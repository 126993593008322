import React from "react";
import { ImageBackground, View, Text } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../ReponsesSurvey";
import FadeSlideView from "../Anim/FadeSlideView";
import BaseSondage from "./baseSondage";
import { TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";
import { OPTIONS_QUESTIONS_TV_AND_RESULTS, OPTIONS_NO_TV_RESULT_MOBILE } from "../../constants/routes";


export class Sondages extends BaseSondage {
  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
  }

  callbackTimer(val) {
    // check again the question
    this.checkSurveyAndQuestion();
    // check timer and current question time
    if (this.state.currentQuestion != null) {
      if (this.state.currentQuestion.displayResult === false) {
        var timePast = this.state.currentSurvey.start.seconds;
        var time_pause = 0;
        if(this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS || this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE){
          time_pause = TIME_PAUSE;
        }
        // console.log(timePast);
        // console.log(val);
        for (let i = 0; i < this.state.currentQuestion.order; i++) {
          const question = this.state.currentSurvey.questions.filter(e => {
            return e.order === i + 1;
          })[0];
          if (i === 0) {
            timePast = timePast + question.time + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + time_pause + question.time + TIME_EXTRA_ANIM;
          }
        }
        const now = Math.floor(
          (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) /
            1000
        );
        const timeLeft = timePast - now;
        if (Math.abs(timeLeft - val) > 0) {
          if (this._isMounted) {
            this.setState({
              timeLeft: timeLeft
            });
          }
        }
      }
    }
  }

  finishCountDownCallback = () => {
    if (
      this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
    ) {
      //Show result and time response
      if (this.state.currentQuestion.displayResult === true) {
        // Next question
        this.checkSurveyAndQuestion();
        if (this._isMounted) {
          this.setState({ displayResult: false });
        }
      } else {
        if (this.state.currentResults != null) {
          const answersByTimes = this.state.currentResults.filter(q => {
            return q.id === this.state.currentQuestion.id;
          })[0].answersByTimes;
          if (this._isMounted) {
            this.setState({ answersByTimes });
          }
        }
        if (this._isMounted) {
          this.setState({ displayResult: true });
        }
      }
    } else {
      // Next question
      // console.log("should go to next question");
      this.checkSurveyAndQuestion(true);
    }
  };

  render() {
    // const screenHeight = Math.round(Dimensions.get("window").height);
    // const scrollHeight = screenHeight - 190;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {this.state.currentQuestion && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FadeSlideView direction="down">
              <ImageBackground
                key={this.state.currentQuestion.displayResult + '_' + this.state.currentQuestion.id}
                style={{
                  width: 66,
                  height: 66,
                  justifyContent: "center",
                  alignItems: "center"
                }}
                resizeMode={"contain"}
                source={require("../../assets/images/circle.png")}
              >
                <CountDownCircle
                  key={this.state.timeLeft}
                  seconds={
                    this.state.currentQuestion.displayResult
                      ? this.state.currentQuestion.displayResultTimeLeft
                      : this.state.timeLeft || 10
                  }
                  radius={28}
                  borderWidth={8}
                  color={
                    this.state.currentQuestion.displayResult
                      ? "limegreen"
                      : "#ff003f"
                  }
                  bgColor="#fff"
                  textStyle={{ fontSize: 18 }}
                  finishCallback={() => this.finishCountDownCallback()}
                  callbackTimer={this.callbackTimer}
                />
              </ImageBackground>
            </FadeSlideView>

            <View
              style={{
                top: 160,
                position: "fixed",
                height: "100%",
                width: "100%",
                paddingBottom: 150,
                overflowY: "scroll",
                overflowX: "hidden"
              }}
            >
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <FadeSlideView delay={150}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: RF(3),
                      fontWeight: "bold",
                      marginTop: 10,
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center"
                    }}
                  >
                    {this.state.currentQuestion.question}
                  </Text>
                </FadeSlideView>

                <FadeSlideView delay={300} direction="right">
                  <PagingControl
                    key={this.state.timeLeft}
                    style={{ marginTop: 15 }}
                    max={this.state.currentSurvey.questions.length}
                    width={320}
                    current={this.state.currentQuestion.order}
                  />
                </FadeSlideView>

                <FadeSlideView delay={600} direction="up">
                  <View
                    style={{
                      flex: 1,
                      alignItems: "stretch",
                      alignContent: "stretch",
                      alignSelf: "stretch",
                      paddingBottom: 20
                    }}
                  >
                    <Response
                      {...this.props}
                      key={this.state.currentQuestion.displayResult +'_'+ this.state.currentQuestion.id}
                      data={{
                        answers: this.state.currentQuestion.answers,
                        currentQuestionId: this.state.currentQuestion.id,
                        currentQuestionTimeLeft: this.state.currentQuestion
                          .timeLeft,
                        currentQuestionTimeEnd: this.state.currentQuestion
                          .timeEnd,
                        currentSurveyId: this.state.currentSurvey.id,
                        withImage: this.state.currentQuestion.withImage,
                        withResult: this.state.currentQuestion.displayResult,
                        currentResults: this.state.currentResults
                      }}
                      modeTele={false}
                    />
                  </View>
                </FadeSlideView>
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default Sondages;

import React, { Component } from "react";
import { Text, View } from "react-native";

import FadeSlideView from "../Anim/FadeSlideView";

import RF from "react-native-responsive-fontsize";

export class Thankyou extends Component {
  _isMounted = false;
  state = { data: null, screen: "winner" };


  // checkTimeDisplay = () =>{
    
  //     console.log("new data received");
  //     console.log(this.currentSurvey);
  //     console.log(this.currentQuiz);
  //     const now = Math.floor(
  //       (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
  //     );
  //     if (this.currentQuiz.name === "" && this.currentSurvey.name !== "") {
  //       console.log("navigate to Surveys");
  //       this.props.navigation.navigate("Surveys");
  //     } else if (this.currentSurvey.name === "" && this.currentQuiz.name !== "") {
  //       console.log("navigate to Quizzes");
  //       this.props.navigation.navigate("Quizzes");
  //     } else if (this.currentQuiz.name !== "" && this.currentSurvey.name !== "") {
  //       if (
  //         this.currentSurvey.start.seconds < this.currentQuiz.start.seconds &&
  //         now < this.currentSurvey.end.seconds
  //       ) {
  //         console.log("navigate to Surveys");
  //         this.props.navigation.navigate("Surveys");
  //       } else if (
  //         this.currentQuiz.start.seconds < this.currentSurvey.start.seconds &&
  //         now < this.currentQuiz.end.seconds
  //       ) {
  //         console.log("navigate to Quizzes");
  //         this.props.navigation.navigate("Quizzes");
  //       }
  //     }
    
  // }

  // componentDidUpdate(){
  //   this.currentSurvey = this.props.currentSurvey;
  //   this.currentQuiz = this.props.currentQuiz;
  //   this.checkTimeDisplay();
  // }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    // console.log(this.props);
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 30
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: RF(4),
              fontWeight: "bold",
              textTransform: "uppercase",
              textAlign:'center'
            }}
          >
            {this.props.currentSurvey.name}
          </Text>
          <Text
            style={{
              color: "darkgrey",
              textTransform: "uppercase",
              fontSize: RF(2),
              fontWeight: "100",
              marginBottom: 50,
              textAlign:'center'
            }}
          >
            {this.props.currentSurvey.desc}
          </Text>
          <FadeSlideView
            style={{ justifyContent: "center", alignItems: "center" }}
            direction="down"
            delay={1000}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(5),
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 20
              }}
            >
              {this.props.currentSurvey.thankyou_msg}
            </Text>
          </FadeSlideView>
        </View>
      </View>
    );
  }
}

export default Thankyou;

import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Animated,
  Easing,
  Dimensions
} from "react-native";
import { MAIN_COLOR, THEME, MAIN_URL } from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleHeader from "./Elements/header";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";

export class TeleHome extends Component {
  state = {
    currentQuiz: null,
    timeLeft: 0,
    animate: false,
    animations: {
      bgWidth: new Animated.Value(80),
      bgMarginTop: new Animated.Value(100),
      bgMarginBottom: new Animated.Value(200),
      textMarginBottom: new Animated.Value(90),
      fadeOut: new Animated.Value(1)
    }
  };
  springSize = new Animated.Value(3);
  constructor(props) {
    super(props);

    this.callbackTimer = this.callbackTimer.bind(this);
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      })
    ]);

  callbackTimer(val) {
    if (val <= 60 && this.state.animate === false) {
      //set state animated
      this.setState({ animate: true });
      const {
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut
      } = this.state.animations;

      this.doAnimation({
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut
      }).start(() => {
        // Do stuff after animations
      });
    }

    if (this.state.animate === true) {
      this.setState({ timeLeft: val });
      this.spring();
      if (val < 0) {
        if (this.props.currentQuiz === null) {
          this.setState({ msg: "Aucune activité prévue ! Revenez bientôt !" });
        }
      }
    }

    if (this.props.callbackTimer) {
      this.props.callbackTimer(val);
    }
  }

  render() {
    const { animations, animate } = this.state;
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <View style={styles.container}>
        <Animated.View
          style={{
            flex: 1,
            alignSelf: "center",
            width: animate
              ? animations.bgWidth.interpolate({
                  inputRange: [80, 100],
                  outputRange: ["80%", "100%"]
                })
              : "80%",
            marginTop: animate
              ? animations.bgMarginTop
              : Math.round(screenHeight / 6)
          }}
        >
          <Animated.View
            style={{
              flex: 1,
              marginBottom: animate
                ? animations.bgMarginBottom
                : Math.round(screenHeight / 6)
            }}
          >
            <ImageBackground
              style={{
                flex: 1
              }}
              source={require("../../assets/themes/" + THEME + "/bg.png")}
              resizeMode={"cover"}
            >
              <Animated.View
                style={{
                  flexDirection: "row",
                  opacity: animate ? animations.fadeOut : 1
                }}
              >
                <View style={{ alignSelf: "flex-start", margin: 30 }}>
                  <TeleCountDown
                    timeLeft={this.props.timeLeft}
                    callbackTimer={this.callbackTimer}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    margin: 30
                  }}
                >
                  {this.props.currentQuiz && (
                    <NumberOnline type='quiz' logeName={this.props.currentQuiz.subtitle} {...this.props} />
                  )}
                </View>
              </Animated.View>
              <View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {this.props.currentQuiz && (
                  <Animated.Text
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: RF(10),
                      fontWeight: "bold",
                      marginBottom: animate
                        ? animations.textMarginBottom
                        : Math.round(screenHeight / 4)
                    }}
                  >
                    {this.props.currentQuiz.name}
                  </Animated.Text>
                )}
              </View>
            </ImageBackground>
          </Animated.View>
        </Animated.View>
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            position: "absolute"
          }}
        />
        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1
          }}
          source={require("../../assets/themes/" + THEME + "/banner_full.png")}
          resizeMode={"stretch"}
        />
        <Animated.View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: 5,
              position: "absolute",
              bottom: animate ? 0 : Math.round(screenHeight / 30),
              opacity: this.state.msg ? animations.fadeOut : 1
            }}
          >
            <View
              style={{
                margin: 20
              }}
            >
              <QRCode
                value={"https://" + MAIN_URL}
                fgColor={MAIN_COLOR}
                size={Math.round(screenHeight / 4)}
              />
            </View>
            <View style={{flexDirection:"column"}} >
            <Text style={{ color: MAIN_COLOR, margin: 20, textAlign:"center", fontSize: RF(4), lineHeight:RF(5.5)}}>
              {`SCANNEZ LE QR CODE 
OU INSCRIVEZ VOUS 
VIA L’URL CI-DESSOUS`}
            </Text>
            <Text style={{ color: "red", margin: 10, fontSize: RF(8), fontWeight:"bold" }}>
              {MAIN_URL}
            </Text>
            </View>
          </Animated.View>
        {this.state.animate && (
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center"
            }}
          >
            <Animated.View
              style={{
                alignSelf: "center",
                width: Math.round(screenWidth / 3),
                height: Math.round(screenHeight / 3),
                transform: [{ scale: this.springSize }],
                marginTop: Math.round(screenHeight / 3)
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(15),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20
                }}
              >
                {this.state.timeLeft >= 0 ? this.state.timeLeft : ""}
              </Text>
            </Animated.View>
            {this.state.msg && (
              <Text
                style={{
                  color: "white",
                  fontSize: RF(8),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20,
                  position:"absolute",
                  alignSelf:"center"
                }}
              >
                {this.state.msg}
              </Text>
            )}
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLOR,
    flex: 1
  }
});

export default TeleHome;

import React from "react";
import { Animated } from "react-native";

export class FadeView extends React.Component {
  valueStart = this.props.out ? 1 : 0;
  valueEnd = this.props.out ? 0 : 1;
  state = {
    fadeAnim: new Animated.Value(this.valueStart) // Initial value for opacity: 0
  };

  componentDidMount() {
    setTimeout(() => {
      Animated.timing(
        // Animate over time
        this.state.fadeAnim, // The animated value to drive
        {
          toValue: this.valueEnd, // Animate to opacity: 1 (opaque)
          duration: this.props.duration || 1000 // Make it take a while
        }
      ).start(); // Starts the animation
    }, this.props.delay || 0);
  }

  render() {
    let { fadeAnim } = this.state;

    return (
      <Animated.View // Special animatable View
        style={{
          ...this.props.style,
          opacity: fadeAnim // Bind opacity to animated value
        }}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export default FadeView;

import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Animated,
  Easing,
  Dimensions
} from "react-native";
import { MAIN_COLOR, THEME, MAIN_URL, TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleHeader from "./Elements/header";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import { OPTIONS_QUESTIONS_TV_AND_RESULTS, OPTIONS_QUESTIONS_TV, OPTIONS_NO_TV_RESULT_MOBILE } from "../../constants/routes";


export class TeleSurveyHome extends Component {
  
  springSize = new Animated.Value(3);
  setted = false;
  
  constructor(props) {
    super(props);
// console.log("reinit HOME survey");
  this.state = {
    timeLeft: props.timeLeft,
    currentSurvey: props.currentSurvey,
    animate: false,
    animations: {
      bgWidth: new Animated.Value(80),
      bgMarginTop: new Animated.Value(100),
      bgMarginBottom: new Animated.Value(200),
      textMarginBottom: new Animated.Value(90),
      fadeOut: new Animated.Value(1)
    },
    screen: 'countdown',
    timeEnd: 0
  };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12
    }).start();
  }

  doAnimation = ({ bgWidth, bgMarginTop, bgMarginBottom, textMarginBottom, fadeOut }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      })
      
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {
    if (
      this.state.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.state.currentSurvey.display_type === OPTIONS_QUESTIONS_TV
    ) {

      if (val <= 60 && this.state.animate === false) {
        //set state animated
        this.setState({ animate: true, screen: 'countdown' });
        const {
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut
        } = this.state.animations;
  
        this.doAnimation({
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut
        }).start(() => {
          // Do stuff after animations
        });
      }
  
      if (this.state.animate === true) {
        this.setState({ timeLeft: val });
        this.spring();
      }
  
      if (this.props.callbackTimer) {
        this.props.callbackTimer(val - 1);
      }
    }else{
      //Change the screen for showing not replay questions
      if (val <= 0 && this.state.animate === false) {
        const now = Math.floor(
          (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
        );
        var timeEnd = this.state.currentSurvey.start.seconds - now;
        var time_pause = 0;
        if (
          this.state.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.state.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
        ) {
          time_pause = TIME_PAUSE;
        }
        for (let i = 0; i < this.state.currentSurvey.questions.length; i++) {
          const q = this.state.currentSurvey.questions[i];
          timeEnd =  timeEnd + q.time + TIME_EXTRA_ANIM + time_pause;
        }
        timeEnd = timeEnd - time_pause;

        this.setState({ animate: true, screen: 'countToEnd', timeEnd });

        const {
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut
        } = this.state.animations;
  
        this.doAnimation({
          bgWidth,
          bgMarginTop,
          bgMarginBottom,
          textMarginBottom,
          fadeOut
        }).start(() => {
          // Do stuff after animations
        });

      }
    }
    
  }

  render() {
    const { animations, animate } = this.state;
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    // console.log(this.state);
    return (
      <View style={styles.container}>
        <Animated.View
          style={{
            flex: 1,
            alignSelf: "center",
            width: animate
              ? animations.bgWidth.interpolate({
                  inputRange: [80, 100],
                  outputRange: ["80%", "100%"]
                })
              : "80%",
            marginTop: animate ? animations.bgMarginTop : Math.round(screenHeight/6)
          }}
        >
          <Animated.View
            style={{
              flex: 1,
              marginBottom: animate ? animations.bgMarginBottom : Math.round(screenHeight/6)
            }}
          >
            <ImageBackground
              style={{
                flex: 1
              }}
              source={require("../../assets/themes/" + THEME + "/bg.png")}
              resizeMode={"cover"}
            >
              <Animated.View
                style={{
                  flexDirection: "row",
                  opacity: animate ? animations.fadeOut : 1
                }}
              >
                <View style={{ alignSelf: "flex-start", margin: 30 }}>
                  <TeleCountDown
                    timeLeft={this.state.timeLeft}
                    callbackTimer={this.callbackTimer}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                    margin: 30
                  }}
                >
                  {this.props.currentSurvey && (
                    <NumberOnline type='survey' logeName={this.props.currentSurvey.subtitle} />
                  )}
                </View>
              </Animated.View>
              {this.props.currentSurvey && (
              <Animated.View
                style={{
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: animate ? animations.textMarginBottom : Math.round(screenHeight/8)
                }}
              >
                  <Text
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: RF(10),
                      fontWeight: "bold"
                    }}
                  >
                    {this.props.currentSurvey.name}
                  </Text>
                  <Text
                    style={{
                      color: "white",
                      textAlign: "center",
                      fontSize: RF(4),
                      fontWeight: "bold"
                    }}
                  >
                    {this.props.currentSurvey.desc}
                  </Text>
              </Animated.View>
              )}
            </ImageBackground>
          </Animated.View>
          <Animated.View
            style={{
              flexDirection: "row",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              borderRadius: 5,
              position: "absolute",
              bottom: Math.round(screenHeight/8),
              opacity: animate ? animations.fadeOut : 1
            }}
          >
            <View
              style={{
                margin: 20
              }}
            >
              <QRCode
                value={"https://" + MAIN_URL + "/surveys"}
                fgColor={MAIN_COLOR}
                size={Math.round(screenHeight/8)}
              />
            </View>
            <View style={{flexDirection:"column"}} >
            <Text style={{ color: MAIN_COLOR, margin: 20, textAlign:"center", fontSize: RF(4) }}>
              {`SCANNEZ LE QR CODE 
OU INSCRIVEZ VOUS 
VIA L’URL CI-DESSOUS`}
            </Text>
            <Text style={{ color: "red", margin: 20, fontSize: RF(8), fontWeight:"bold" }}>
              {MAIN_URL}
            </Text>
            </View>
          </Animated.View>
        </Animated.View>
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            position: "absolute"
          }}
        />
        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1
          }}
          source={require("../../assets/themes/" + THEME + "/banner_full.png")}
          resizeMode={"stretch"}
        />
        {this.state.animate && this.state.screen==="countdown" && (
          <View style={{ position: "absolute", width: "100%", height: "100%", justifyContent:'center'}}>
            <Animated.View
              style={{
                alignSelf:'center',
                width: Math.round(screenWidth/3),
                height: Math.round(screenHeight/3),
                transform: [{ scale: this.springSize }],
                marginTop: Math.round(screenHeight/3)
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(15),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20
                }}
              >
                {this.state.timeLeft}
              </Text>
            </Animated.View>
          </View>
        )}

        {this.state.animate && this.state.screen==="countToEnd" && (
          <View style={{ position: "absolute", width: "100%", height: "100%", justifyContent:'center', top: Math.round(screenHeight/8)}}>
          <TeleCountDown
            key={this.state.timeEnd}
            timeLeft={this.state.timeEnd}
            modeBig={true}
            callbackTimer={this.callbackTimerForEnd}
          />
          </View>
        )}

      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLOR,
    flex: 1
  }
});

export default TeleSurveyHome;

import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/performance";
import { THEME, FIREBASE_APP_API_KEY, FIREBASE_APP_AUTH_DOMAIN, FIREBASE_APP_DATABASE_URL, FIREBASE_APP_PROJECT_ID, FIREBASE_APP_STORAGE_BUCKET, FIREBASE_APP_MESSAGING_SENDER_ID, FIREBASE_APP_ID, TIME_DELAY } from "../../constants/const";
import moment from "moment";
import "moment-timezone";

//TODO: need to make .env make
const config = {
    apiKey: FIREBASE_APP_API_KEY,
    authDomain: FIREBASE_APP_AUTH_DOMAIN,
    databaseURL: FIREBASE_APP_DATABASE_URL,
    projectId: FIREBASE_APP_PROJECT_ID,
    storageBucket: FIREBASE_APP_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_APP_MESSAGING_SENDER_ID,
    appId: FIREBASE_APP_ID
  };

// For login by email without password
const actionCodeSettings = {
  url: "https://loge.wisslmedia.com/",
  iOS: {
    bundleId: "com.wisslmedia.quiz"
  },
  android: {
    packageName: "com.wisslmedia.quiz",
    installApp: true,
    minimumVersion: "1"
  },
  handleCodeInApp: true,
  // When multiple custom dynamic link domains are defined, specify which
  // one to use.
  dynamicLinkDomain: "wslquiz.page.link"
};

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.auth.languageCode = 'fr';
    this.db = app.firestore();
    //For debug
    // app.app().functions('europe-west1').useFunctionsEmulator('http://localhost:5001');
    this.functions = app.app().functions('europe-west1');
    // useFunctionsEmulator('http://localhost:5001');
    // Initialize Performance Monitoring and get a reference to the service
    this.perf = app.performance();
  }

  // *** Auth API ***

  getCredential = (email, passwordActuel) => app.auth.EmailAuthProvider.credential(
    email,
    passwordActuel
  );

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSendSignInLinkToEmail = email =>
    this.auth.sendSignInLinkToEmail(email, actionCodeSettings);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  doCurrentUser = () => this.auth.currentUser;

  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);
  users = () => this.db.collection("users");
  quiz = qid => this.db.doc(`quizzes/${qid}`);
  quizzes = () => this.db.collection("quizzes");
  result = qid => this.db.doc(`quizzes/${qid}/result`);

  answer = aid => this.db.doc(`answers/${aid}`);
  answers = () => this.db.collection("answers");
  
  surveys_responses = () => this.db.collection("surveys_responses");

  pronostics_responses = (user_prono) => this.db.doc(`pronostics_responses/${user_prono}`);

  connections = () => this.db.collection("connections");

  timeNow = () => {
    const getTimeNow = this.functions.httpsCallable('timeNow');
    return getTimeNow().then(function(result) {
      // Read result of the Cloud Function.
      var gmt    = moment(result.data.ms);
      var timeParis  = gmt.clone().tz("Europe/Paris");
      const now = new Date(Date.now());
      window.localStorage.setItem("delay", timeParis.valueOf() - TIME_DELAY - now.getTime());
      window.localStorage.setItem("delayTele", timeParis.valueOf() - now.getTime());
      return timeParis.valueOf()
    });
  }

  currentQuizQuery = async () =>{
    const now = await this.timeNow();
    return this.db
    .collection("quizzes")
    .where("loge", "==", THEME)
    .where("end", ">=", new Date(now))
    .orderBy("end", "asc")
    .limit(1);
  }

  currentSurveyQuery = async () =>{
    const now = await this.timeNow();
    return this.db
    .collection("surveys")
    .where("loge", "==", THEME)
    .where("end", ">=", new Date(now))
    .orderBy("end", "asc")
    .limit(1);
  }

  currentMatchQuery = async () =>{
    const now = await this.timeNow();
    return this.db
    .collection("matches")
    .where("loge", "==", THEME)
    .where("end_time", ">=", new Date(now))
    .orderBy("end_time", "asc")
    .limit(1);
  }

  currentResponeseQuery = surveyId =>
    this.db
      .collection("surveys_responses")
      .where("surveyId", "==", surveyId)
    
  currentAnswersQuery = quizId =>
    this.db
      .collection("answers")
      .where("quizId", "==", quizId)
      .orderBy("time", "asc");

  pronostics_responses_query = (pronoId) => this.db.collection("pronostics_responses").where("pronosticId", "==", pronoId);
 
  quiz_winner = qid => this.db.doc(`quiz_winner/${qid}`);
  quiz_winner_ranking = (qid,rid) => this.db.doc(`quiz_winner/${qid}/ranking/${rid}`);
  quiz_participants = (qid,uid) => this.db.doc(`quiz_participants/${qid}/participants/${uid}`);
  quiz_participants_count = (qid) => this.db.collection(`quiz_participants/${qid}/participants`);

  
  match_participants = (qid,uid) => this.db.doc(`match_participants/${qid}/participants/${uid}`);
  match_participants_count = (qid) => this.db.collection(`match_participants/${qid}/participants`);
  match_lives = (mid) => this.db.doc(`match_lives/${mid}`);
  match_winner = (mid) => this.db.doc(`match_winner/${mid}`);
  match_winner_ranking = (mid, rid) => this.db.doc(`match_winner/${mid}/ranking/${rid}`);
}

export default Firebase;

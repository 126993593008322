import React from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import Ranking from "../../components/Ranking";
import {
  Text,
  View,
  Image,
  Dimensions,
  TouchableHighlight
} from "react-native";
import RF from "react-native-responsive-fontsize";
import { BUTTON_COLOR, MAIN_COLOR } from "../../constants/const";

export class RankingScreen extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { data: null, currentPlayer: null };
  }

  componentDidMount() {
    this._isMounted = true;
    const currentResults = window.localStorage.getItem("currentResults");
    var playersRanking = [];
    if (currentResults != null) {
      const arrResults = JSON.parse(currentResults);

      arrResults.forEach(q => {
        // console.log(q);
        const answerList = q.answersByTimes;
        // console.log(answerList);
        answerList.forEach(ans => {
          // console.log(ans);
          const exists = playersRanking.filter(pl => {
            return pl.userId === ans.userId;
          });
          if (exists.length > 0) {
            //Exist and need update
            const rightTimeMs = ans.isRight >= 1 ? ans.timeMs : 0;
            exists[0].isRight += ans.isRight;
            exists[0].time += ans.time;
            exists[0].timeMs += ans.timeMs;
            exists[0].rightTimeMs += rightTimeMs;
          } else {
            //New needs to add
            playersRanking.push(ans);
          }
        });
      });

      playersRanking.sort((a, b) => {
        return (
          b.isRight - a.isRight ||
          a.rightTimeMs - b.rightTimeMs ||
          a.timeMs - b.timeMs ||
          a.time - b.time
        );
      });

      var currentPlayer = null;
      if (this.props.authUser) {
        for (let i = 0; i < playersRanking.length; i++) {
          const element = playersRanking[i];
          if (this.props.authUser.uid === element.userId) {
            element.rank = i + 1;
            currentPlayer = element;
            break; // for
          }
        }
      }

      playersRanking = playersRanking.slice(0, 100);

      if (this._isMounted) {
        this.setState({
          data: playersRanking,
          totalQuestions: arrResults.length,
          currentPlayer: currentPlayer
        });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const screenWidth = Math.round(Dimensions.get("window").width);
    const { navigate } = this.props.navigation;
    return (
      <View>
        <Text
          style={{
            color: "white",
            textAlign: "center",
            textAlignVertical: "center",
            flex: 1,
            margin: 20,
            fontSize: RF(2.2)
          }}
        >
          Classement final
        </Text>
        <View style={{ marginBottom: RF(2) + 56 }}>
          <Ranking {...this.state} {...this.props} />
        </View>

        <View
          style={{
            alignContent: "center",
            alignSelf: "center",
            bottom: 0,
            position: "fixed",
            backgroundColor: MAIN_COLOR,
            width: 320,
            height: RF(4) + 16
          }}
        >
          <TouchableHighlight
            style={{ bottom: 16 }}
            onPress={() => navigate("Home")}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(3),
                backgroundColor: BUTTON_COLOR,
                textAlign: "center",
                padding: 8,
                borderRadius: 3
              }}
            >
              <i>Retour à l'accueil</i>
            </Text>
          </TouchableHighlight>
        </View>
        {this.state.currentPlayer && (
          <View style={{ position: "fixed", bottom: 16 + RF(4) + 20 }}>
            <View
              style={{
                backgroundColor: "dimgray",
                position: "absolute",
                height: "100%",
                width: "100%",
                opacity: 1
              }}
            />

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignContent: "stretch",
                alignItems: "flex-start",
                width: 320,
                justifyContent: "space-between",
                borderBottomColor: "gray",
                borderBottomWidth: 0.5,
                paddingBottom: 5,
                paddingTop: 5
              }}
            >
              {this.state.currentPlayer.rank === 1 && (
                <Image
                  style={{
                    width: 40,
                    height: RF(2)
                  }}
                  source={require("../../assets/images/first.png")}
                  resizeMode="contain"
                />
              )}
              {this.state.currentPlayer.rank > 1 && (
                <Text
                  style={{
                    fontSize: RF(2),
                    textAlign: "center",
                    width: 40,
                    textAlignVertical: "center",
                    color: "lightgray"
                  }}
                >
                  {this.state.currentPlayer.rank}
                </Text>
              )}
              <Text
                style={{
                  color: "white",
                  textAlign: "left",
                  textAlignVertical: "center",
                  flex: 1,
                  marginLeft: 8,
                  fontSize: RF(2.2)
                }}
              >
                {this.state.currentPlayer.displayName}
              </Text>

              <Text
                style={{
                  fontSize: RF(1.8),
                  color: "white",
                  textAlignVertical: "bottom",
                  width: 50
                }}
              >
                {this.state.totalQuestions != null
                  ? `${this.state.currentPlayer.isRight}/${this.state.totalQuestions}`
                  : this.state.currentPlayer.isRight >= 1
                  ? "✅"
                  : "❌"}
              </Text>

              {this.state.currentPlayer.rightTimeMs > 0 && (
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.8),
                    textAlignVertical: "bottom",
                    marginLeft: 5,
                    width: 60
                  }}
                >
                  {Math.floor(
                    this.state.currentPlayer.rightTimeMs / 1000
                  ).toFixed(0)}
                  '
                  {Math.floor(
                    (this.state.currentPlayer.rightTimeMs -
                      Math.floor(
                        this.state.currentPlayer.rightTimeMs / 1000
                      ).toFixed(0) *
                        1000) /
                      10
                  ) < 10
                    ? "0" +
                      Math.floor(
                        (this.state.currentPlayer.rightTimeMs -
                          Math.floor(
                            this.state.currentPlayer.rightTimeMs / 1000
                          ).toFixed(0) *
                            1000) /
                          10
                      )
                    : Math.floor(
                        (this.state.currentPlayer.rightTimeMs -
                          Math.floor(
                            this.state.currentPlayer.rightTimeMs / 1000
                          ).toFixed(0) *
                            1000) /
                          10
                      )}
                  s
                </Text>
              )}

              {this.state.currentPlayer.rightTimeMs <= 0 && (
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.8),
                    textAlignVertical: "bottom",
                    marginLeft: 5,
                    width: this.props.modeTele
                      ? Math.round(screenWidth / 16)
                      : 60
                  }}
                >
                  {Math.floor(this.state.currentPlayer.timeMs / 1000).toFixed(
                    0
                  )}
                  '
                  {Math.floor(
                    (this.state.currentPlayer.timeMs -
                      Math.floor(
                        this.state.currentPlayer.timeMs / 1000
                      ).toFixed(0) *
                        1000) /
                      10
                  ) < 10
                    ? "0" +
                      Math.floor(
                        (this.state.currentPlayer.timeMs -
                          Math.floor(
                            this.state.currentPlayer.timeMs / 1000
                          ).toFixed(0) *
                            1000) /
                          10
                      )
                    : Math.floor(
                        (this.state.currentPlayer.timeMs -
                          Math.floor(
                            this.state.currentPlayer.timeMs / 1000
                          ).toFixed(0) *
                            1000) /
                          10
                      )}
                  s
                </Text>
              )}
            </View>
          </View>
        )}
      </View>
    );
  }
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(withMainLayout(RankingScreen));

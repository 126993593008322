import { Dimensions } from "react-native";

const { height, width } = Dimensions.get("window");

// Remove the status bar height since the modal view does not cover this area
const getHeight = () =>  height;
export const getWidthPercent = percentage => (width * percentage) / 100;
export const getWidthPercentMaxLimited = (percentage, max) => {return width > max ? (max * percentage)/100 : (width * percentage) / 100};
export const getHeightPercent = percentage => (height * percentage) / 100;
export const getPercent = percentage =>
  (((getHeight() + width) / 2) * percentage) / 100;

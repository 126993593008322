export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';

export const OPTIONS_QUESTIONS_TV = 'questions_on_tv';
export const OPTIONS_QUESTIONS_TV_AND_RESULTS = 'questions_on_tv_and_results';
export const OPTIONS_NO_TV_RESULT_MOBILE = 'only_result_on_mobile';
export const OPTIONS_NO_RESULTS = 'no_results';

import React, { Component } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ImageBackground,
  Dimensions
} from "react-native";
import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";
import Confetti from "react-confetti";
import RF from "react-native-responsive-fontsize";
import { MAIN_COLOR, THEME, EMAILS_RESULTS } from "../../constants/const";
import { getWidthPercent } from "../../libs/list_picker/ratio";
import MatchWinnerRandom from "./matchWinnerRandom";

export class MatchWinner extends Component {
  _isMounted = false;
  _isSaving = false;
  state = { winner: null, screen: "winner", winnersList: [], numUpdate: 0 };
  ranCount = 0;

  componentDidMount() {
    this._isMounted = true;
    console.log(this.props);
    if(this.props.currentMatch.winner){
      this.setState({
        winner: this.props.currentMatch.winner,
        numUpdate: this.state.numUpdate + 1
      });
    }else{
      if (this.props.matchWinners.length > 1) {
        //random
        this.setState(
          {
            screen: "random",
            winnersList: this.props.matchWinners,
            numUpdate: this.state.numUpdate + 1
          },
          () => {
            // Do stuff after animations
            var intervalId = setInterval(() => {
              this.doRandom();
            }, 300);
            this.setState({
              intervalId: intervalId,
              randomNum:
                Math.floor(Math.random() * this.props.matchWinners.length * 10) +
                2 * this.props.matchWinners.length
            });
          }
        );
      } else if (this.props.matchWinners.length === 1) {
        this.setState({
          winner: this.props.matchWinners[0],
          numUpdate: this.state.numUpdate + 1
        });
      }
    }
    
  }

  doRandom = () => {
    const index = this.ranCount % this.state.winnersList.length;
    var l = this.state.winnersList.map(el => {
      el.color = "default";
      return el;
    });
    l[index].color = "green";
    this.setState(
      {
        winnersList: this.props.matchWinners,
        winner: l[index]
      },
      () => {
        if (this.ranCount >= this.state.randomNum) {
          clearInterval(this.state.intervalId);
          var batch = this.props.firebase.db.batch();
          var doc = this.props.firebase.match_winner(
            this.props.currentMatch.id + "_" + this.props.currentMatch.match_id
          );
          batch.set(doc, this.state.winner);
          // Commit the batch
          batch.commit().then(function() {
            console.log("updated winner");
          });

          //send email:
          // call email
          const sendMailMatchWinners = this.props.firebase.functions.httpsCallable(
            "sendMailMatchWinners"
          );
          var resortList = this.props.pronosticsSorted.splice(0);
          for (let i = 0; i < resortList.length; i++) {
            const r = resortList[i];
            if(r.userId === this.state.winner.userId){
              resortList[i].point = resortList[i].point + 1;
            }
          }
          const resorted = resortList.sort((a,b)=>{
              return b.point - a.point;
          });
          sendMailMatchWinners({
            currentMatch: this.props.currentMatch,
            rankList: resorted,
            winner: this.state.winner,
            to: EMAILS_RESULTS
          });

          this.setState({
            screen: "winner",
            numUpdate: this.state.numUpdate + 1
          });
        }
      }
    );
    this.ranCount = this.ranCount + 1;
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  _getColorMinute = win => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (win.correct_minute === true) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };
  _getColorScorer = win => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (win.correct_scorer === true) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };
  _getColorScore = win => {
    if (win) {
      if (
        win.correct_score === true &&
        win.correct_scorer === true &&
        win.correct_minute === true
      ) {
        return "transparent";
      }
      if (win.correct_score === true) {
        return "#2F541E";
      } else {
        return "#511E28";
      }
    }
    return "transparent";
  };

  _displayMinute = min => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <ImageBackground
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        source={require("../../assets/themes/" + THEME + "/bg.png")}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            top: 0,
            position: "absolute"
          }}
        />
        <Confetti width={screenWidth} height={screenHeight} />

        {this.state.screen === "winner" && this.props.currentMatch && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderRadius: 10,
              padding: 50,
              width: "80%"
            }}
            key={this.state.numUpdate}
          >
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.props.liveData.home_score
                  ? this.props.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.props.liveData.visitor_score
                  ? this.props.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 30
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center"
                }}
              >
                Vainqueur
              </Text>
            </View>

            <FadeSlideView direction="down" delay={1000}>
              <Text
                style={{
                  color: "white",
                  fontSize: RF(9),
                  fontWeight: "bold",
                  margin: 20,
                  textAlign: "center",
                  textTransform: "uppercase"
                }}
              >
                {this.state.winner != null
                  ? this.state.winner.displayName
                  : "Pas de vainqueur"}
              </Text>
            </FadeSlideView>

            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
                maxWidth: getWidthPercent(80),
                borderBottomWidth: 1,
                borderBottomColor: "#ccc",
                backgroundColor: this._getColorScore(this.state.winner)
              }}
            >
              <Text style={styles.pronoTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.pronoLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.pronoText}>
                {this.state.winner ? this.state.winner.scoreHome : 0}
              </Text>
              <Text style={styles.pronoText}>-</Text>
              <Text style={styles.pronoText}>
                {this.state.winner ? this.state.winner.scoreVisitor : 0}
              </Text>
              <Image
                style={styles.pronoLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.pronoTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View style={styles.pronoLine}>
              <View
                style={{
                  height: getWidthPercent(4),
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderBottomColor: "#ccc",
                  borderRightWidth: 1,
                  borderRightColor: "#ccc",
                  backgroundColor: this._getColorScorer(this.state.winner)
                }}
              >
                <Text style={styles.pronoScorerTextLeft}>
                  {this.state.winner ? this.state.winner.firstScorer.name : ""}
                </Text>
              </View>
              <View
                style={{
                  height: getWidthPercent(4),
                  justifyContent: "center",
                  alignItems: "center",
                  borderBottomWidth: 1,
                  borderBottomColor: "#ccc",
                  borderLeftWidth: 1,
                  borderLeftColor: "#ccc",
                  backgroundColor: this._getColorMinute(this.state.winner)
                }}
              >
                <Text style={styles.pronoScorerTextRight}>
                  {this.state.winner ? this._displayMinute(this.state.winner.firstScoreMinute) : ""}
                </Text>
              </View>
            </View>
          </View>
        )}

        {this.state.screen === "random" && this.props.currentMatch && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderRadius: 10,
              padding: 50,
              width: "80%"
            }}
            key={this.state.numUpdate}
          >
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.props.liveData.home_score
                  ? this.props.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.props.liveData.visitor_score
                  ? this.props.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 30
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center"
                }}
              >
                Ex Aequo
              </Text>
            </View>

            <FadeSlideView direction="up">
              <Text
                style={{
                  color: "white",
                  fontSize: RF(9),
                  fontWeight: "bold",
                  margin: 20,
                  textAlign: "center",
                  textTransform: "uppercase"
                }}
              >
                Tirage au sort
              </Text>
            </FadeSlideView>

            <MatchWinnerRandom
              data={this.state.winnersList}
              key={this.state.numUpdate}
            />
          </View>
        )}
      </ImageBackground>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLOR,
    flex: 1
  },
  liveContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  liveLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(80)
  },
  liveLogo: {
    width: getWidthPercent(7),
    height: getWidthPercent(7),
    margin: getWidthPercent(1)
  },
  liveText: {
    color: "white",
    fontSize: getWidthPercent(5),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },
  liveTeamTextLeft: {
    color: "white",
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(20)
  },
  liveTeamTextRight: {
    color: "white",
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(20)
  },
  liveTextScorer: {
    color: "white",
    fontSize: getWidthPercent(4),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },

  pronoLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(80),
    borderBottomWidth: 1,
    borderBottomColor: "#ccc"
  },
  pronoLogo: {
    width: getWidthPercent(2),
    height: getWidthPercent(2),
    margin: getWidthPercent(1)
  },
  pronoText: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },
  pronoTeamTextLeft: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(15)
  },
  pronoTeamTextRight: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(15)
  },
  pronoTextScorer: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },
  pronoScorerTextLeft: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(15),
    marginRight: getWidthPercent(1)
  },
  pronoScorerTextRight: {
    color: "white",
    fontSize: getWidthPercent(1),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(15),
    marginLeft: getWidthPercent(1)
  }
});

export default MatchWinner;

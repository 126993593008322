import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import SignIn from '../../components/SignIn';

export class SignInScreen extends Component {
  render() {
    return (
        <SignIn {...this.props} />
    )
  }
}

export default withMainLayout(SignInScreen)

import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";
import Thankyou from "../../components/Sondages/thankyou";

export class SurveysScreen extends Component {
  
  render() {
    return (
      <Thankyou {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(SurveysScreen));

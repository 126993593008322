import React, { Component } from "react";
import { View, Image, Text, Dimensions } from "react-native";
import RF from "react-native-responsive-fontsize";
import { THEME } from "../../../constants/const";

const logoLeft = require("../../../assets/themes/" + THEME + "/header_left.png");
const logoRight = require("../../../assets/themes/" + THEME + "/header_right.png");

export class TeleHeader extends Component {
  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <View {...this.props}>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <Image
            style={{
              width: Math.round(screenWidth/6),
              height: Math.round(screenHeight/6)
            }}
            resizeMode={"contain"}
            source={logoLeft}
          />
          {this.props.texts && (
            <View
              style={{
                width: 0,
                flexGrow: 1,
                flex: 1,
                padding: 10,
                justifyContent: "center"
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(3),
                  fontWeight: "bold",
                  textTransform: "uppercase"
                }}
              >
                {this.props.texts.name}
              </Text>
              <Text
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: RF(3),
                  fontWeight: "100"
                }}
              >
                {this.props.texts.subtitle}
              </Text>
            </View>
          )}
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Image
            style={{ width: Math.round(screenWidth/6), height: Math.round(screenHeight/6) }}
            resizeMode={"contain"}
            source={logoRight}
          />
        </View>
      </View>
    );
  }
}

export default TeleHeader;

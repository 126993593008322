import React from "react";
import { ImageBackground, View, Text } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../Reponses";
import FadeSlideView from "../Anim/FadeSlideView";
import BaseQuiz from "./baseQuiz";
import { TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";
import Ranking from "../Ranking";


export class Question extends BaseQuiz {
  
  constructor(props) {
    super(props);
    this.callbackTimer = this.callbackTimer.bind(this);
  }

  callbackTimer(val) {
    // check again the question
    this.checkQuizAndQuestion();
    // check timer and current question time
    if (this.state.currentQuestion != null) {
      if(this.state.currentQuestion.displayResult === false){
        var timePast = this.state.currentQuiz.start.seconds ;
        // console.log(timePast);
        // console.log(val);
        for (let i = 0; i < this.state.currentQuestion.order; i++) {
          const question = this.state.currentQuiz.questions.filter(e => {
            return e.order === i + 1;
          })[0];
          if (i === 0) {
            timePast = timePast + question.time + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + TIME_PAUSE + question.time + TIME_EXTRA_ANIM;
          }
        }
        const now = Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)) / 1000);
        const timeLeft = timePast - now;
        if (Math.abs(timeLeft - val) > 0){
          // if(this._isMounted){
            this.setState({
              timeLeft: timeLeft
            });
          // }
        }
      }
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if (this.state.currentQuestion.displayResult === true) {
      // Next question
      this.checkQuizAndQuestion();
      // if(this._isMounted){
        this.setState({ displayResult: false });
      // }
    } else {
        this.setState({ displayResult: true });
    }
  };

  render() {
    // const screenHeight = Math.round(Dimensions.get("window").height);
    // const scrollHeight = screenHeight - 190;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {this.state.currentQuestion && (
          <View
            style={{
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FadeSlideView direction="down">
              <ImageBackground
                key={this.state.currentQuestion.displayResult}
                style={{
                  width: 66,
                  height: 66,
                  justifyContent: "center",
                  alignItems: "center"
                }}
                resizeMode={"contain"}
                source={require("../../assets/images/circle.png")}
              >
                <CountDownCircle
                  key={this.state.timeLeft}
                  seconds={
                    this.state.currentQuestion.displayResult
                      ? this.state.currentQuestion.displayResultTimeLeft
                      : this.state.timeLeft || 10
                  }
                  radius={28}
                  borderWidth={8}
                  color={
                    this.state.currentQuestion.displayResult
                      ? "limegreen"
                      : "#ff003f"
                  }
                  bgColor="#fff"
                  textStyle={{ fontSize: 18 }}
                  finishCallback={() => this.finishCountDownCallback()}
                  callbackTimer={this.callbackTimer}
                />
              </ImageBackground>
            </FadeSlideView>

            <View
              style={{
                top: 160,
                position: "fixed",
                height: "100%",
                width: "100%",
                paddingBottom: 150,
                overflowY: "scroll",
                overflowX: "hidden"
              }}
            >
              <View style={{ justifyContent: "center", alignItems: "center" }}>
                <FadeSlideView delay={150}>
                  <Text
                    style={{
                      color: "white",
                      fontSize: RF(3),
                      fontWeight: "bold",
                      marginTop: 10,
                      paddingLeft: 5,
                      paddingRight: 5,
                      textAlign: "center"
                    }}
                  >
                    {this.state.currentQuestion.question}
                  </Text>
                </FadeSlideView>

                <FadeSlideView delay={300} direction="right">
                  <PagingControl
                    key={this.state.timeLeft}
                    style={{ marginTop: 15 }}
                    max={this.state.currentQuiz.questions.length}
                    width={320}
                    current={this.state.currentQuestion.order}
                  />
                </FadeSlideView>

                <FadeSlideView delay={600} direction="up">
                  <View
                    style={{
                      flex: 1,
                      alignItems: "stretch",
                      alignContent: "stretch",
                      alignSelf: "stretch",
                      paddingBottom: 20
                    }}
                  >
                    <Response
                      {...this.props}
                      key={this.state.currentQuestion.displayResult}
                      data={{
                        answers: this.state.currentQuestion.answers,
                        currentQuestionId: this.state.currentQuestion.id,
                        currentQuestionTimeLeft: this.state.currentQuestion
                          .timeLeft,
                        currentQuestionTimeLeftMs: this.state.currentQuestion
                          .timeLeftMs,
                        currentQuestionTimeEnd: this.state.currentQuestion
                          .timeEnd,
                        currentQuizId: this.state.currentQuiz.id,
                        withImage: this.state.currentQuestion.withImage,
                        withResult: this.state.currentQuestion.displayResult,
                        currentResults: this.state.currentResults
                      }}
                    />
                  </View>
                </FadeSlideView>

                {this.state.currentQuestion.displayResult && (
                  <View
                    style={{
                      paddingBottom: 100
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        textAlignVertical: "center",
                        flex: 1,
                        marginLeft: 8,
                        fontSize: RF(2.2),
                        paddingBottom: 5
                      }}
                    >
                      Temps de réponse
                    </Text>
                    <Ranking data={this.state.answersByTimes} />
                  </View>
                )}
              </View>
            </View>
          </View>
        )}
      </View>
    );
  }
}

export default Question;

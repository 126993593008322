import React from "react";
// import { createStackNavigator, createAppContainer } from "react-navigation";
import { createSwitchNavigator } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";
import HomeScreen from "./screens/HomeScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignInScreen from "./screens/SignInScreen";
import ForecastScreen from "./screens/ForecastScreen";
import SelectGameScreen from "./screens/SelectGameScreen";
import CountDownScreen from "./screens/CountdownScreen";
import QuizzesScreen from "./screens/QuizzesScreen";
import NoQuizScreen from "./screens/QuizzesScreen/none";
import RankingScreen  from "./screens/RankingScreen";
import TeleScreen from "./screens/TeleScreen";
import SurveysScreen from "./screens/SurveysScreen";
import ThankyouScreen from "./screens/ThankyouScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ReactGA from 'react-ga';
import MenuNoLogin from "./screens/SelectGameScreen/menuNoLogin";
import PreForecastScreen from "./screens/ForecastScreen/preForecast";

// const AppNavigator = createStackNavigator({
const AppNavigator = createSwitchNavigator({
  Home:  HomeScreen ,
  SignUp: SignUpScreen ,
  SignIn: SignInScreen ,
  Forecast: ForecastScreen ,
  SelectGame: SelectGameScreen ,
  CountDown: CountDownScreen ,
  Quizzes: QuizzesScreen ,
  NoQuiz: NoQuizScreen ,
  Ranking: RankingScreen,
  Tele: TeleScreen,
  Surveys: SurveysScreen,
  Thankyou: ThankyouScreen,
  Profile: ProfileScreen,
  Menu : MenuNoLogin,
  PreForecast: PreForecastScreen
});

// const AppContainer = createAppContainer(AppNavigator);
const AppContainer = createBrowserApp(AppNavigator);

class AppNative extends React.Component {
  render() {
    ReactGA.initialize('UA-145155721-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
    return <AppContainer {...this.props} />;
  }
}

export default AppNative;

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  Button,
  TouchableHighlight
} from "react-native";
import RF from "react-native-responsive-fontsize";
import { withFirebase } from "../Firebase";
import { MAIN_COLOR, BUTTON_COLOR } from "../../constants/const";

export class SignIn extends Component {
  
  constructor(props) {
    super(props);
    this.currentUser = null;
    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser != null) {
          //Save prono if needed
          this.currentUser = authUser;
          this.savePronoIfNeed();
          props.navigation.navigate("SelectGame");
        }
      });
    }
  }

  savePronoIfNeed = () => {
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    console.log(toSave);
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          userId: this.currentUser.uid,
          displayName: this.currentUser.displayName,
          email: this.currentUser.email,
          ...dataToSave
        })
        .then(() => {
          window.localStorage.removeItem("match_forecast_to_save");
          window.localStorage.setItem(
            "match_forecast",
            JSON.stringify(dataToSave)
          );
        });
    }
  };

  validInput = () => {
    if (!this.state || !this.state.email) {
      return false;
    }
    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = firebase => {
    const errMessage = "L'email d'entrée invalide";
    if (this.validInput()) {
      this.setState({ error: null });

      const email = this.state.email;
      const password = this.state.password;
      const that = this;
      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then(function(result) {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          // do animation
          that.setState({ sentLink: true });
        })
        .catch(function(err) {
          // Some error occurred, you can inspect the code: error.code
          console.log(err);
          that.setState({ error: { message: err.code } });
        });

      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          maxWidth: 500
        }}
      >
        <View
          style={{
            padding: 20,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: MAIN_COLOR,
            borderColor: "white",
            borderWidth: 1,
            borderRadius: 6
          }}
        >
          {(!this.state || !this.state.sentLink) && (
            <Text
              style={{
                color: "white",
                fontSize: RF(2),
                fontWeight: "normal",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Connexion
            </Text>
          )}
          {(!this.state || !this.state.sentLink) && (
            <TextInput
              style={styles.inputBox}
              textContentType="emailAddress"
              placeholder={"Email *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ email: text });
              }}
              value={(this.state && this.state.email) || ""}
            />
          )}
          {(!this.state || !this.state.sentLink) && (
            <TextInput
              textContentType="password"
              style={styles.inputBox}
              secureTextEntry={true}
              placeholder={"Mot de passe *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ password: text });
              }}
              value={(this.state && this.state.password) || ""}
            />
          )}
          {(!this.state || !this.state.sentLink) && (
            <Button
              title="Valider"
              color={BUTTON_COLOR}
              onPress={() => {
                this.onValidateClicked();
              }}
            />
          )}

          {this.state && this.state.error && (
            <Text
              style={{
                color: "red",
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center"
              }}
            >
              * {this.state.error.message}
            </Text>
          )}
          <TouchableHighlight
            style={{ alignSelf: "flex-end" }}
            onPress={() => navigate("SignUp")}
          >
            <Text
              style={{
                color: BUTTON_COLOR,
                fontSize: RF(2),
                textAlign: "center",
                padding: 8
              }}
            >
              <i>Créer un compte</i>
            </Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputBox: {
    height: 30,
    width: "100%",
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "white",
    backgroundColor: "white",
    padding: 5,
    margin: 3,
    marginBottom: 20
  }
});

export default withFirebase(SignIn);

import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  ImageBackground,
  Animated,
  Easing,
  Dimensions,
  Image
} from "react-native";
import { MAIN_COLOR, THEME, MAIN_URL, EMAILS_RESULTS } from "../../constants/const";
import NumberOnline from "./Elements/online";
import TeleHeader from "./Elements/header";
import TeleCountDown from "./Elements/countdown";
import QRCode from "react-qr-code";
import RF from "react-native-responsive-fontsize";
import {
  getWidthPercent,
  getHeightPercent
} from "../../libs/list_picker/ratio";
import moment from "moment";
import MatchPronostic from "./matchPronostic";

export class TeleMatchHome extends Component {
  springSize = new Animated.Value(3);
  setted = false;
  _isMounted = false;
  pronosticsOrigin = [];
  pronosticsSorted = [];
  lastFirst100s = [];
  winners = [];

  constructor(props) {
    super(props);

    this.state = {
      timeLeft: props.timeLeft,
      currentMatch: props.currentMatch,
      animate: false,
      animations: {
        bgWidth: new Animated.Value(80),
        bgMarginTop: new Animated.Value(100),
        bgMarginBottom: new Animated.Value(200),
        textMarginBottom: new Animated.Value(90),
        fadeOut: new Animated.Value(1)
      },
      screen: "countdown",
      timeEnd: 0,
      liveTime: "00 : 00",
      liveTimeValue: 0,
      pronostics: [],
      liveData: {},
      updateNum: 0
    };
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);

    if (props.firebase && props.currentMatch) {
      props.firebase
        .pronostics_responses_query(props.currentMatch.id)
        .onSnapshot(snapshot => {
          if (snapshot.size <= 0) {
            window.localStorage.removeItem("pronostics");
          }
          // Actually the data should be only 1
          var arrData = [];
          snapshot.forEach(data => {
            arrData.push(data.data());
          });
          window.localStorage.setItem("pronostics", JSON.stringify(arrData));
          // calculate results
          // bind data
          // if (this._isMounted) {
          //   this.setState({
          //     pronostics: arrData,
          //     updateNum: this.state.updateNum + 1
          //   });
          // }
          this.pronosticsOrigin = arrData;
          this._calculatePronostics();
        });
    }
  }
  spring() {
    this.springSize.setValue(3);
    Animated.spring(this.springSize, {
      toValue: 1,
      bounciness: 12
    }).start();
  }

  doAnimation = ({
    bgWidth,
    bgMarginTop,
    bgMarginBottom,
    textMarginBottom,
    fadeOut
  }) =>
    Animated.parallel([
      Animated.timing(bgWidth, {
        toValue: 100,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginTop, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(bgMarginBottom, {
        toValue: 0,
        easing: Easing.elastic(),
        duration: 500,
        delay: 0
      }),
      Animated.timing(textMarginBottom, {
        toValue: 350,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      }),
      Animated.timing(fadeOut, {
        toValue: 0,
        easing: Easing.ease,
        duration: 300,
        delay: 0
      })
    ]);

  callbackTimerForEnd(val) {
    if (this.props.callbackTimerForEnd) {
      this.props.callbackTimerForEnd(val);
    }
  }

  callbackTimer(val) {
    // console.log("callbackTimer =>", val);
    //correct the timeLeft
    if (this.props.callbackTimer) {
      this.props.callbackTimer(val);
    }
    //Do animation
    if (val <= 0) {
      this.setState({ animate: true });
      const {
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut
      } = this.state.animations;

      this.doAnimation({
        bgWidth,
        bgMarginTop,
        bgMarginBottom,
        textMarginBottom,
        fadeOut
      }).start(() => {
        // Do stuff after animations
        var intervalId = setInterval(() => {
          this.checkEndTime();
        }, 1000);
        this.setState({ intervalId: intervalId, screen: "live" });
        this.syncDataLive();
      });
    }
  }

  syncDataLive = () => {
    if (this.props.firebase && this.props.currentMatch) {
      this.props.firebase
        .match_lives(this.props.currentMatch.match_id)
        .onSnapshot(docSnapshot => {
          // Actually the data should be only 1
          var lData = docSnapshot.data();
          // bind data
          if (lData) {
            if (this._isMounted) {
              this.setState(
                {
                  liveData: lData,
                  liveTimeValue:
                    (lData.minute ? lData.minute : 0) * 60 +
                    (lData.injury_time ? lData.injury_time : 0) * 60 +
                    parseInt(lData.second, 10)
                  // updateNum: this.state.updateNum + 1
                },
                () => {
                  this.checkEndTime();
                }
              );
            }
          }
        });
    }
  };

  checkEndTime = () => {

    if (this.props.currentMatch) {
      const time = this.state.liveTimeValue;
      const min = Math.floor(time / 60);
      const sec = time - min * 60;
      const minDisplay = min < 10 ? `0${min}` : `${min}`;
      const secDisplay = sec < 10 ? `0${sec}` : `${sec}`;
      if (this.state.liveData.status) {
        //calculate the result pronostic
        this._calculatePronostics();
        if (this.state.liveData.status === "NS") {
          this.setState({ liveTime: `Pas commencé` });
        } else if (this.state.liveData.status === "HT") {
          this.setState({ liveTime: `Mi-temps` });
        } else if (this.state.liveData.status === "FT") {
          // save the winner
          this._saveTheWinners();
          this.setState({ liveTime: `Terminé` }, () => {
            if (this.props.callbackMatch) {
              //send email if only 1 winner
              if (this.winners.length === 1) {
                // call email
                const sendMailMatchWinners = this.props.firebase.functions.httpsCallable(
                  "sendMailMatchWinners"
                );
                sendMailMatchWinners({
                  currentMatch: this.props.currentMatch,
                  rankList: this.pronosticsSorted,
                  winner: this.pronosticsSorted[0],
                  to: EMAILS_RESULTS
                });
              }
              this.props.callbackMatch(
                this.winners,
                this.state.liveData,
                this.pronosticsSorted
              );
            }
          });
        } else {
          this.setState({
            liveTime: `${minDisplay} : ${secDisplay}`,
            liveTimeValue: time + 1
          });
        }
      } else {
        this.setState({
          liveTime: `${minDisplay} : ${secDisplay}`,
          liveTimeValue: time + 1
        });
      }
    }
  };

  _saveTheWinners = () => {
    if (this.props.firebase && this.state.liveTime !== "Terminé") {
      var batch = this.props.firebase.db.batch();
      for (let i = 0; i < this.pronosticsSorted.length; i++) {
        const el = this.pronosticsSorted[i];
        var doc = this.props.firebase.match_winner_ranking(
          this.props.currentMatch.id + "_" + this.props.currentMatch.match_id,
          i + 1
        );
        batch.set(doc, el);
      }
      // Commit the batch
      batch.commit().then(function() {
        console.log("updated winner");
      });
    }
  };

  _getScoreDirection = (home, visitor) => {
    if (parseInt(home, 10) > parseInt(visitor, 10)) {
      return 1;
    }
    if (parseInt(home, 10) === parseInt(visitor, 10)) {
      return 0;
    }
    if (parseInt(home, 10) < parseInt(visitor, 10)) {
      return -1;
    }
  };

  _calculatePronostics = () => {
    if (this.state.liveData.status && this.pronosticsOrigin.length > 0) {
      const homeScore = this.state.liveData.home_score || 0;
      const visitorScore = this.state.liveData.visitor_score || 0;

      var pronosticUpdate = this.pronosticsOrigin.map(el => {
        const firstScorerName =
          this.state.liveData.first_scorer.player_name || "";
        var firstScoreMinute = this.state.liveData.first_score_minute;
        const firstScoreMinuteNumber = this.state.liveData.first_score_minute;

        const scoreDirection = this._getScoreDirection(homeScore, visitorScore);
        const scoreAbs =
          Math.abs(parseInt(el.scoreHome, 10) - parseInt(homeScore, 10)) +
          Math.abs(parseInt(el.scoreVisitor, 10) - parseInt(visitorScore, 10));
        if (
          firstScoreMinute === 45 &&
          this.state.liveData.first_score_minute_extra > 0
        ) {
          firstScoreMinute = "ex1";
        }
        if (
          firstScoreMinute === 90 &&
          this.state.liveData.first_score_minute_extra > 0
        ) {
          firstScoreMinute = "ex2";
        }

        var firstScoreMinuteValue = el.firstScoreMinute;
        if (el.firstScoreMinute === "ex1") {
          firstScoreMinuteValue = 40.5;
        }
        if (el.firstScoreMinute === "ex2") {
          firstScoreMinuteValue = 90.5;
        }

        el.firstScoreMinuteDistant = Math.abs(
          firstScoreMinuteValue - firstScoreMinuteNumber
        );
        el.scoreDistant = scoreAbs; // === 1 ;//&& parseInt(el.scoreHome, 10) >= homeScore && parseInt(el.scoreVisitor, 10) >= visitorScore;
        el.scoreDirection = this._getScoreDirection(
          el.scoreHome,
          el.scoreVisitor
        );

        el.correct_direction = el.scoreDirection === scoreDirection;
        el.correct_score =
          parseInt(el.scoreHome, 10) === homeScore &&
          parseInt(el.scoreVisitor, 10) === visitorScore;
        el.correct_scorer =
          el.firstScorer.name.trim() === firstScorerName.trim();
        el.correct_minute = el.firstScoreMinute === firstScoreMinute;

        el.point =
          (el.correct_direction === true ? 32 : 0) +
          (el.correct_score === true ? 16 : 0);
        // +
        // (el.correct_scorer === true ? 4 : 0) +
        // (el.correct_minute === true ? 2 : 0) - el.scoreDistant
        el.color = "default";
        return el;
      });

      // sorting with points
      this.pronosticsSorted = pronosticUpdate.slice(0);
      this.pronosticsSorted.sort((a, b) => {
        return (
          b.point - a.point ||
          a.scoreDistant - b.scoreDistant ||
          b.correct_scorer - a.correct_scorer ||
          b.correct_minute - a.correct_minute ||
          a.firstScoreMinuteDistant - b.firstScoreMinuteDistant
        );
      });

      const firstPlace = this.pronosticsSorted[0];
      if (firstPlace.point > 0) {
        var winnerPlaces = this.pronosticsSorted.filter(el => {
          return (
            el.point === firstPlace.point &&
            el.correct_scorer === firstPlace.correct_scorer &&
            el.correct_minute === firstPlace.correct_minute &&
            el.scoreDistant === firstPlace.scoreDistant &&
            el.firstScoreMinuteDistant === firstPlace.firstScoreMinuteDistant
          );
        });
        if (homeScore === 0 && visitorScore === 0) {
          winnerPlaces = this.pronosticsSorted.filter(el => {
            return (
              parseInt(el.scoreHome, 10) === 0 &&
              parseInt(el.scoreVisitor, 10) === 0
            );
          });
        }
        this.winners = winnerPlaces;
        // console.log("winners.length => ", winnerPlaces.length);
        var first100 = this.pronosticsSorted.slice(0, 42);
        // console.log("first100 => ", first100);
        //check if not same elements last time
        if (this.lastFirst100s.length === 0) {
          first100 = this._array_shuffle(first100);
          this.lastFirst100s = first100.slice(0);
        } else {
          if (!this._array_same_elements(first100, this.lastFirst100s)) {
            // then
            first100 = this._array_shuffle(first100);
            this.lastFirst100s = first100.slice(0);
          }
        }
        var thirdPoint = null;
        var thirdDistant = null;
        var thirdScorer = null;
        var thirdMinute = null;
        var thirdScoreDistant = null;

        // Score !== 0 - 0
        if (winnerPlaces.length >= 3) {
          // found 3 firsts
          pronosticUpdate = this.lastFirst100s.map(el => {
            if (homeScore === 0 && visitorScore === 0) {
              if (el.point === firstPlace.point && el.scoreDistant === firstPlace.scoreDistant) {
                el.color = "1st";
              } else {
                el.color = "red";
              }
            } else {
              if (
                el.point === firstPlace.point &&
                el.correct_scorer === firstPlace.correct_scorer &&
                el.correct_minute === firstPlace.correct_minute &&
                el.scoreDistant === firstPlace.scoreDistant &&
                el.firstScoreMinuteDistant ===
                  firstPlace.firstScoreMinuteDistant
              ) {
                el.color = "1st";
              } else {
                el.color = "red";
              }
            }
            return el;
          });
        } else if (winnerPlaces.length === 2) {
          // found 2 firsts , next should be 3rd
          if (this.state.pronostics.length > 2) {
            const thirdPlace = this.pronosticsSorted[2];
            // console.log("thirdPlace => ", thirdPlace);
            thirdPoint = thirdPlace.point;
            thirdDistant = thirdPlace.firstScoreMinuteDistant;
            thirdScoreDistant = thirdPlace.scoreDistant;
            thirdScorer = thirdPlace.correct_scorer;
            thirdMinute = thirdPlace.correct_minute;
          }
          pronosticUpdate = this.lastFirst100s.map(el => {
            if (homeScore === 0 && visitorScore === 0) {
              if (el.point === firstPlace.point && el.scoreDistant === firstPlace.scoreDistant) {
                el.color = "1st";
              } else if (el.point === thirdPoint && el.scoreDistant === thirdScoreDistant) {
                el.color = "3rd";
              } else {
                el.color = "red";
              }
            } else {
              if (
                el.point === firstPlace.point &&
                el.correct_scorer === firstPlace.correct_scorer &&
                el.correct_minute === firstPlace.correct_minute &&
                el.scoreDistant === firstPlace.scoreDistant &&
                el.firstScoreMinuteDistant ===
                  firstPlace.firstScoreMinuteDistant
              ) {
                el.color = "1st";
              } else if (
                el.point === thirdPoint &&
                el.correct_scorer === thirdScorer &&
                el.correct_minute === thirdMinute &&
                el.scoreDistant === thirdScoreDistant &&
                el.firstScoreMinuteDistant === thirdDistant
              ) {
                el.color = "3rd";
              } else {
                el.color = "red";
              }
            }

            return el;
          });
        } else {
          //if (winnerPlaces.length === 1) {
          // found 1 first , next should be 2rd and 3rd
          var secondPoint = null;
          var secondDistant = null;
          var secondScoreDistant = null;
          var secondScorer = null;
          var secondMinute = null;
          thirdPoint = null;
          thirdDistant = null;
          thirdScoreDistant = null;
          thirdScorer = null;
          thirdMinute = null;
          if (this.state.pronostics.length > 1) {
            const secondPlace = this.pronosticsSorted[1];
            secondPoint = secondPlace.point;
            secondDistant = secondPlace.firstScoreMinuteDistant;
            secondScoreDistant = secondPlace.scoreDistant;
            secondScorer = secondPlace.correct_scorer;
            secondMinute = secondPlace.correct_minute;
          }
          if (secondPoint !== null) {
            //check howmany of 2nd places
            const secondWinnerPlaces = this.pronosticsSorted.filter(el => {
              if (homeScore === 0 && visitorScore === 0) {
                return el.point === secondPoint && el.scoreDistant === secondScoreDistant;
              } else {
                return (
                  el.point === secondPoint &&
                  el.correct_scorer === secondScorer &&
                  el.correct_minute === secondMinute &&
                  el.scoreDistant === secondScoreDistant &&
                  el.firstScoreMinuteDistant === secondDistant
                );
              }
            });
            if (secondWinnerPlaces.length > 1) {
              // found 2nd and 3rd
            } else {
              // need to find 3rd
              if (this.state.pronostics.length > 2) {
                const thirdPlace = this.pronosticsSorted[2];
                thirdPoint = thirdPlace.point;
                thirdDistant = thirdPlace.firstScoreMinuteDistant;
                thirdScoreDistant = thirdPlace.scoreDistant;
                thirdScorer = thirdPlace.correct_scorer;
                thirdMinute = thirdPlace.correct_minute;
              }
            }
          }

          pronosticUpdate = this.lastFirst100s.map(el => {
            if (homeScore === 0 && visitorScore === 0) {
              if (el.point === firstPlace.point && el.scoreDistant === firstPlace.scoreDistant) {
                el.color = "1st";
              } else if (el.point === secondPoint && el.scoreDistant === secondScoreDistant) {
                el.color = "2nd";
              } else if (el.point === thirdPoint && el.scoreDistant === thirdScoreDistant) {
                el.color = "3rd";
              } else {
                el.color = "red";
              }
            } else {
              if (
                el.point === firstPlace.point &&
                el.correct_scorer === firstPlace.correct_scorer &&
                el.correct_minute === firstPlace.correct_minute &&
                el.scoreDistant === firstPlace.scoreDistant &&
                el.firstScoreMinuteDistant ===
                  firstPlace.firstScoreMinuteDistant
              ) {
                el.color = "1st";
              } else if (
                el.point === secondPoint &&
                el.correct_scorer === secondScorer &&
                el.correct_minute === secondMinute &&
                el.scoreDistant === secondScoreDistant &&
                el.firstScoreMinuteDistant === secondDistant
              ) {
                el.color = "2nd";
              } else if (
                el.point === thirdPoint &&
                el.correct_scorer === thirdScorer &&
                el.correct_minute === thirdMinute &&
                el.scoreDistant === thirdScoreDistant &&
                el.firstScoreMinuteDistant === thirdDistant
              ) {
                el.color = "3rd";
              } else {
                el.color = "red";
              }
            }

            return el;
          });
        }
      }

      if (this._isMounted) {
        this.setState({
          pronostics: pronosticUpdate,
          updateNum: this.state.updateNum + 1
        });
      }
    }
  };

  // assumes array elements are primitive types
  _array_same_elements = (a1, a2) => {
    let superSet = {};
    for (let i = 0; i < a1.length; i++) {
      const e = a1[i].userId;
      superSet[e] = 1;
    }
    for (let i = 0; i < a2.length; i++) {
      const e = a2[i].userId;
      if (!superSet[e]) {
        return false;
      }
      superSet[e] = 2;
    }
    for (let e in superSet) {
      if (superSet[e] === 1) {
        return false;
      }
    }
    return true;
  };

  _array_shuffle = array => {
    var m = array.length,
      t,
      i;

    // While there remain elements to shuffle…
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }

    return array;
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  render() {
    const { animations, animate } = this.state;
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    // console.log(this.state);
    return (
      <View style={styles.container}>
        {this.state.screen === "countdown" && (
          <Animated.View
            style={{
              flex: 1,
              alignSelf: "center",
              width: animate
                ? animations.bgWidth.interpolate({
                    inputRange: [80, 100],
                    outputRange: ["80%", "100%"]
                  })
                : "80%",
              marginTop: animate
                ? animations.bgMarginTop
                : Math.round(screenHeight / 6)
            }}
          >
            <Animated.View
              style={{
                flex: 1,
                marginBottom: animate
                  ? animations.bgMarginBottom
                  : Math.round(screenHeight / 6)
              }}
            >
              <ImageBackground
                style={{
                  flex: 1
                }}
                source={require("../../assets/themes/" + THEME + "/bg.png")}
                resizeMode={"cover"}
              >
                <Animated.View
                  style={{
                    flexDirection: "row",
                    opacity: animate ? animations.fadeOut : 1
                  }}
                >
                  <View style={{ alignSelf: "flex-start", margin: 30 }}>
                    <TeleCountDown
                      timeLeft={this.state.timeLeft}
                      callbackTimer={this.callbackTimer}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      alignItems: "flex-end",
                      margin: 30
                    }}
                  >
                    {this.props.currentMatch && (
                      <NumberOnline
                        type="match"
                        logeName={this.props.currentMatch.subtitle}
                        {...this.props}
                      />
                    )}
                  </View>
                </Animated.View>
                {this.props.currentMatch && (
                  <Animated.View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: animate
                        ? animations.textMarginBottom
                        : Math.round(screenHeight / 4)
                    }}
                  >
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Image
                        style={{
                          width: getWidthPercent(15),
                          height: getWidthPercent(15)
                        }}
                        source={this.props.currentMatch.team_home_logo}
                        resizeMode="contain"
                      />
                      <Text
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: RF(4),
                          fontWeight: "bold"
                        }}
                      >
                        {this.props.currentMatch.team_home}
                      </Text>
                    </View>
                    <Text
                      style={{
                        color: "white",
                        textAlign: "center",
                        fontSize: RF(10),
                        fontWeight: "bold",
                        marginLeft: getWidthPercent(1),
                        marginRight: getWidthPercent(1)
                      }}
                    >
                      {moment(
                        this.props.currentMatch.start.seconds * 1000
                      ).format("HH:mm")}
                    </Text>
                    <View
                      style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Image
                        style={{
                          width: getWidthPercent(15),
                          height: getWidthPercent(15)
                        }}
                        source={this.props.currentMatch.team_visitor_logo}
                        resizeMode="contain"
                      />
                      <Text
                        style={{
                          color: "white",
                          textAlign: "center",
                          fontSize: RF(4),
                          fontWeight: "bold"
                        }}
                      >
                        {this.props.currentMatch.team_visitor}
                      </Text>
                    </View>
                  </Animated.View>
                )}
              </ImageBackground>
            </Animated.View>
          </Animated.View>
        )}

        {this.state.screen === "live" && (
          <View style={styles.liveContainer}>
            <View style={styles.liveTime} key={this.state.liveTime}>
              <Text style={styles.liveTimeText}>{this.state.liveTime}</Text>
            </View>
            <View style={styles.liveLine}>
              <Text style={styles.liveTeamTextLeft}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveText}>
                {this.state.liveData.home_score
                  ? this.state.liveData.home_score
                  : 0}
              </Text>
              <Text style={styles.liveText}>-</Text>
              <Text style={styles.liveText}>
                {this.state.liveData.visitor_score
                  ? this.state.liveData.visitor_score
                  : 0}
              </Text>
              <Image
                style={styles.liveLogo}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.liveTeamTextRight}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>
            <View style={styles.pronosticContainer}>
              <View style={styles.pronosticHeader}>
                <Text style={styles.pronosticTextLeft}>
                  PREMIER BUTEUR :{" "}
                  {this.state.liveData.first_scorer
                    ? this.state.liveData.first_scorer.player_name
                    : "--"}
                </Text>
                <Text style={styles.pronosticTextRight}>
                  MINUTE DU PREMIER BUT :{" "}
                  {this.state.liveData.first_score_minute
                    ? `${this.state.liveData.first_score_minute}'`
                    : "--"}
                </Text>
              </View>
              <View style={{ width: "100%" }}>
                <MatchPronostic
                  tall={screenHeight * 0.7}
                  key={this.state.pronostics.length}
                  data={this.state.pronostics}
                />
              </View>
            </View>
          </View>
        )}
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            position: "absolute"
          }}
        />
        <Animated.Image
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            alignSelf: "center",
            opacity: animate ? animations.fadeOut : 1
          }}
          source={require("../../assets/themes/" + THEME + "/banner_full.png")}
          resizeMode={"stretch"}
        />
        <Animated.View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "white",
            borderRadius: 5,
            position: "absolute",
            bottom: 0,
            opacity: animate ? animations.fadeOut : 1
          }}
        >
          <View
            style={{
              margin: 20
            }}
          >
            <QRCode
              value={"https://" + MAIN_URL}
              fgColor={MAIN_COLOR}
              size={Math.round(screenHeight / 4)}
            />
          </View>
          <View style={{flexDirection:"column"}} >
            <Text style={{ color: MAIN_COLOR, margin: 20, textAlign:"center", fontSize: RF(4) }}>
              {`FAITES VOTRE`} <b>PRONOSTIC</b> {`
SUR LE SITE CI-DESSOUS `}
            </Text>
            <Text style={{ color: "red", margin: 20, fontSize: RF(8), fontWeight:"bold" }}>
              {MAIN_URL}
            </Text>
            </View>
        </Animated.View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: MAIN_COLOR,
    flex: 1
  },
  liveContainer: {
    justifyContent: "center",
    alignItems: "center"
  },
  liveTime: {
    marginTop: getHeightPercent(5),
    justifyContent: "center",
    alignItems: "center",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: getHeightPercent(2)
  },
  liveTimeText: {
    fontSize: getHeightPercent(2),
    textAlign: "center",
    color: "white",
    marginTop: getHeightPercent(1),
    marginBottom: getHeightPercent(1),
    marginLeft: getHeightPercent(2),
    marginRight: getHeightPercent(2)
  },
  liveLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    maxWidth: getWidthPercent(80)
  },
  liveLogo: {
    width: getWidthPercent(7),
    height: getWidthPercent(7),
    margin: getWidthPercent(1)
  },
  liveText: {
    color: "white",
    fontSize: getWidthPercent(5),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },
  liveTeamTextLeft: {
    color: "white",
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "right",
    width: getWidthPercent(20)
  },
  liveTeamTextRight: {
    color: "white",
    fontSize: getWidthPercent(3),
    fontWeight: "bold",
    textAlign: "left",
    width: getWidthPercent(20)
  },
  liveTextScorer: {
    color: "white",
    fontSize: getWidthPercent(4),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercent(1),
    marginRight: getWidthPercent(1)
  },
  pronosticContainer: {
    width: getWidthPercent(90)
  },
  pronosticHeader: {
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    flexDirection: "row",
    width: "100%",
    flex: 1,
    paddingBottom: getHeightPercent(1)
  },
  pronosticTextLeft: {
    color: "#ccc",
    textTransform: "capitalize",
    alignSelf: "flex-start",
    flex: 1,
    fontSize: getWidthPercent(1)
  },
  pronosticTextRight: {
    color: "#ccc",
    textTransform: "capitalize",
    alignSelf: "flex-end",
    fontSize: getWidthPercent(1)
  }
});

export default TeleMatchHome;

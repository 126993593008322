import React, { Component } from "react";
import SignUp from "../../components/SignUp";
import withMainLayout from "../../components/MainLayout";

export class SignUpScreen extends Component {
  render() {
    return <SignUp {...this.props} />;
  }
}

export default withMainLayout(SignUpScreen);

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  CheckBox,
  Button,
  TouchableHighlight
} from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  THEME,
  MAIN_COLOR,
  BUTTON_COLOR,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  TEXT_OPTION_1,
  TEXT_OPTION_2,
  TEXT_OPTION_3,
  URL_RULES
} from "../../constants/const";

export class Profile extends Component {
  _isMount = false;
  state = { changePassword: false, confirmDeleteAccount: false };

  constructor(props) {
    super(props);
    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          props.firebase
            .user(authUser.uid)
            .get()
            .then(u => {
              if (this._isMount) {
                const user = u.data();
                user.id = authUser.uid;
                this.setState({ ...user });
              }
            });
        }
      });
    }
  }

  componentDidMount() {
    this._isMount = true;
  }

  componentWillUnmount() {
    this._isMount = false;
  }

  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      return false;
    }

    if (!this.state.lastName || this.state.lastName.length === "") {
      return false;
    }

    if (!this.state.email || this.state.email.length === "") {
      return false;
    }

    if (
      this.state.changePassword &&
      (!this.state.password || this.state.password.length === "")
    ) {
      return false;
    }

    if (!this.state.agreement || this.state.agreement === false) {
      return false;
    }

    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = () => {
    const errMessage = "Paramètre d'entrée invalide ou manquant";
    if (this.validInput()) {
      this.setState({ error: null });
      const {
        email,
        password,
        passwordActuel,
        firstName,
        lastName,
        company,
        phone
      } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;

      firebase
        .user(this.state.id)
        .set({
          email,
          firstName,
          lastName,
          company: company || false,
          phone: phone || false,
          newsletter: that.state.newsletter || false,
          newsletter_commercial: that.state.newsletter_commercial || false,
          agreement: true,
          theme: THEME
        },{merge:true})
        .then(() => {
          that.setState({ error: null });
          firebase.auth.currentUser
            .updateProfile({
              displayName: `${firstName} ${lastName.charAt(0).toUpperCase()}.`
            })
            .then(function() {
              //change password if needed
              if (that.state.changePassword) {
                var cred = firebase.getCredential(email, passwordActuel);
                firebase.auth.currentUser
                  .reauthenticateWithCredential(cred)
                  .then(() => {
                    firebase.auth.currentUser
                      .updatePassword(password)
                      .then(() => {
                        console.log("Password updated!");
                        navigate("Home");
                      })
                      .catch(error => {
                        that.setState({ error });
                        console.log(error);
                      });
                  })
                  .catch(function(error) {
                    that.setState({ error });
                    console.log(error);
                  });
              } else {
                navigate("Home");
              }
            })
            .catch(function(error) {
              that.setState({ error });
              console.log(error);
            });
        })
        .catch(error => {
          that.setState({ error });
          console.log(error);
        });

      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  removeAccount = () => {
    if (this.state.confirmDeleteAccount && this.state.passwordActuel) {
      const { email, passwordActuel } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;

      var cred = firebase.getCredential(email, passwordActuel);
      firebase.auth.currentUser
        .reauthenticateWithCredential(cred)
        .then(() => {
          firebase.auth.currentUser
            .delete()
            .then(() => {
              console.log("account deleted!");
              //delete all data
              window.localStorage.clear();
              that.props.firebase.doSignOut();
              navigate("Home");
            })
            .catch(error => {
              that.setState({ error });
              console.log(error);
            });
        })
        .catch(function(error) {
          that.setState({ error });
          console.log(error);
        });
    }
  };

  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          maxWidth: 500
        }}
        keyboardShouldPersistTaps="always"
      >
        {this.state.confirmDeleteAccount === false && (
          <View
            style={{
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderColor: "white",
              borderWidth: 1,
              borderRadius: 6
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(2),
                fontWeight: "normal",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Profil
            </Text>

            {this.state && this.state.error && (
              <Text
                style={{
                  color: "red",
                  fontSize: RF(1.5),
                  margin: 10,
                  textAlign: "center"
                }}
              >
                * {this.state.error.message}
              </Text>
            )}

            <TextInput
              style={styles.inputBox}
              placeholder={"Prénom *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ firstName: text });
              }}
              value={(this.state && this.state.firstName) || ""}
              autoComplete="false"
            />
            <TextInput
              style={styles.inputBox}
              placeholder={"Nom *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ lastName: text });
              }}
              value={(this.state && this.state.lastName) || ""}
              autoComplete="false"
            />

            {PROFILE_COMPANY === true && (
              <TextInput
                style={styles.inputBox}
                placeholder={"Société "}
                placeholderTextColor={"lightgray"}
                onChangeText={text => {
                  this.setState({ company: text });
                }}
                value={(this.state && this.state.company) || ""}
                autoComplete="false"
              />
            )}
            {PROFILE_PHONE === true && (
              <TextInput
                style={styles.inputBox}
                placeholder={"Numéro de téléphone "}
                placeholderTextColor={"lightgray"}
                keyboardType="phone-pad"
                onChangeText={text => {
                  this.setState({ phone: text });
                }}
                value={(this.state && this.state.phone) || ""}
              />
            )}

            <View
              style={{
                height: 1,
                width: "100%",
                margin: 10,
                alignSelf: "center",
                backgroundColor: "lightgray"
              }}
            />

            <TextInput
              style={styles.inputBox}
              textContentType="emailAddress"
              placeholder={"Email *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ email: text });
              }}
              disabled
              value={(this.state && this.state.email) || ""}
            />

            {this.state.changePassword === true && (
              <TextInput
                style={styles.inputBox}
                secureTextEntry={true}
                placeholder={"Mot de passe actuel *"}
                placeholderTextColor={"lightgray"}
                onChangeText={text => {
                  this.setState({ passwordActuel: text });
                }}
                value={(this.state && this.state.passwordActuel) || ""}
                autoComplete="false"
              />
            )}
            {this.state.changePassword === true && (
              <TextInput
                style={styles.inputBox}
                secureTextEntry={true}
                placeholder={"Nouveau mot de passe *"}
                placeholderTextColor={"lightgray"}
                onChangeText={text => {
                  this.setState({ password: text });
                }}
                value={(this.state && this.state.password) || ""}
                autoComplete="false"
              />
            )}
            {this.state.changePassword === false && (
              <TouchableHighlight
                style={{ alignSelf: "flex-end" }}
                onPress={() => {
                  this.setState({ changePassword: true });
                }}
              >
                <Text
                  style={{
                    color: BUTTON_COLOR,
                    fontSize: RF(1.5),
                    textAlign: "center",
                    padding: 8
                  }}
                >
                  <i>Modifier le mot de passe</i>
                </Text>
              </TouchableHighlight>
            )}
            {TEXT_OPTION_1 && (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20
                }}
              >
                <CheckBox
                  value={true}
                  onValueChange={v => {
                    this.setState({ agreement: v });
                  }}
                  disabled
                />
                <Text
                  style={{
                    flex: 1,
                    textAlignVertical: "center",
                    color: "white",
                    fontSize: RF(1.5),
                    fontWeight: "normal",
                    marginLeft: 10
                  }}
                >
                  J'ai lu et accepte les modalités figurant dans {" "}
                  <a rel="noopener noreferrer" href={URL_RULES} target="_blank">
                    le règlement du présent concours
                  </a> {" "}
                  *
                </Text>
              </View>
            )}
            {TEXT_OPTION_2 && (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20
                }}
              >
                <CheckBox
                  value={(this.state && this.state.newsletter) || false}
                  onValueChange={v => {
                    this.setState({ newsletter: v });
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlignVertical: "center",
                    color: "white",
                    fontSize: RF(1.5),
                    fontWeight: "normal",
                    marginLeft: 10
                  }}
                >
                  {TEXT_OPTION_2}
                </Text>
              </View>
            )}
            {TEXT_OPTION_3 && (
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 20
                }}
              >
                <CheckBox
                  value={
                    (this.state && this.state.newsletter_commercial) || false
                  }
                  onValueChange={v => {
                    this.setState({ newsletter_commercial: v });
                  }}
                />
                <Text
                  style={{
                    flex: 1,
                    textAlignVertical: "center",
                    color: "white",
                    fontSize: RF(1.5),
                    fontWeight: "normal",
                    marginLeft: 10
                  }}
                >
                  {TEXT_OPTION_3}
                </Text>
              </View>
            )}
            <Button
              title="Valider"
              color={BUTTON_COLOR}
              onPress={() => {
                this.onValidateClicked();
              }}
            />
          </View>
        )}

        <TouchableHighlight
          style={{ alignSelf: "flex-end" }}
          onPress={() => {
            this.setState({
              confirmDeleteAccount: !this.state.confirmDeleteAccount
            });
          }}
        >
          <Text
            style={{
              color: BUTTON_COLOR,
              fontSize: RF(1.5),
              textAlign: "center",
              padding: 8
            }}
          >
            <i>
              {this.state.confirmDeleteAccount
                ? "Votre profil"
                : "Supprimer mon compte"}
            </i>
          </Text>
        </TouchableHighlight>
        {this.state.confirmDeleteAccount === true && (
          <View
            style={{
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderColor: "white",
              borderWidth: 1,
              borderRadius: 6
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(2),
                fontWeight: "normal",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Supprimer mon compte
            </Text>
            {this.state && this.state.error && (
              <Text
                style={{
                  color: "red",
                  fontSize: RF(1.5),
                  margin: 10,
                  textAlign: "center"
                }}
              >
                * {this.state.error.message}
              </Text>
            )}
            <Text
              style={{
                color: BUTTON_COLOR,
                fontSize: RF(1.5),
                textAlign: "center",
                padding: 8
              }}
            >
              La suppression du compte et donc, des données associées:
            </Text>
            <TextInput
              style={styles.inputBox}
              secureTextEntry={true}
              placeholder={"Votre mot de passe actuel *"}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ passwordActuel: text });
              }}
              value={(this.state && this.state.passwordActuel) || ""}
              autoComplete="false"
            />
            <View style={{ marginTop: 20 }}>
              <Button
                title="Supprimer mon compte"
                color={BUTTON_COLOR}
                onPress={() => {
                  if (
                    window.confirm(
                      "Êtes-vous certain de vouloir supprimer votre compte ?"
                    )
                  ) {
                    this.removeAccount();
                  }
                }}
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputBox: {
    height: 30,
    width: "100%",
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "white",
    backgroundColor: "white",
    padding: 5,
    margin: 3
  }
});

export default Profile;

import React, { Component } from "react";
import Surveys from "../../components/Sondages/sondages";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";

export class SurveysScreen extends Component {
  render() {
    return (
      <Surveys {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(SurveysScreen));

import React, { Component } from "react";
import { Animated, Text, View } from "react-native";
import RF from "react-native-responsive-fontsize";

export class TeleCountDown extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.springValue = new Animated.Value(0.3);
    this.startValue = props.timeLeft || 0;
    this.state = { currentCount: 0 };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  _displayDate = timeLeft => {
    const secLeft = timeLeft % 60;
    const minLeft = Math.floor(timeLeft / 60);
    const hourLeft = Math.floor(minLeft / 60);
    const dayLeft = Math.floor(hourLeft / 24);
    const day = dayLeft > 0 ? dayLeft + "j " : "";
    const hourDisplay = hourLeft % 24;
    const hour = hourDisplay < 10 ? "0" + hourDisplay : hourDisplay;
    const minDisplay = minLeft % 60;
    const min = minDisplay < 10 ? "0" + minDisplay : minDisplay;
    const sec = secLeft < 10 ? "0" + secLeft : secLeft;
    return day + " " + hour + " : " + min + " : " + sec;
  };

  componentDidMount() {
    this._isMounted = true;
    var intervalId = setInterval(() => {
      if (this.props.callbackTimer) {
        this.props.callbackTimer(this.startValue);
      }
      if (this.startValue >= 0) {
        if (this._isMounted) {
          this.setState({ currentCount: this.startValue }, () => {
            this.spring();
          });
        }
      } else {
        clearInterval(this.state.intervalId);
        // this.props.navigation.navigate("Quizzes");
      }
      this.startValue = this.startValue - 1;
    }, 1000);
    this.setState({ intervalId: intervalId });
  }

  spring() {
    this.springValue.setValue(3);
    Animated.spring(this.springValue, {
      toValue: 1,
      bounciness: 12
    }).start();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <View
        style={{
          borderColor: "gray",
          height: this.props.modeBig ? 300 : RF(8),
          borderRadius: 30,
          borderWidth: this.props.modeBig ? 0 : 3,
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          flexDirection: this.props.modeBig ? "column" : "row",
          paddingLeft: 30,
          paddingRight: 30,
          ...this.props.style
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            textShadowOffset: { width: 5, height: 5 },
            textShadowColor: "rgba(255, 255, 255, 0.5)",
            textShadowRadius: 20,
            marginRight: this.props.modeBig ? 0 : 10,
            fontSize: this.props.modeBig ? RF(6) : RF(5)
          }}
        >
          {this.props.modeBig ? "Temps restant pour répondre : " : "DANS"}
        </Text>
        {this.state && (
          <Text
            style={{
              color: "white",
              textAlign: "center",
              textShadowOffset: { width: 5, height: 5 },
              textShadowColor: "rgba(255, 255, 255, 0.5)",
              textShadowRadius: 20,
              fontSize: this.props.modeBig ? RF(10) : RF(5)
            }}
          >
            {this._displayDate(this.state.currentCount)}
          </Text>
        )}
      </View>
    );
  }
}

export default TeleCountDown;

import React, { Component } from "react";
import Intro from "../../components/Intro";
import withMainLayout from "../../components/MainLayout";

class HomeScreen extends Component {
  
  render() {
    
    return <Intro {...this.props} />;
  }
}

export default withMainLayout(HomeScreen);

import { StyleSheet, PixelRatio } from 'react-native';
import { getWidthPercent, getHeightPercent } from './ratio';

export default StyleSheet.create({
  modalContainer: {
    backgroundColor: 'white',
    width: "90%",//getWidthPercent(100),
    height: getHeightPercent(60),
    alignSelf: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor:"white"
  },
  contentContainer: {
    width: "90%",//getWidthPercent(100),
    backgroundColor: 'transparent'
  },
  touchFlag: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  imgStyle: {
    resizeMode: 'contain',
    width: 46,
    height: 46,
    borderRadius: 23,
    borderWidth: 1 / PixelRatio.get(),
    borderColor: '#eee'
  },
  emoji: {
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 30,
    width: 30,
    height: 30,
    borderWidth: 1 / PixelRatio.get(),
    borderColor: 'transparent',
  },
  item: {
    flexDirection: 'row',
    height: 50,
    justifyContent: 'flex-start',
    alignItems: 'center'
  },
  itemFlag: {
    justifyContent: 'center',
    alignItems: 'center',
    height: getHeightPercent(15),
    width: getWidthPercent(15),
  },
  itemName: {
    justifyContent: 'center',
    width: getWidthPercent(70),
    borderBottomWidth: 2 / PixelRatio.get(),
    borderBottomColor: '#eee',
    height: getHeightPercent(7),
  },
  name: {
    fontSize: getHeightPercent(3),
  },
  letters: {
    position: 'absolute',
    height: getHeightPercent(60),
    top: 0,
    bottom: 0,
    right: 10,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  letter: {
    height: getHeightPercent(2),
    width: getWidthPercent(3),
    justifyContent: 'center',
    alignItems: 'center',
  },
  letterText: {
    textAlign: 'center',
    fontSize: getHeightPercent(1.5),
  },
});

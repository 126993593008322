import React from "react";
import { Animated, Text, View } from "react-native";
import RF from "react-native-responsive-fontsize";
import { THEME } from "../../constants/const";

export class CountDown extends React.Component {
  constructor(props) {
    super(props);
    this.springValue = new Animated.Value(0.3);
    this.startValue = props.timeLeft || 10;
  }

  _displayDate = timeLeft => {
    const secLeft = timeLeft % 60;
    const minLeft = Math.floor(timeLeft / 60);
    const hourLeft = Math.floor(minLeft / 60);
    const dayLeft = Math.floor(hourLeft / 24);
    const day = dayLeft > 0 ? dayLeft + "j " : "";
    const hourDisplay = hourLeft % 24;
    const hour = hourDisplay < 10 ? "0" + hourDisplay : hourDisplay;
    const minDisplay = minLeft % 60;
    const min = minDisplay < 10 ? "0" + minDisplay : minDisplay;
    const sec = secLeft < 10 ? "0" + secLeft : secLeft;
    return day + " " + hour + " : " + min + " : " + sec;
  };

  componentDidMount() {
    var intervalId = setInterval(() => {
      if (this.startValue >= 0) {
        this.setState({ currentCount: this.startValue }, () => {
          this.spring();
        });
      } else {
        clearInterval(this.state.intervalId);
        // console.log("should start quiz");
        if (this.props.act === "surveys") {
          this.props.navigation.navigate("Surveys");
        } else {
          this.props.navigation.navigate("Quizzes");
        }
      }
      this.startValue = this.startValue - 1;
      if (this.props.callbackTimer) {
        this.props.callbackTimer(this.startValue);
      }
    }, 1000);
    this.setState({ intervalId: intervalId });
  }

  spring() {
    this.springValue.setValue(2);
    Animated.spring(this.springValue, {
      toValue: 1,
      bounciness: 12
    }).start();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  render() {
    return (
      <View>
        {this.state && this.state.currentCount <= 60 && (
          <View
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              justifyContent: "center"
            }}
          >
            <Animated.View
              style={{
                marginTop: 300,
                width: 227,
                height: 200,
                alignSelf: "center",
                transform: [{ scale: this.springValue }]
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(13),
                  fontStyle: "bold",
                  textAlign: "center",
                  textShadowOffset: { width: 5, height: 5 },
                  textShadowColor: "rgba(255, 255, 255, 0.5)",
                  textShadowRadius: 20
                }}
              >
                {this.state && this.state.currentCount}
              </Text>
            </Animated.View>
          </View>
        )}
        {this.state && this.state.currentCount > 60 && (
          <View>
            <Text
              style={{
                color: "white",
                fontSize: RF(2),
                fontStyle: "bold",
                textAlign: "center",
                textShadowOffset: { width: 5, height: 5 },
                textShadowColor: "rgba(255, 255, 255, 0.5)",
                textShadowRadius: 20,
                margin: 20
              }}
            >
              {THEME ==='omgame' ? "Le Quiz Puma" : "Le quiz"} commence dans
            </Text>
            <Text
              style={{
                color: "white",
                fontSize: RF(6),
                fontStyle: "bold",
                textAlign: "center",
                textShadowOffset: { width: 5, height: 5 },
                textShadowColor: "rgba(255, 255, 255, 0.5)",
                textShadowRadius: 20
              }}
            >
              {this._displayDate(this.state.currentCount)}
            </Text>
          </View>
        )}
      </View>
    );
  }
}

export default CountDown;

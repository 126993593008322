import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import { Profile } from "../../components/SignUp/profile";
import { withAuthorization } from "../../components/Session";

export class ProfileScreen extends Component {
  render() {
    return <Profile {...this.props} />;
  }
}
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(ProfileScreen));

import React, { Component } from "react";
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  CheckBox,
  Button,
  TouchableHighlight
} from "react-native";
import RF from "react-native-responsive-fontsize";
import { withFirebase } from "../Firebase";
import {
  THEME,
  MAIN_COLOR,
  BUTTON_COLOR,
  PROFILE_PHONE,
  PROFILE_COMPANY,
  TEXT_OPTION_1,
  TEXT_OPTION_2,
  TEXT_OPTION_3,
  URL_RULES
} from "../../constants/const";

export class SignUp extends Component {
  currentUser = null;
  validInput = () => {
    if (this.state === null) {
      return false;
    }

    if (!this.state.firstName || this.state.firstName === "") {
      return false;
    }

    if (!this.state.lastName || this.state.lastName.length === "") {
      return false;
    }

    if (!this.state.email || this.state.email.length === "") {
      return false;
    }

    if (!this.state.password || this.state.password.length === "") {
      return false;
    }

    if (!this.state.agreement || this.state.agreement === false) {
      return false;
    }

    var re = /\S+@\S+\.\S+/;
    return re.test(String(this.state.email).toLowerCase());
  };

  onValidateClicked = () => {
    const errMessage = "Paramètre d'entrée invalide ou manquant";
    // console.log(this.state);
    if (this.validInput()) {
      this.setState({ error: null });

      const {
        email,
        password,
        firstName,
        lastName,
        company,
        phone
      } = this.state;
      const { navigate } = this.props.navigation;
      const { firebase } = this.props;
      const that = this;
      firebase
        .doCreateUserWithEmailAndPassword(email, password)
        .then(function(result) {
          // do animation
          // that.setState({ sentLink: true });
          // Update user table
          console.log(result);
          firebase
            .user(result.user.uid)
            .set({
              email,
              firstName,
              lastName,
              company: company || false,
              phone: phone || false,
              newsletter: that.state.newsletter || false,
              newsletter_commercial: that.state.newsletter_commercial || false,
              agreement: that.state.agreement || false,
              theme: THEME,
              date: result.user.metadata.creationTime,
              uid: result.user.uid
            })
            .then(() => {
              that.setState({ error: null });
              firebase.auth.currentUser
                .updateProfile({
                  displayName: `${firstName} ${lastName
                    .charAt(0)
                    .toUpperCase()}.`
                })
                .then(function() {
                  //save prono if need
                  that.currentUser = {
                    uid: result.user.uid,
                    email: email,
                    displayName: `${firstName} ${lastName
                      .charAt(0)
                      .toUpperCase()}.`
                  };
                  that.savePronoIfNeed();
                  navigate("Home");
                })
                .catch(function(error) {
                  that.setState({ error });
                  console.log(error);
                });
            })
            .catch(error => {
              that.setState({ error });
              console.log(error);
            });
        })
        .catch(function(err) {
          // Some error occurred, you can inspect the code: error.code
          console.log(err);
          that.setState({ error: { message: err.code } });
        });

      // Go to notice screen for checking email
    } else {
      this.setState({ error: { message: errMessage } });
    }
  };

  savePronoIfNeed = () => {
    const toSave = window.localStorage.getItem("match_forecast_to_save");
    if (toSave) {
      const dataToSave = JSON.parse(toSave);
      // Save to firestore
      this.props.firebase
        .pronostics_responses(
          `${this.currentUser.uid}_${dataToSave.pronosticId}`
        )
        .set({
          userId: this.currentUser.uid,
          displayName: this.currentUser.displayName,
          email: this.currentUser.email,
          ...dataToSave
        });
      window.localStorage.removeItem("match_forecast_to_save");
      window.localStorage.setItem("match_forecast", JSON.stringify(dataToSave));
    }
  };

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          maxWidth: 500
        }}
      >
        <View
          style={{
            padding: 20,
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: MAIN_COLOR,
            borderColor: "white",
            borderWidth: 1,
            borderRadius: 6
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: RF(2),
              fontWeight: "normal",
              margin: 10,
              textAlign: "center",
              textTransform: "uppercase"
            }}
          >
            Inscription
          </Text>

          {this.state && this.state.error && (
            <Text
              style={{
                color: "red",
                fontSize: RF(1.5),
                margin: 10,
                textAlign: "center"
              }}
            >
              * {this.state.error.message}
            </Text>
          )}

          <TextInput
            autoFocus={true}
            style={styles.inputBox}
            placeholder={"Prénom *"}
            placeholderTextColor={"lightgray"}
            onChangeText={text => {
              this.setState({ firstName: text });
            }}
            value={(this.state && this.state.firstName) || ""}
          />
          <TextInput
            style={styles.inputBox}
            placeholder={"Nom *"}
            placeholderTextColor={"lightgray"}
            onChangeText={text => {
              this.setState({ lastName: text });
            }}
            value={(this.state && this.state.lastName) || ""}
          />

          {PROFILE_COMPANY === true && (
            <TextInput
              style={styles.inputBox}
              placeholder={"Société "}
              placeholderTextColor={"lightgray"}
              onChangeText={text => {
                this.setState({ company: text });
              }}
              value={(this.state && this.state.company) || ""}
            />
          )}
          {PROFILE_PHONE === true && (
            <TextInput
              style={styles.inputBox}
              placeholder={"Numéro de téléphone "}
              placeholderTextColor={"lightgray"}
              keyboardType="phone-pad"
              onChangeText={text => {
                this.setState({ phone: text });
              }}
              value={(this.state && this.state.phone) || ""}
            />
          )}

          <View
            style={{
              height: 1,
              width: "100%",
              margin: 10,
              alignSelf: "center",
              backgroundColor: "lightgray"
            }}
          />

          <TextInput
            style={styles.inputBox}
            textContentType="emailAddress"
            placeholder={"Email *"}
            placeholderTextColor={"lightgray"}
            keyboardType="email-address"
            onChangeText={text => {
              this.setState({ email: text });
            }}
            value={(this.state && this.state.email) || ""}
          />

          <TextInput
            style={styles.inputBox}
            secureTextEntry={true}
            placeholder={"Mot de passe *"}
            placeholderTextColor={"lightgray"}
            onChangeText={text => {
              this.setState({ password: text });
            }}
            value={(this.state && this.state.password) || ""}
          />
          {TEXT_OPTION_1 && (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: 20,
              marginTop: 20
            }}
          >
            <CheckBox
              value={(this.state && this.state.agreement) || false}
              onValueChange={v => {
                this.setState({ agreement: v });
              }}
            />
            <Text
              style={{
                flex: 1,
                textAlignVertical: "center",
                color: "white",
                fontSize: RF(1.5),
                fontWeight: "normal",
                marginLeft: 10
              }}
            >
              J'ai lu et accepte les modalités figurant dans {" "}
              <a
                rel="noopener noreferrer"
                href={URL_RULES}
                target="_blank"
              >
                le règlement du présent concours
              </a> {" "}
              *
            </Text>
          </View>
          )}
          {TEXT_OPTION_2 && (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginBottom: 20
            }}
          >
            <CheckBox
              value={(this.state && this.state.newsletter) || false}
              onValueChange={v => {
                this.setState({ newsletter: v });
              }}
            />
            <Text
              style={{
                flex: 1,
                textAlignVertical: "center",
                color: "white",
                fontSize: RF(1.5),
                fontWeight: "normal",
                marginLeft: 10
              }}
            >
              {TEXT_OPTION_2}
            </Text>
          </View>
          )}

          {TEXT_OPTION_3 && (
            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                marginBottom: 20
              }}
            >
              <CheckBox
                value={
                  (this.state && this.state.newsletter_commercial) || false
                }
                onValueChange={v => {
                  this.setState({ newsletter_commercial: v });
                }}
              />
              <Text
                style={{
                  flex: 1,
                  textAlignVertical: "center",
                  color: "white",
                  fontSize: RF(1.5),
                  fontWeight: "normal",
                  marginLeft: 10
                }}
              >
                {TEXT_OPTION_3}
              </Text>
            </View>
          )}
          <Button
            title="Valider"
            color={BUTTON_COLOR}
            onPress={() => {
              this.onValidateClicked();
            }}
          />
          <TouchableHighlight
            style={{ alignSelf: "flex-end" }}
            onPress={() => navigate("SignIn")}
          >
            <Text
              style={{
                color: BUTTON_COLOR,
                fontSize: RF(1.5),
                textAlign: "center",
                padding: 8
              }}
            >
              <i>Connexion</i>
            </Text>
          </TouchableHighlight>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputBox: {
    height: 30,
    width: "100%",
    borderWidth: 1,
    borderRadius: 3,
    borderColor: "white",
    backgroundColor: "white",
    padding: 5,
    margin: 3
  }
});

export default withFirebase(SignUp);

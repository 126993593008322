import React, { Component } from "react";
import { Text, View, FlatList } from "react-native";
import {
  getHeightPercent,
  getWidthPercent
} from "../../libs/list_picker/ratio";

export class MatchWinnerRandom extends Component {
  _keyExtractor = (item, index) => index;

  _renderItem = ({ item, index }) => {
    return (
      <View
        style={{
          width: Math.round(getWidthPercent(50) / this._numColumns()),
          height: Math.round(getHeightPercent(30) / this._numRows())
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 2,
            marginBottom: 2,
            flex:1
          }}
        >
          <View
            style={{
              backgroundColor: this._getColor(item.color),
              opacity: 0.3,
              position: "absolute",
              height: "100%",
              width: "100%"
            }}
          />
          <Text
            style={{
              fontSize: Math.round(getWidthPercent(40) / this._numColumns() / 6),
              color: this._getColor(item.color),
              textAlign:'center'
            }}
          >
            {item.displayName}
          </Text>
        </View>
      </View>
    );
  };

  _getColor = (statusColor) => {
      switch (statusColor) {
            case 'green':
              return '#6DD400';
            case 'yellow':
                return '#F7B500';
            case 'red':
                return '#E02020';
          default:
                return 'white';
      }
  }

  _numColumns = () => {
    return this.props.data.length < 16 ? Math.ceil(this.props.data.length / 3) : 8;
  };

  _numRows = () => {
    return Math.ceil(this.props.data.length / this._numColumns());
  };

  render() {
    return (
      <FlatList
        style={{width:   getWidthPercent(50),
             ...this.props.style }}
        showsHorizontalScrollIndicator={false}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns()}
        keyExtractor={this._keyExtractor()}
      />
    );
  }
}

export default MatchWinnerRandom;

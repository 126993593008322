import React, { Component } from "react";
import {
  Text,
  View,
  FlatList,
  TouchableHighlight,
  Image,
  Dimensions
} from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeSlideView from "../Anim/FadeSlideView";
import PercentResult from "./percentResult";
import LottieView from "react-native-web-lottie";

export class ResponseTextItem extends Component {
  _keyExtractor = (item, index) => item.id;

  _renderOptionText = index => {
    var optionText = "";
    switch (index) {
      case 2:
        optionText = "B";
        break;
      case 3:
        optionText = "C";
        break;
      case 4:
        optionText = "D";
        break;

      default:
        optionText = "A";
        break;
    }
    return optionText;
  };

  _renderItem = ({ item, index }) => {
    if (this.props.question.withImage) {
      return this._renderItemImage({ item, index });
    } else {
      return this._renderItemText({ item, index });
    }
  };

  _renderItemImage = ({ item, index }) => {
    const screenHeight = Math.round(Dimensions.get("window").height);

    return (
      <FadeSlideView
        delay={index * 50}
        style={{
          flex: 1,
          flexDirection: this.props.modeTele ? "row" : "column",
          margin: 1
        }}
      >
        <TouchableHighlight
          onPress={() => {
            this.props.onPressButton(index, item.id);
            this.setState({ selected: true });
          }}
          disabled={
            this.props.answered || this.props.withResult || this.props.modeTele
          }
        >
          <View > 
            <Image
              style={{
                width: this.props.modeTele ? Math.round(screenHeight / 5) : 150,
                height: this.props.modeTele
                  ? this.props.withResult
                    ? Math.round(screenHeight / 5)
                    : Math.round(screenHeight / 4)
                  : 150,
                borderRadius: 5,
                margin: 5,
                marginBottom: item.text ? 5 : this._marginBottom(this.props.withResult, false),
                borderColor: "white",
                borderWidth: 1,
                opacity: item.answered ? 0.5 : 1
              }}
              source={{ uri: item.answer }}
            />
            {item.text && (
              <Text
                style={{
                  fontSize: this.props.modeTele
                    ? this.props.withResult
                      ? RF(2)
                      : RF(2)
                    : RF(2),
                    color: "white",
                    textAlign:"center",
                    marginBottom: this._marginBottom(this.props.withResult,  true)
                }}
              >
                {item.text}
              </Text>
            )}
            {item.answered && (
              <View style={{ position: "absolute", top: 30, right: 25 }}>
                <LottieView
                  style={{ height: 100, width: 100 }}
                  source={require("../../assets/anim/tick.json")}
                  autoPlay
                />
              </View>
            )}
            {this.props.withResult && (
              <PercentResult
                type={item.isRight ? "right" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
                
              />
            )}
          </View>
        </TouchableHighlight>
      </FadeSlideView>
    );
  };

  _marginBottom = (withResult, withText) => {
    const screenHeight = Math.round(Dimensions.get("window").height);
    
    return 5 + (withResult
      ? this.props.modeTele
        ? Math.round(screenHeight / 23) + ( withText ? RF(2) : 0)
        : 30 + ( withText ? RF(2) : 0)
      : 5 + ( withText ? RF(2) : 0));
  };

  _renderItemText = ({ item, index }) => {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <FadeSlideView delay={index * 50}>
        <TouchableHighlight
          onPress={() => {
            this.props.onPressButton(index, item.id);
            this.setState({ selected: true });
          }}
          disabled={
            this.props.answered || this.props.withResult || this.props.modeTele
          }
        >
          <View style={{ flex: 1, alignItems: "stretch" }}>
            <View
              style={{
                flex: 1,
                height: this.props.modeTele ? Math.round(screenHeight / 8) : 60,
                width: this.props.modeTele
                  ? this.props.withResult
                    ? Math.round(screenWidth / 6)
                    : Math.round(screenWidth / 4)
                  : 310,
                borderRadius: 5,
                margin: 5,
                marginBottom: this._marginBottom(this.props.withResult, item.text ? true : false),
                backgroundColor: "white",
                borderWidth: 1,
                justifyContent: "flex-start",
                alignItems: "center",
                alignSelf: "stretch",
                flexDirection: "row"
              }}
            >
              <View
                style={{
                  width: this.props.modeTele
                    ? this.props.withResult
                      ? Math.round(screenHeight / 16)
                      : Math.round(screenHeight / 10)
                    : 30,
                  height: this.props.modeTele
                    ? this.props.withResult
                      ? Math.round(screenHeight / 16)
                      : Math.round(screenHeight / 10)
                    : 30,
                  borderRadius: this.props.modeTele
                    ? this.props.withResult
                      ? Math.round(screenHeight / 32)
                      : Math.round(screenHeight / 20)
                    : 15,
                  backgroundColor: item.answered ? "white" : "lightgrey",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: this.props.modeTele
                    ? Math.round(screenHeight / 40)
                    : 9
                }}
              >
                <Text
                  style={{
                    fontSize: this.props.modeTele
                      ? this.props.withResult
                        ? RF(2)
                        : RF(4)
                      : RF(2)
                  }}
                >
                  {this._renderOptionText(item.id)}
                </Text>
              </View>

              <Text
                style={{
                  fontSize: this.props.modeTele
                    ? this.props.withResult
                      ? RF(2)
                      : RF(4)
                    : RF(2)
                }}
              >
                {item.answer}
              </Text>
            </View>
            {item.answered && (
              <View
                style={{
                  flex: 1,
                  alignItems: "flex-end",
                  position: "absolute",
                  bottom: this._marginBottom(this.props.withResult, item.text ? true : false) - 20,
                  left: -20,
                  alignContent: "flex-end"
                }}
              >
                <LottieView
                  style={{ height: 100, width: 100, alignSelf: "flex-end" }}
                  source={require("../../assets/anim/tick.json")}
                  autoPlay
                />
              </View>
            )}
            {this.props.withResult && (
              <PercentResult
                type={item.isRight ? "right" : "wrong"}
                value={Number.parseFloat(item.percent * 100).toFixed(0)}
                modeTele={this.props.modeTele}
              />
            )}
          </View>
        </TouchableHighlight>
      </FadeSlideView>
    );
  };

  _numColumns = withImage => {
    if (this.props.modeTele) {
      return withImage
        ? this.props.withResult
          ? 2
          : 4
        : this.props.withResult
        ? 2
        : 2;
    }
    return withImage ? 2 : 1;
  };

  render() {
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <FlatList
        style={{
          paddingTop: 5,
          paddingBottom: 5,
          maxWidth: this.props.modeTele
            ? this.props.withResult
              ? Math.round(screenWidth / 2)
              : Math.round(screenWidth / 1.5)
            : "100%"
        }}
        showsHorizontalScrollIndicator={false}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns(this.props.question.withImage)}
      />
    );
  }
}

export default ResponseTextItem;

import React from "react";
import { Animated, Easing } from "react-native";
import PropTypes from "prop-types";

export class FadeSlideView extends React.Component {
  static propTypes = {
    distance: PropTypes.number.isRequired,
    direction: PropTypes.string.isRequired, // up, down, left, right
    withFade: PropTypes.bool.isRequired,
    delay: PropTypes.number
  };

  static defaultProps = {
    distance: 60,
    direction: "up",
    withFade: true,
    delay: 0
  };

  constructor() {
    super();
    this.animatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.animatedValue.setValue(0);
    setTimeout(() => {
      Animated.timing(
        // Animate over time
        this.animatedValue, // The animated value to drive
        {
          toValue: this.valueEnd || 1, // Animate to opacity: 1 (opaque)
          easing: Easing.linear,
          duration: this.props.duration || 600 // Make it take a while
        }
      ).start(); // Starts the animation
    }, this.props.delay || 0);
  }

  render() {
    const marginTop = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [this.props.distance, 0]
    });
    const marginBottom = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [this.props.distance, 0]
    });
    const marginLeft = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [this.props.distance, 0]
    });
    const marginRight = this.animatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [this.props.distance, 0]
    });

    var styles = {
      ...this.props.style,
      opacity: this.animatedValue
    };

    switch (this.props.direction) {
      case "down":
        styles.marginBottom = marginBottom;
        break;
      case "left":
        styles.marginLeft = marginLeft;
        break;
      case "right":
        styles.marginRight = marginRight;
        break;
      default:
        styles.marginTop = marginTop;
        break;
    }

    return (
      <Animated.View // Special animatable View
        style={styles}
      >
        {this.props.children}
      </Animated.View>
    );
  }
}

export default FadeSlideView;

import React, { Component } from "react";
import { Button, Image, Picker, StyleSheet, Text, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";
import RF from "react-native-responsive-fontsize";
import { BUTTON_COLOR, MAIN_COLOR } from "../../constants/const";
import ListPicker from "../../libs/list_picker/ListPicker";
import { getHeightPercent, getWidthPercentMaxLimited } from "../../libs/list_picker/ratio";

export class Forecast extends Component {
  state = {
    screen: "score",
    scoreHome: 0,
    scoreVisitor: 0,
    minute: 1,
    dataSelected: 1
  };
  dataList = null;

  componentDidUpdate() {
    if(this.props.currentMatch){
      const match_forecast = window.localStorage.getItem("match_forecast");
      if (match_forecast !== null) {
        this.currentForecast = JSON.parse(match_forecast);
        if (this.currentForecast.matchId === this.props.currentMatch.match_id) {
          // Same match and already forecasted
          if(this.props.mode!=='preforecast'){
            this.props.navigation.navigate('Home');
          }
        }else{
          // not the same match, delete the old one
          window.localStorage.removeItem("match_forecast");
        }
      }
      if(Math.floor((Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000) >= this.props.currentMatch.start.seconds){
        //Time out for forecast, should go back
        this.props.navigation.navigate('Home');
      }
    }
  }

  onScoreHomeChangesValue = (itemValue, itemIndex) => {
    console.log("onChange Home", itemValue);
    this.setState({
      scoreHome: itemValue
    });
  };

  onScoreVisitorChangesValue = (itemValue, itemIndex) => {
    console.log("onChange Visitor", itemValue);
    this.setState({
      scoreVisitor: itemValue
    });
  };

  onMinuteChangesValue = (itemValue, itemIndex) => {
    console.log("onMinuteChangesValue", itemValue);
    this.setState({
      minute: itemValue
    });
  };

  onValidateClicked = ev => {
    if (this.state.screen === "score") {
      this.dataList = this.props.currentMatch.team_home_players.map(el => {
        return {
          name: el.name,
          icon: el.image,
          letter: el.name,
          player_id: el.player_id
        };
      });
      this.setState({ screen: "firstScorer" });
    } else if (this.state.screen === "firstScorer") {
      this.setState({ screen: "minute" });
    } else if (this.state.screen === 'minute' && this.props.mode === 'preforecast'){
      // save and go to home
      const dataToSave = {
        scoreHome         : parseInt(this.state.scoreHome, 10),
        scoreVisitor      : parseInt(this.state.scoreVisitor, 10),
        firstScorer       : this.state.pickData,
        firstScoreMinute  : this.state.minute,
        matchId           : this.props.currentMatch.match_id,
        pronosticId       : this.props.currentMatch.id,
      }
      window.localStorage.setItem("match_forecast_to_save", JSON.stringify(dataToSave));
      this.props.navigation.navigate('SignUp');
    }else{
      console.log(this.state);
      // save and go to home
      const dataToSave = {
        scoreHome         : this.state.scoreHome,
        scoreVisitor      : this.state.scoreVisitor,
        firstScorer       : this.state.pickData,
        firstScoreMinute  : this.state.minute,
        matchId           : this.props.currentMatch.match_id,
        pronosticId       : this.props.currentMatch.id,
      }
      // Save to firestore
      this.props.firebase.pronostics_responses(`${this.props.authUser.uid}_${this.props.currentMatch.id}`).set({
        userId: this.props.authUser.uid,
        displayName: this.props.authUser.displayName,
        email: this.props.authUser.email,
        ...dataToSave
      });
      window.localStorage.setItem("match_forecast", JSON.stringify(dataToSave));
      this.props.navigation.navigate('Home');
    }
    
  };

  _renderMinuteItems = max => {
    const arrItems = [];
    for (let i = 1; i < max; i++) {
      arrItems.push(this._renderMinuteItem(i));
    }
    return arrItems;
  };

  _renderMinuteItem = index => {
    return (
      <Picker.Item
        label={index < 10 ? `0${index}` : `${index}`}
        value={index}
      />
    );
  };

  render() {
    
    return (
      <View
        style={{
          top: 0,
          marginTop: 0,
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          maxWidth: maxW
        }}
      >
        {this.props.currentMatch && this.state.screen === "score" && (
          <View
            style={{
              padding: getWidthPercentMaxLimited(2, maxW),
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderColor: "white",
              borderWidth: 1,
              borderRadius: 6
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(3),
                fontWeight: "bold",
                margin: getWidthPercentMaxLimited(2, maxW),
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              SCORE À LA FIN DU TEMPS RÈGLEMENTAIRE
            </Text>

            <View
              style={{
                height: 1,
                width: "100%",
                margin: getWidthPercentMaxLimited(1, maxW),
                alignSelf: "center",
                backgroundColor: "lightgray"
              }}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Image
                style={{
                  width: getWidthPercentMaxLimited(30, maxW),
                  height: getWidthPercentMaxLimited(30, maxW),
                  marginTop: getWidthPercentMaxLimited(2, maxW),
                  marginBottom: getWidthPercentMaxLimited(2, maxW),
                  marginRight: getWidthPercentMaxLimited(7, maxW)
                }}
                resizeMode={"contain"}
                source={this.props.currentMatch.team_home_logo}
              />
              <Image
                style={{
                  width: getWidthPercentMaxLimited(30, maxW),
                  height: getWidthPercentMaxLimited(30, maxW),
                  marginTop: getWidthPercentMaxLimited(2, maxW),
                  marginBottom: getWidthPercentMaxLimited(2, maxW),
                  marginLeft: getWidthPercentMaxLimited(7, maxW)
                }}
                resizeMode={"contain"}
                source={this.props.currentMatch.team_visitor_logo}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Picker
                selectedValue={this.state.scoreHome}
                onValueChange={this.onScoreHomeChangesValue}
                mode="dropdown"
                style={styles.pickerStyleLeft}
              >
                <Picker.Item label="0" value="0" />
                <Picker.Item label="1" value="1" />
                <Picker.Item label="2" value="2" />
                <Picker.Item label="3" value="3" />
                <Picker.Item label="4" value="4" />
                <Picker.Item label="5" value="5" />
                <Picker.Item label="6" value="6" />
                <Picker.Item label="7" value="7" />
                <Picker.Item label="8" value="8" />
                <Picker.Item label="9" value="9" />
              </Picker>

              <Picker
                selectedValue={this.state.scoreVisitor}
                onValueChange={this.onScoreVisitorChangesValue}
                mode="dropdown"
                style={styles.pickerStyleRight}
              >
                <Picker.Item label="0" value="0" />
                <Picker.Item label="1" value="1" />
                <Picker.Item label="2" value="2" />
                <Picker.Item label="3" value="3" />
                <Picker.Item label="4" value="4" />
                <Picker.Item label="5" value="5" />
                <Picker.Item label="6" value="6" />
                <Picker.Item label="7" value="7" />
                <Picker.Item label="8" value="8" />
                <Picker.Item label="9" value="9" />
              </Picker>
            </View>
            <View style={{ marginTop: getHeightPercent(5) }}>
              <Button
                title="PRONOSTIQUER"
                color={BUTTON_COLOR}
                onPress={() => {
                  this.onValidateClicked();
                }}
              />
            </View>
          </View>
        )}

        {this.props.currentMatch && this.state.screen === "firstScorer" && (
          <View
            style={{
              padding: getHeightPercent(2),
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderColor: "white",
              borderWidth: 1,
              borderRadius: 6
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(3),
                fontWeight: "bold",
                margin: getHeightPercent(2),
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              Quel joueur sera le 1er buteur du match ?
            </Text>

            <View
              style={{
                height: 1,
                width: "100%",
                margin: getHeightPercent(1),
                alignSelf: "center",
                backgroundColor: "lightgray"
              }}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <TouchableWithoutFeedback
                onPress={() => {
                  this.dataList = this.props.currentMatch.team_home_players.map(
                    el => {
                      return {
                        name: el.name,
                        icon: el.image,
                        letter: el.name,
                        player_id: el.player_id
                      };
                    }
                  );
                  this.setState({ pickData: null, dataSelected: 1 });
                }}
              >
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(30, maxW),
                    height: getWidthPercentMaxLimited(30, maxW),
                    margin: getHeightPercent(2),
                    marginRight: getWidthPercentMaxLimited(7, maxW),
                    opacity: this.state.dataSelected === 1 ? 1 : 0.5
                  }}
                  resizeMode={"contain"}
                  source={this.props.currentMatch.team_home_logo}
                />
              </TouchableWithoutFeedback>
              <TouchableWithoutFeedback
                onPress={() => {
                  this.dataList = this.props.currentMatch.team_visitor_players.map(
                    el => {
                      return {
                        name: el.name,
                        icon: el.image,
                        letter: el.name,
                        player_id: el.player_id
                      };
                    }
                  );
                  this.setState({ pickData: null, dataSelected: 2 });
                }}
              >
                <Image
                  style={{
                    width: getWidthPercentMaxLimited(30, maxW),
                    height: getWidthPercentMaxLimited(30, maxW),
                    margin: getHeightPercent(2),
                    marginLeft: getWidthPercentMaxLimited(7, maxW),
                    opacity: this.state.dataSelected === 2 ? 1 : 0.5
                  }}
                  resizeMode={"contain"}
                  source={this.props.currentMatch.team_visitor_logo}
                />
              </TouchableWithoutFeedback>
            </View>
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <ListPicker
                ref={picker => {
                  this.picker = picker;
                }}
                onChange={value => {
                  this.setState({ pickData: value });
                }}
                closeable={true}
                isEmojiable={false}
                key={this.state.dataSelected}
                dataList={this.dataList}
              />
              <View
                style={{
                  width: "100%",
                  padding: getHeightPercent(2),
                  marginTop: getHeightPercent(2),
                  borderRadius: 10,
                  borderColor: "#ccc",
                  borderWidth: 2
                }}
              >
                <TouchableOpacity onPress={() => this.picker.openModal()}>
                  {!this.state.pickData && (
                    <Text style={{ color: "white" }}>Choisir un joueur</Text>
                  )}

                  {this.state.pickData && (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Image
                        style={{
                          width: getWidthPercentMaxLimited(30, maxW),
                          height: getWidthPercentMaxLimited(30, maxW),
                          borderRadius: getWidthPercentMaxLimited(15, maxW)
                        }}
                        source={this.state.pickData.icon}
                        resizeMode={"contain"}
                      />
                      <Text
                        style={{
                          color: "white",
                          fontSize: RF(5),
                          fontWeight: "bold",
                          margin: 10
                        }}
                      >
                        {this.state.pickData.name}
                      </Text>
                    </View>
                  )}
                </TouchableOpacity>
              </View>
            </View>
            {this.state.pickData && (
              <View style={{ marginTop: getHeightPercent(5) }}>
                <Button
                  title="PRONOSTIQUER"
                  color={BUTTON_COLOR}
                  onPress={() => {
                    this.onValidateClicked();
                  }}
                />
              </View>
            )}
          </View>
        )}

        {this.props.currentMatch && this.state.screen === "minute" && (
          <View
            style={{
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderColor: "white",
              borderWidth: 1,
              borderRadius: 6
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(3),
                fontWeight: "bold",
                margin: 10,
                textAlign: "center",
                textTransform: "uppercase"
              }}
            >
              À quelle minute sera marqué le 1er but ?
            </Text>

            <View
              style={{
                height: 1,
                width: "100%",
                margin: 10,
                alignSelf: "center",
                backgroundColor: "lightgray"
              }}
            />

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(3),
                  fontWeight: "bold",
                  margin: 10,
                  textAlign: "center"
                }}
              >
                Faites tourner les rouleaux pour choisir la minute
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Picker
                selectedValue={this.state.minute}
                onValueChange={this.onMinuteChangesValue}
                mode="dropdown"
                style={(this.state.minute === 'ex1'|| this.state.minute === 'ex2') ?  styles.pickerStyleNoMargin : styles.pickerStyleNoMarginShort}
                itemStyle={styles.itemStyle}
              >
                <Picker.Item label="01" value="1" />
                <Picker.Item label="02" value="2" />
                <Picker.Item label="03" value="3" />
                <Picker.Item label="04" value="4" />
                <Picker.Item label="05" value="5" />
                <Picker.Item label="06" value="6" />
                <Picker.Item label="07" value="7" />
                <Picker.Item label="08" value="8" />
                <Picker.Item label="09" value="9" />
                <Picker.Item label="10" value="10" />
                <Picker.Item label="11" value="11" />
                <Picker.Item label="12" value="12" />
                <Picker.Item label="13" value="13" />
                <Picker.Item label="14" value="14" />
                <Picker.Item label="15" value="15" />
                <Picker.Item label="16" value="16" />
                <Picker.Item label="17" value="17" />
                <Picker.Item label="18" value="18" />
                <Picker.Item label="19" value="19" />
                <Picker.Item label="20" value="20" />
                <Picker.Item label="21" value="21" />
                <Picker.Item label="22" value="22" />
                <Picker.Item label="23" value="23" />
                <Picker.Item label="24" value="24" />
                <Picker.Item label="25" value="25" />
                <Picker.Item label="26" value="26" />
                <Picker.Item label="27" value="27" />
                <Picker.Item label="28" value="28" />
                <Picker.Item label="29" value="29" />
                <Picker.Item label="30" value="30" />
                <Picker.Item label="31" value="31" />
                <Picker.Item label="32" value="32" />
                <Picker.Item label="33" value="33" />
                <Picker.Item label="34" value="34" />
                <Picker.Item label="35" value="35" />
                <Picker.Item label="36" value="36" />
                <Picker.Item label="37" value="37" />
                <Picker.Item label="38" value="38" />
                <Picker.Item label="39" value="39" />
                <Picker.Item label="40" value="40" />
                <Picker.Item label="41" value="41" />
                <Picker.Item label="42" value="42" />
                <Picker.Item label="43" value="43" />
                <Picker.Item label="44" value="44" />
                <Picker.Item label="45" value="45" />
                <Picker.Item label="Tps add. 1er MT" value="ex1" />
                <Picker.Item label="46" value="46" />
                <Picker.Item label="47" value="47" />
                <Picker.Item label="48" value="48" />
                <Picker.Item label="49" value="49" />
                <Picker.Item label="50" value="50" />
                <Picker.Item label="51" value="51" />
                <Picker.Item label="52" value="52" />
                <Picker.Item label="53" value="53" />
                <Picker.Item label="54" value="54" />
                <Picker.Item label="55" value="55" />
                <Picker.Item label="56" value="56" />
                <Picker.Item label="57" value="57" />
                <Picker.Item label="58" value="58" />
                <Picker.Item label="59" value="59" />
                <Picker.Item label="60" value="60" />
                <Picker.Item label="61" value="61" />
                <Picker.Item label="62" value="62" />
                <Picker.Item label="63" value="63" />
                <Picker.Item label="64" value="64" />
                <Picker.Item label="65" value="65" />
                <Picker.Item label="66" value="66" />
                <Picker.Item label="67" value="67" />
                <Picker.Item label="68" value="68" />
                <Picker.Item label="69" value="69" />
                <Picker.Item label="70" value="70" />
                <Picker.Item label="71" value="71" />
                <Picker.Item label="72" value="72" />
                <Picker.Item label="73" value="73" />
                <Picker.Item label="74" value="74" />
                <Picker.Item label="75" value="75" />
                <Picker.Item label="76" value="76" />
                <Picker.Item label="77" value="77" />
                <Picker.Item label="78" value="78" />
                <Picker.Item label="79" value="79" />
                <Picker.Item label="80" value="80" />
                <Picker.Item label="81" value="81" />
                <Picker.Item label="82" value="82" />
                <Picker.Item label="83" value="83" />
                <Picker.Item label="84" value="84" />
                <Picker.Item label="85" value="85" />
                <Picker.Item label="86" value="86" />
                <Picker.Item label="87" value="87" />
                <Picker.Item label="88" value="88" />
                <Picker.Item label="89" value="89" />
                <Picker.Item label="90" value="90" />
                <Picker.Item label="Tps add. 2e MT" value="ex2" />
              </Picker>

            </View>
            <View style={{ marginTop: 30 }}>
              <Button
                title="PRONOSTIQUER"
                color={BUTTON_COLOR}
                onPress={() => {
                  this.onValidateClicked();
                }}
              />
            </View>
          </View>
        )}
      </View>
    );
  }
}

const maxW = 500;

const styles = StyleSheet.create({
  pickerStyleNoMargin: {
    borderRadius: 10,
    width: getWidthPercentMaxLimited(95, maxW),
    height: getWidthPercentMaxLimited(30, maxW),
    marginTop: getHeightPercent(2),
    marginBottom: getHeightPercent(2),
    backgroundColor: "white",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  pickerStyleNoMarginShort: {
    borderRadius: 10,
    width: getWidthPercentMaxLimited(40, maxW),
    height: getWidthPercentMaxLimited(30, maxW),
    marginTop: getHeightPercent(2),
    marginBottom: getHeightPercent(2),
    backgroundColor: "white",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center"
  },
  itemStyle:{
    textAlign:"center",
  },
  pickerStyleLeft: {
    borderRadius: 10,
    minWidth: getWidthPercentMaxLimited(30, maxW),
    height: getWidthPercentMaxLimited(30, maxW),
    marginTop: getHeightPercent(2),
    marginBottom: getHeightPercent(2),
    backgroundColor: "white",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",

    marginRight: getWidthPercentMaxLimited(7, maxW)
  },
  pickerStyleRight: {
    borderRadius: 10,
    minWidth: getWidthPercentMaxLimited(30, maxW),
    height: getWidthPercentMaxLimited(30, maxW),
    marginTop: getHeightPercent(2),
    marginBottom: getHeightPercent(2),
    backgroundColor: "white",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",

    marginLeft: getWidthPercentMaxLimited(7, maxW)
  }
});

export default Forecast;

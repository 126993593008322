import React, { Component } from "react";
import { ImageBackground, View, Text, Dimensions } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../Reponses";
import FadeSlideView from "../Anim/FadeSlideView";
import { TIME_PAUSE, THEME } from "../../constants/const";
import TeleHeader from "./Elements/header";
import Ranking from "../Ranking";
import ClassementPhone from "./Elements/classementonyourphone";

export class TeleQuizzes extends Component {
  _isMounted = false;
  state = { displayResult: false };
  currentQuiz = null;
  currentQuestion = null;
  currentResults = null;

  constructor(props) {
    super(props);
    if (props.currentQuiz) {
      this.currentQuiz = props.currentQuiz;
    }
    if (props.currentQuestion) {
      this.currentQuestion = props.currentQuestion;
    } else {
      if (this.props.checkQuizAndQuestion) {
        this.props.checkQuizAndQuestion();
      }
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if (this.state.displayResult === true) {
      // Next question
      if (this.props.checkQuizAndQuestion) {
        this.props.checkQuizAndQuestion();
      }
      if (this._isMounted) {
        this.setState({ displayResult: false });
      }
    } else {
      const currentResults = window.localStorage.getItem("currentResults");
      if (currentResults != null) {
        this.currentResults = JSON.parse(currentResults);

        var answersByTimes = this.currentResults.filter(q => {
          return q.id === this.currentQuestion.id;
        })[0].answersByTimes;

        answersByTimes = answersByTimes.map(el => {
          el.rightTimeMs = el.isRight === 1 ? el.timeMs : 0;
          return el;
        });
        // console.log("before sort => ", answersByTimes);

        if(answersByTimes.length > 0){
          // Only take first 10
          answersByTimes = answersByTimes.sort((a, b) => {
            return b.isRight - a.isRight || a.rightTimeMs - b.rightTimeMs || a.timeMs - b.timeMs || a.time - b.time;
          });
          answersByTimes = answersByTimes.slice(0,10);
        }

        // console.log("after sort => ", answersByTimes);

        if (this._isMounted) {
          this.setState({ answersByTimes });
        }
        //re-calculate the percent
        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          const res = this.currentQuestion.answers[i];
          var result = null;
          result = this.currentResults
            .filter(q => {
              return q.id === this.currentQuestion.id;
            })[0]
            .answers.filter(r => {
              return r.id === res.id;
            })[0];
          this.currentQuestion.answers[i].withResult = true;
          this.currentQuestion.answers[i].percent = result.percent || 0;
          this.currentQuestion.answers[i].totalAnswers = result.totalAnswers;
          this.currentQuestion.answers[i].isRight =
            res.id === this.currentQuestion.rightAnswer;
        }
      }
      if (this._isMounted) {
        this.setState({ displayResult: true });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    // console.log(this.props);
    return (
      <ImageBackground
        style={{ flex: 1, alignItems: "center" }}
        source={require("../../assets/themes/" + THEME + "/bg.png")}
        resizeMode={"cover"}
      >
        {this.props.currentQuiz !== null && (
          <View
            style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
          >
            <TeleHeader
              style={{
                flex: 1,
                flexDirection: "row",
                width: screenWidth,
                maxHeight: "20%",
                padding: 10,
                marginTop: 10,
                position: "absolute"
              }}
              texts={this.props.currentQuiz}
            />
            {this.props.currentQuestion && (
              <View
                style={{
                  flex: 1,
                  alignSelf: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  maxWidth: this.state.displayResult
                    ? Math.round(screenWidth / 2)
                    : Math.round(screenWidth / 1.1)
                }}
              >
                <FadeSlideView direction="down">
                  <ImageBackground
                    key={this.state.displayResult}
                    style={{
                      width: Math.round(screenHeight / 9),
                      height: Math.round(screenHeight / 9),
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    resizeMode={"contain"}
                    source={require("../../assets/images/circle.png")}
                  >
                    <CountDownCircle
                      seconds={
                        this.state.displayResult
                          ? TIME_PAUSE
                          : this.props.currentQuestion.timeLeft || 10
                      }
                      radius={Math.round(screenHeight / 19)}
                      borderWidth={Math.round(screenHeight / 42)}
                      color={this.state.displayResult ? "limegreen" : "#ff003f"}
                      bgColor="#fff"
                      textStyle={{ fontSize: RF(3) }}
                      finishCallback={() => this.finishCountDownCallback()}
                    />
                  </ImageBackground>
                </FadeSlideView>

                <View
                  style={{
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <FadeSlideView delay={150}>
                    <Text
                      style={{
                        color: "white",
                        fontSize: this.state.displayResult ? RF(5) : RF(8),
                        fontWeight: "bold",
                        marginTop: Math.round(screenHeight / 42),
                        textAlign: "center"
                      }}
                    >
                      {this.props.currentQuestion.question}
                    </Text>
                  </FadeSlideView>

                  <FadeSlideView delay={500} direction="up">
                    <View
                      style={{
                        marginTop: Math.round(screenHeight / 42),
                        flex: 1,
                        alignItems: "stretch",
                        alignContent: "stretch",
                        alignSelf: "stretch"
                      }}
                    >
                      <Response
                        {...this.props}
                        key={this.state.displayResult}
                        data={{
                          answers: this.currentQuestion.answers,
                          currentQuestionId: this.currentQuestion.id,
                          currentQuestionTimeLeft: this.currentQuestion
                            .timeLeft,
                          currentQuestionTimeEnd: this.currentQuestion.timeEnd,
                          currentQuizId: this.currentQuiz.id,
                          withImage: this.currentQuestion.withImage,
                          withResult: this.state.displayResult,
                          currentResults: this.currentResults
                        }}
                        modeTele={true}
                      />
                    </View>
                  </FadeSlideView>
                  {this.state.displayResult === false && (
                    <FadeSlideView delay={800} direction="right">
                      <PagingControl
                        style={{ marginTop: Math.round(screenHeight / 40) }}
                        max={this.props.currentQuiz.questions.length}
                        width={Math.round(screenWidth / 2)}
                        current={this.props.currentQuestion.order}
                        modeTele={true}
                      />
                    </FadeSlideView>
                  )}
                </View>
              </View>
            )}

            {this.state.displayResult && (
              <View
                style={{
                  flex: 1,
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  marginTop: Math.round(screenHeight / 6)
                }}
              >
                <FadeSlideView
                  delay={500}
                  direction="up"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  <ClassementPhone />
                  <Ranking data={this.state.answersByTimes} modeTele={true} />
                </FadeSlideView>
              </View>
            )}
          </View>
        )}
      </ImageBackground>
    );
  }
}

export default TeleQuizzes;

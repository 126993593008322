import React, { Component } from "react";
import { Text, View, Image, ImageBackground, Dimensions } from "react-native";
import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";

import RF from "react-native-responsive-fontsize";
import { THEME } from "../../constants/const";

export class TeleSurveyResults extends Component {
  _isMounted = false;
  state = { data: null, screen: "winner" };

  componentDidMount() {
    this._isMounted = true;
    const currentResults = window.localStorage.getItem("currentSurveyResults");
    const playersRanking = [];
    if (currentResults != null) {
      const arrResults = JSON.parse(currentResults);
      // console.log(arrResults);
      arrResults.forEach((q) => {
        // console.log(q);
        const answerList = q.answersByTimes;
        const qId = q.id;
        // console.log(answerList);
        answerList.forEach(ans => {
          // console.log(ans);
          const exists = playersRanking.filter(pl => {
            return pl.userId === ans.userId;
          });
          if (exists.length > 0) {
            //Exist and need update
            exists[0].isRight += ans.isRight;
            exists[0].time += ans.time;
            exists[0].answeredList.push({order : qId , value : ans.isRight === 1});
          } else {
            //New needs to add
            ans.answeredList = [{order : qId , value : ans.isRight === 1}];
            playersRanking.push(ans);
          }
        });
      });

      playersRanking.sort((a, b) => {
        return b.isRight - a.isRight || a.time - b.time;
      });

      var answeredList = [];
      var winner = playersRanking[0] || null;
      if (winner !== null) {
        // console.log(winner);
        const listBoolean = [];
        arrResults.forEach((q) => {
          const qId = q.id;
          const exist = winner.answeredList.filter(l => {
            return l.order === qId;
          });
          if(exist.length > 0){
            listBoolean.push(exist[0].value);
          }else{
            listBoolean.push(false);
          }
        });
        winner.answeredList = listBoolean;
        answeredList = this.renderAnswerList(winner.answeredList);
      }else{
        const listBoolean = [];
        arrResults.forEach((q) => {
            listBoolean.push(false);
        });
        answeredList = this.renderAnswerList(listBoolean);
      }

      this.setState({
        winner: winner,
        data: playersRanking,
        totalQuestions: arrResults.length,
        answeredList: answeredList
      });
    }

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderAnsweredItem = (anw, i) => {
    return (
      <FadeSlideView direction="left" key={i}>
        <Image
          style={{
            width: 30,
            height: 30,
            margin: 10
          }}
          resizeMode={"contain"}
          source={
            anw
              ? require("../../assets/images/right_answered.png")
              : require("../../assets/images/wrong_answered.png")
          }
        />
      </FadeSlideView>
    );
  };

  renderAnswerList = arrList => {
    const out = [];
    for (let i = 0; i < arrList.length; i++) {
      const anw = arrList[i];
      out.push(this.renderAnsweredItem(anw, i));
    }
    return out;
  };

  render() {
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <ImageBackground
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        source={require("../../assets/themes/" + THEME + "/bg.png")}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            top: 0,
            position: "absolute"
          }}
        />
        {this.state.screen === "winner" && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              padding: 50,
              width: "80%"
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(6),
                fontWeight: "bold",
                textTransform: "uppercase"
              }}
            >
              {this.props.currentSurvey.name}
            </Text>
            <Text
              style={{
                color: "darkgrey",
                textTransform: "uppercase",
                fontSize: RF(3),
                fontWeight: "100",
                marginBottom: 50
              }}
            >
              {this.props.currentSurvey.desc}
            </Text>  
              <FadeSlideView direction="down" delay={1000}>
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(10),
                    fontWeight: "bold",
                    margin: 20,
                    textAlign:'center'
                  }}
                >
                  {this.props.currentSurvey.thankyou_msg}
                </Text>
              </FadeSlideView>
          </View>
        )}
        
      </ImageBackground>
    );
  }
}

export default TeleSurveyResults;

import React, { Component } from "react";
import { Text, View, Image } from "react-native";
import RF from "react-native-responsive-fontsize";
import { THEME } from "../../constants/const";
import { getHeightPercent } from "../../libs/list_picker/ratio";

const logoLeft = require("../../assets/themes/" + THEME + "/header_left.png");
const logoRight = require("../../assets/themes/" + THEME + "/header_right.png");

export class Header extends Component {
  render() {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          width: "100%",
          maxHeight: getHeightPercent(8),
          marginTop: 5,
          ...this.props.style
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Image
            style={{
              width: getHeightPercent(8),
              height: getHeightPercent(8),
              marginLeft: 10
            }}
            resizeMode={"contain"}
            source={logoLeft}
          />
          {this.props.currentQuiz && (
            <View
              style={{
                width: 0,
                flexGrow: 1,
                flex: 1,
                padding: 10,
                justifyContent: "center"
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(1.5),
                  fontWeight: "bold",
                  textTransform: "uppercase"
                }}
              >
                {this.props.currentQuiz.name}
              </Text>

              <Text
                style={{
                  color: "white",
                  textTransform: "uppercase",
                  fontSize: RF(1.5),
                  fontWeight: "100"
                }}
              >
                {this.props.currentQuiz.subtitle}
              </Text>
            </View>
          )}
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Image
            style={{
              width: getHeightPercent(16),
              height: getHeightPercent(8),
              marginRight: 10
            }}
            resizeMode={"contain"}
            source={logoRight}
          />
        </View>
      </View>
    );
  }
}

export default Header;

import React, { Component } from "react";
import { Text, View, FlatList } from "react-native";
import {
  getWidthPercent
} from "../../libs/list_picker/ratio";

export class MatchPronostic extends Component {
  _keyExtractor = (item, index) => index;

  _renderItem = ({ item, index }) => {
    return (
      <View
        style={{
          width: Math.round(getWidthPercent(90) / this._numColumns()),
          height: this._getItemHeight()
        }}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginLeft: Math.round(this._getItemHeight() / 12) ,
            marginBottom: Math.round(this._getItemHeight() / 12),
            marginTop: Math.round(this._getItemHeight() / 12),
            marginRight: Math.round(this._getItemHeight() / 12),
            flex: 1
          }}
        >
          <View
            style={{
              backgroundColor: this._getColor(item.color),
              opacity: this._getOpacityBg(item.color),
              position: "absolute",
              height: "100%",
              width: "100%",
              borderRadius: Math.round(this._getItemHeight() / 20),
              borderWidth: 1,
              borderColor: this._getColor(item.color)
            }}
          />

          <View style={{ flexDirection: "column", width: "100%", justifyContent:"center", alignItems:"center" }}>
            <Text
              style={{
                fontSize: Math.round(
                  getWidthPercent(90) / this._numColumns() / 7
                ),
                opacity: this._getOpacityText(item.color),
                color: this._getColorText(item.color),
                textAlign: "center"
              }}
            >
              {item.displayName}
            </Text>
            <Text
              style={{
                fontSize: Math.round(
                  getWidthPercent(90) / this._numColumns() / 18
                ),
                color: this._getColorText(item.color),
                opacity: this._getOpacityText(item.color),
                textAlign: "center"
              }}
            >
              {item.scoreHome} - {item.scoreVisitor}
            </Text>
            <View style={{ flexDirection: "row", width: "90%" }}>
              <Text
                style={{
                  fontSize: Math.round(
                    getWidthPercent(90) / this._numColumns() / 18
                  ),
                  color: this._getColorText(item.color),
                  opacity: this._getOpacityText(item.color),
                  textAlign: "left"
                }}
              >
                {item.firstScorer.name}
              </Text>

              <Text
                style={{
                  flex: 1,
                  fontSize: Math.round(
                    getWidthPercent(90) / this._numColumns() / 18
                  ),
                  color: this._getColorText(item.color),
                  opacity: this._getOpacityText(item.color),
                  textAlign: "right"
                }}
              >
                {this._displayMinute(item.firstScoreMinute)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  };

  _displayMinute = min => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  _getColor = statusColor => {
    switch (statusColor) {
      case "1st":
        return "#F7D300";
      case "2nd":
        return "#4D5762";
      case "3rd":
        return "#CE8351";
      case "out":
        return "#E02020";
      default:
        return "#E5E5E5";
    }
  };

  _getColorText = statusColor => {
    switch (statusColor) {
      case "1st":
        return "#635602";
      case "2nd":
        return "#FFFFFF";
      case "3rd":
        return "#431F07";
      case "out":
        return "#FFFFFF";
      default:
        return "#FFFFFF";
    }
  };

  _getOpacityText = statusColor => {
    switch (statusColor) {
      case "1st":
        return 1;
      case "2nd":
        return 1;
      case "3rd":
        return 1;
      case "out":
        return 0.5;
      default:
        return 0.3;
    }
  }

  _getOpacityBg = statusColor => {
    switch (statusColor) {
      case "1st":
        return 1;
      case "2nd":
        return 1;
      case "3rd":
        return 1;
      case "out":
        return 0.3;
      default:
        return 0.05;
    }
  }

  _getItemHeight = () => {
    return Math.round(this.props.tall / this._numRows()) <
      Math.round(this.props.tall / 7)
      ? Math.round(this.props.tall / 7)
      : Math.round(this.props.tall / this._numRows());
  };

  _numColumns = () => {
    return this.props.data.length < 16
      ? Math.ceil(this.props.data.length / 3)
      : 6;
  };

  _numRows = () => {
    return Math.ceil(this.props.data.length / this._numColumns());
  };

  _getItemLayout = (data, index) => {
    return {
      length: this._getItemHeight(),
      offset: this._getItemHeight() * index,
      index
    };
  };

  render() {
    return (
      <FlatList
        style={{
          width: getWidthPercent(90),
          ...this.props.style
        }}
        showsHorizontalScrollIndicator={false}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        numColumns={this._numColumns()}
        keyExtractor={this._keyExtractor()}
        getItemLayout={this._getItemLayout}
      />
    );
  }
}

export default MatchPronostic;

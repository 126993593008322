import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  TouchableHighlight
} from "react-native";
import { BUTTON_COLOR } from "../../constants/const";
import {
  getHeightPercent,
  getWidthPercentMaxLimited
} from "../../libs/list_picker/ratio";
import RF from "react-native-responsive-fontsize";
import moment from "moment";

export class MenuMatch extends Component {
  state = { screen: "forecast_available" };

  constructor(props) {
    super(props);
    this.currentForecast = null;
  }

  componentDidMount() {
    const match_forecast = window.localStorage.getItem("match_forecast");
    if (match_forecast !== null) {
      this.currentForecast = JSON.parse(match_forecast);
      if (this.currentForecast.matchId === this.props.currentMatch.match_id) {
        if(this.props.mode !== 'nologin'){
          this.setState({
            screen: "forecasted",
            currentForecast: this.currentForecast
          });
        }
      } else {
        window.localStorage.removeItem("match_forecast");
      }
    }
  }

  displayMinute = min => {
    switch (min) {
      case "ex1":
        return "Tps add. 1er MT";

      case "ex2":
        return "Tps add. 2e MT";
      default:
        return `${min}'`;
    }
  };

  render() {
    const { navigate } = this.props.navigation;
    return (
      <View>
        {this.state.screen === "forecast_available" && (
          <View style={styles.moduleContainer}>
            <View style={styles.moduleTimmer}>
              <Image
                style={{ width: 20, height: 20, margin: 5 }}
                source={require("../../assets/images/clock.svg")}
                resizeMode="contain"
              />
              <Text style={{ fontSize: RF(2), color: "white" }}>
                {moment(this.props.currentMatch.start.seconds * 1000).format(
                  "HH"
                )}
                h
                {moment(this.props.currentMatch.start.seconds * 1000).format(
                  "mm"
                )}
              </Text>
            </View>
            <TouchableHighlight
              style={styles.moduleButton}
              onPress={() => {
                if (this.props.mode === "nologin") {
                  navigate("PreForecast");
                } else {
                  navigate("Forecast");
                }
              }}
              disabled={
                Math.floor(
                  (Date.now() +
                    parseInt(window.localStorage.getItem("delay"), 10)) /
                    1000
                ) >= this.props.currentMatch.start.seconds
              }
            >
              <Text
                style={
                  Math.floor(
                    (Date.now() +
                      parseInt(window.localStorage.getItem("delay"), 10)) /
                      1000
                  ) >= this.props.currentMatch.start.seconds
                    ? styles.moduleButtonTextDisabled
                    : styles.moduleButtonText
                }
              >
                Pronostiquer
              </Text>
            </TouchableHighlight>
          </View>
        )}
        {this.state.screen === "forecasted" && (
          <View style={styles.forecastContainer}>
            <View style={styles.forecastLine}>
              <Text style={styles.forecastText}>
                {this.props.currentMatch.team_home}
              </Text>
              <Image
                style={{
                  width: getWidthPercentMaxLimited(9, maxWidth),
                  height: getWidthPercentMaxLimited(9, maxWidth),
                  margin: 5
                }}
                source={this.props.currentMatch.team_home_logo}
                resizeMode="contain"
              />
              <Text style={styles.forecastText}>
                {this.state.currentForecast.scoreHome}
              </Text>
              <Text style={{ color: "white" }}>-</Text>
              <Text style={styles.forecastText}>
                {this.state.currentForecast.scoreVisitor}
              </Text>
              <Image
                style={{
                  width: getWidthPercentMaxLimited(9, maxWidth),
                  height: getWidthPercentMaxLimited(9, maxWidth),
                  margin: 5
                }}
                source={this.props.currentMatch.team_visitor_logo}
                resizeMode="contain"
              />
              <Text style={styles.forecastText}>
                {this.props.currentMatch.team_visitor}
              </Text>
            </View>
            <View style={styles.forecastLine}>
              <Text style={styles.forecastTextScorer}>
                {this.state.currentForecast.firstScorer.name}
              </Text>
              <Text style={styles.forecastTextScorer}>
                {this.displayMinute(
                  this.state.currentForecast.firstScoreMinute
                )}
              </Text>
            </View>
          </View>
        )}
        <View style={styles.separator} />
      </View>
    );
  }
}

const maxWidth = 500;
const styles = StyleSheet.create({
  moduleContainer: {
    flex: 1,
    flexDirection: "row",
    minHeight: getHeightPercent(10),
    marginTop: getHeightPercent(3),
    marginBottom: getHeightPercent(3),
    justifyContent: "flex-start",
    alignItems: "center"
  },
  moduleTimmer: {
    flexDirection: "row",
    width: getWidthPercentMaxLimited(20, maxWidth),
    justifyContent: "center",
    alignItems: "center"
  },
  moduleButton: {
    alignSelf: "center",
    width: getWidthPercentMaxLimited(80, maxWidth) - 10
  },
  moduleButtonText: {
    color: "white",
    fontSize: getWidthPercentMaxLimited(4, maxWidth),
    fontWeight: "bold",
    backgroundColor: BUTTON_COLOR,
    textAlign: "center",
    textTransform: "uppercase",
    marginLeft: 10,
    padding: 8,
    borderRadius: 3,
    width: getWidthPercentMaxLimited(60, maxWidth)
  },
  moduleButtonTextDisabled: {
    color: "white",
    fontSize: getWidthPercentMaxLimited(4, maxWidth),
    fontWeight: "bold",
    backgroundColor: "#ccc",
    textAlign: "center",
    textTransform: "uppercase",
    marginLeft: 10,
    padding: 8,
    borderRadius: 3,
    width: getWidthPercentMaxLimited(60, maxWidth)
  },
  separator: {
    width: getWidthPercentMaxLimited(30, maxWidth),
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    height: 1,
    alignSelf: "center"
  },
  forecastContainer: {
    flex: 1,
    minHeight: getHeightPercent(10),
    marginTop: getHeightPercent(3),
    marginBottom: getHeightPercent(3),
    justifyContent: "center",
    alignItems: "center"
  },
  forecastLine: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  forecastText: {
    color: "white",
    fontSize: getWidthPercentMaxLimited(5, maxWidth),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercentMaxLimited(2, maxWidth),
    marginRight: getWidthPercentMaxLimited(2, maxWidth)
  },
  forecastTextScorer: {
    color: "white",
    fontSize: getWidthPercentMaxLimited(4, maxWidth),
    fontWeight: "bold",
    textAlign: "center",
    marginLeft: getWidthPercentMaxLimited(2, maxWidth),
    marginRight: getWidthPercentMaxLimited(2, maxWidth)
  }
});

export default MenuMatch;

import React, { Component } from "react";
import { View, Dimensions } from "react-native";
import PropTypes from 'prop-types';

export class PagingControl extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired
  };

  static defaultProps = {
    width: 320,
    current: 1,
    max: 10
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props,
      itemWidth: ((props.width - 4) / props.max) - 4
    };
  }

  componentWillMount(){
    this.options = [];

    for (let i = 1; i <= this.state.max; i++) {
        this.options.push(this.renderPage({past : (i <= this.state.current), key : i}));
    }

  }

  renderPage({ past, key }) {
    const backgroundColor = past ? "red" : "lightgray";
    const screenHeight = Math.round(Dimensions.get("window").height);
    return (
      <View
      key={key}
        style={{
          width: this.state.itemWidth,
          height: this.props.modeTele ? Math.round(screenHeight/80) : 4,
          borderRadius: this.props.modeTele ? Math.round(screenHeight/160) : 2,
          marginRight: 2,
          marginLeft: 2,
          backgroundColor
        }}
      />
    );
  }

  render() {

    return (
      <View
        style={{ ...this.props.style, width: this.state.width, height: 4, justifyContent:'center', alignItems:'center', flexDirection: "row" }}
      >
        {this.options}
      </View>
    );
  }
}

export default PagingControl;

import React, { Component } from "react";
import { Text, View, FlatList, Dimensions, Image } from "react-native";
import RF from "react-native-responsive-fontsize";

export class Ranking extends Component {
  constructor(props) {
    super(props);
    if (this.props.firebase) {
      this.props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          this.setState({ authUser });
        }
      });
    }
  }
  _keyExtractor = (item, index) => item.userId;

  _renderHeader = () => {
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "stretch",
          alignItems: "flex-start",
          width: this.props.modeTele ? Math.round(screenWidth/2) : 320,
          justifyContent: "space-between",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
          paddingBottom: 5,
          paddingTop: 5
        }}
      >
        <Text
          style={{
            fontSize: this.props.modeTele ? RF(4) : RF(2),
            textAlign: "center",
            width: this.props.modeTele ? Math.round(screenWidth/16) : 40,
            textAlignVertical: "center",
            color: "lightgray"
          }}
        />
        <Text
          style={{
            color: "lightgray",
            textAlign: "left",
            textAlignVertical: "center",
            flex: 1,
            marginLeft: 8,
            fontSize: this.props.modeTele ? RF(3) : RF(1.5)
          }}
        >
          JOUEUR
        </Text>

        <Text
          style={{
            fontSize: this.props.modeTele ? RF(3) : RF(1.5),
            color: "lightgray",
            width: this.props.modeTele ? Math.round(screenWidth/16) :50
          }}
        >
          PTS
        </Text>

        <Text
          style={{
            color: "lightgray",
            fontSize: this.props.modeTele ? RF(3) : RF(1.5),
            textAlignVertical: "center",
            marginLeft: 5,
            width: this.props.modeTele ? Math.round(screenWidth/16) : 60
          }}
        >
          TPS
        </Text>
      </View>
    );
  };

  _renderEmpty = () => {
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "stretch",
          alignItems: "flex-start",
          width: this.props.modeTele ? Math.round(screenWidth/2) : 320,
          justifyContent: "space-between",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
          paddingBottom: 5,
          paddingTop: 5
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            textAlignVertical: "center",
            flex: 1,
            fontSize: this.props.modeTele ? RF(4) : RF(2)
          }}
        >
          AUCUN DES PARTICIPANTS
        </Text>

      </View>
    );
  };

  _renderItem = ({ item, index }) => {
    const screenWidth = Math.round(Dimensions.get("window").width);
  return(
    <View key={item.id}>
      {this.state &&
        this.state.authUser &&
        this.state.authUser.uid === item.userId && (
          <View
            style={{
              backgroundColor: "lightgray",
              position: "absolute",
              height: "100%",
              width: "100%",
              opacity: 0.4
            }}
          />
        )}
      <View
        direction={index % 2 === 0 ? "left" : "right"}
        style={{
          flex: 1,
          flexDirection: "row",
          alignContent: "stretch",
          alignItems: "flex-start",
          width: this.props.modeTele ? Math.round(screenWidth/2) : 320,
          justifyContent: "space-between",
          borderBottomColor: "gray",
          borderBottomWidth: 0.5,
          paddingBottom: 5,
          paddingTop: 5
        }}
      >
        {index === 0 && (
          <Image style={{
            width: this.props.modeTele ? Math.round(screenWidth/16) : 40,
            height:this.props.modeTele ? RF(4) : RF(2)
          }} source={require("../../assets/images/first.png")} resizeMode="contain" />
        )}
         {index > 0 && (
        <Text
          style={{
            fontSize: this.props.modeTele ? RF(4) : RF(2),
            textAlign: "center",
            width: this.props.modeTele ? Math.round(screenWidth/16) : 40,
            textAlignVertical: "center",
            color: "lightgray"
          }}
        >
          {index === 0 ? "🥇" : index + 1}
        </Text>
        )}
        <Text
          style={{
            color: "white",
            textAlign: "left",
            textAlignVertical: "center",
            flex: 1,
            marginLeft: 8,
            fontSize: this.props.modeTele ? RF(4) : RF(2.2)
          }}
        >
          {item.displayName}
        </Text>

        <Text
          style={{
            fontSize: this.props.modeTele ? RF(3) : RF(1.8),
            color: "white",
            textAlignVertical: 'bottom',
            width: this.props.modeTele ? Math.round(screenWidth/16) : 50
          }}
        >
          {(this.props.totalQuestions != null) ? `${item.isRight}/${this.props.totalQuestions}` : (item.isRight >= 1 ? "✅" : "❌")}
        </Text>
        
        {item.rightTimeMs > 0 && (
        <Text
          style={{
            color: "white",
            fontSize: this.props.modeTele ? RF(3) : RF(1.8),
            textAlignVertical: 'bottom',
            marginLeft: 5,
            width: this.props.modeTele ? Math.round(screenWidth/16) : 60
          }}
        >
          {Math.floor(item.rightTimeMs / 1000).toFixed(0)}'{Math.floor((item.rightTimeMs - (Math.floor(item.rightTimeMs / 1000).toFixed(0) * 1000))/10) < 10 ? "0" + Math.floor((item.rightTimeMs - (Math.floor(item.rightTimeMs / 1000).toFixed(0) * 1000))/10) : Math.floor((item.rightTimeMs - (Math.floor(item.rightTimeMs / 1000).toFixed(0) * 1000))/10)}s
        </Text>
        )}
        
        {item.rightTimeMs <= 0 && (
        <Text
          style={{
            color: "white",
            fontSize: this.props.modeTele ? RF(3) : RF(1.8),
            textAlignVertical: 'bottom',
            marginLeft: 5,
            width: this.props.modeTele ? Math.round(screenWidth/16) : 60
          }}
        >
          {this.props.data[0].isRight === 0 ? this._displayTimeMs(item.timeMs) : "-"}
        </Text>
        )}
      </View>
    </View>
  )
      }

      _displayTimeMs = (timeMs) => {
        console.log(timeMs);
        var displayT = "";
        displayT += Math.floor(timeMs / 1000).toFixed(0) + "'";
        if(Math.floor((timeMs - (Math.floor(timeMs / 1000).toFixed(0) * 1000))/10) < 10){
          displayT += "0" + Math.floor((timeMs - (Math.floor(timeMs / 1000).toFixed(0) * 1000))/10);
        }else{
          displayT +=  Math.floor((timeMs - (Math.floor(timeMs / 1000).toFixed(0) * 1000))/10) + "s";
        }
        return  displayT;
      }

  render() {
    return (
      <FlatList
        style={{marginBottom:33}}
        data={this.props.data}
        extraData={this.state}
        renderItem={this._renderItem}
        ListHeaderComponent={this._renderHeader}
        numColumns={1}
        keyExtractor={this._keyExtractor}
        ListEmptyComponent={this._renderEmpty}
      />
    );
  }
}

export default Ranking;

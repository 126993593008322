import React, { Component } from "react";
import { ImageBackground, View, Text, Dimensions } from "react-native";
import CountDownCircle from "../CountDown/circle";
import RF from "react-native-responsive-fontsize";
import PagingControl from "../Paging";
import Response from "../ReponsesSurvey";
import FadeSlideView from "../Anim/FadeSlideView";
import { TIME_PAUSE, THEME } from "../../constants/const";
import TeleHeader from "./Elements/header";
import { OPTIONS_QUESTIONS_TV_AND_RESULTS, OPTIONS_QUESTIONS_TV } from "../../constants/routes";

export class TeleSurveys extends Component {
  _isMounted = false;
  state = { displayResult: false };
  currentSurvey = null;
  currentQuestion = null;
  currentResults = null;

  constructor(props) {
    super(props);
    if (props.currentSurvey) {
      this.currentSurvey = props.currentSurvey;
    }
    if (props.currentQuestion) {
      this.currentQuestion = props.currentQuestion;
    }else{
      if (this.props.checkSurveyAndQuestion) {
        this.props.checkSurveyAndQuestion();
      }
    }
  }

  finishCountDownCallback = () => {
    //Show result and time response
    if(this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS){
      if (this.state.displayResult === true) {
        // Next question
        if (this.props.checkSurveyAndQuestion) {
          this.props.checkSurveyAndQuestion();
        }
        if (this._isMounted) {
          this.setState({ displayResult: false });
        }
      } else {
        const currentResults = window.localStorage.getItem("currentSurveyResults");
        if (currentResults != null) {
          this.currentResults = JSON.parse(currentResults);
          const answersByTimes = this.currentResults.filter(q => {
            return q.id === this.currentQuestion.id;
          })[0].answersByTimes;
          if (this._isMounted) {
            this.setState({ answersByTimes });
          }
          //re-calculate the percent
          for (let i = 0; i < this.currentQuestion.answers.length; i++) {
            const res = this.currentQuestion.answers[i];
            var result = null;
            result = this.currentResults
              .filter(q => {
                return q.id === this.currentQuestion.id;
              })[0]
              .answers.filter(r => {
                return r.id === res.id;
              })[0];
            this.currentQuestion.answers[i].withResult = true;
            this.currentQuestion.answers[i].percent = result.percent || 0;
            this.currentQuestion.answers[i].totalAnswers = result.totalAnswers;
            this.currentQuestion.answers[i].isRight =
              res.id === this.currentQuestion.rightAnswer;
          }
          
        }
        if (this._isMounted) {
          this.setState({ displayResult: true });
        }
      }
    }else if(this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV){
      // Next question
      if (this.props.checkSurveyAndQuestion) {
        this.props.checkSurveyAndQuestion();
      }
    }
    
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);
    return (
      <ImageBackground
        style={{ flex: 1, alignItems: "center" }}
        source={require("../../assets/themes/" + THEME + "/bg.png")}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            position: "absolute"
          }}
          texts={this.props.currentSurvey}
        />

        <View
          style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
        >
          {this.props.currentQuestion && (
            <View
              style={{
                flex: 1,
                alignSelf: "center",
                justifyContent: "center",
                alignItems: "center",
                maxWidth: Math.round(screenWidth/1.1),
              }}
            >
              <FadeSlideView direction="down">
                <ImageBackground
                  key={this.state.displayResult}
                  style={{
                    width: Math.round(screenHeight/9),
                    height: Math.round(screenHeight/9),
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/circle.png")}
                >
                  <CountDownCircle
                    seconds={
                      this.state.displayResult
                        ? TIME_PAUSE
                        : this.props.currentQuestion.timeLeft || 10
                    }
                    radius={Math.round(screenHeight/19)}
                    borderWidth={Math.round(screenHeight/42)}
                    color={this.state.displayResult ? "limegreen" : "#ff003f"}
                    bgColor="#fff"
                    textStyle={{ fontSize: RF(3) }}
                    finishCallback={() => this.finishCountDownCallback()}
                  />
                </ImageBackground>
              </FadeSlideView>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <FadeSlideView delay={150}>
                  <Text
                    style={{
                      color: "white",
                      fontSize:  RF(8),
                      fontWeight: "bold",
                      marginTop: Math.round(screenHeight/42),
                      textAlign: "center"
                    }}
                  >
                    {this.props.currentQuestion.question}
                  </Text>
                </FadeSlideView>

                <FadeSlideView delay={500} direction="up">
                  <View
                    style={{
                      marginTop: Math.round(screenHeight/42),
                      flex: 1,
                      alignItems: "stretch",
                      alignContent: "stretch",
                      alignSelf: "stretch"
                    }}
                  >
                    <Response
                      {...this.props}
                      key={this.state.displayResult}
                      data={{
                        answers: this.currentQuestion.answers,
                        currentQuestionId: this.currentQuestion.id,
                        currentQuestionTimeLeft: this.currentQuestion.timeLeft,
                        currentQuestionTimeEnd: this.currentQuestion.timeEnd,
                        currentSurveyId: this.currentSurvey.id,
                        withImage: this.currentQuestion.withImage,
                        withResult: this.state.displayResult,
                        currentResults: this.currentResults
                      }}
                      modeTele={true}
                    />
                  </View>
                </FadeSlideView>
                {this.state.displayResult === false && (
                  <FadeSlideView delay={800} direction="right">
                    <PagingControl
                      style={{ marginTop:  Math.round(screenHeight/40)  }}
                      max={this.props.currentSurvey.questions.length}
                      width={Math.round(screenWidth/2)}
                      current={this.props.currentQuestion.order}
                      modeTele={true}
                    />
                  </FadeSlideView>
                )}
              </View>
            </View>
          )}
        </View>
      </ImageBackground>
    );
  }
}

export default TeleSurveys;

import React, { Component } from "react";
import TeleHome from "../../components/Tele/home";
import { View, Text } from "react-native";
import { withFirebase } from "../../components/Firebase";
import { TIME_EXTRA_ANIM, TIME_PAUSE } from "../../constants/const";
import TeleQuizzes from "../../components/Tele/quizzes";
import TeleRanking from "../../components/Tele/ranking";
import TeleSurveyHome from "../../components/Tele/surveyHome";
import TeleSurveys from "../../components/Tele/surveys";
import TeleSurveyResults from "../../components/Tele/surveyResults";
import {
  OPTIONS_QUESTIONS_TV_AND_RESULTS,
  OPTIONS_QUESTIONS_TV
} from "../../constants/routes";
import { TeleMatchHome } from "../../components/Tele/matchHome";
import MatchWinner from "../../components/Tele/matchWinner";

class HomeScreen extends Component {
  _isMounted = false;
  state = {
    screen: "home", //home
    updatedNum: 0,
    currentQuiz: null,
    currentQuestion: null,
    currentSurvey: null,
    timeLeft: 0,
    timeLeftSurvey: 0,
    matchWinners: [],
    liveData: {},
    pronosticsSorted: []
  };

  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuiz = null;
    this.currentMatch = null;
    this.currentQuestion = null;
    this.currentResults = null;

    this.metaData = require("../../metadata.json");

    if (props.firebase) {
      // for current quiz
      props.firebase.currentQuizQuery().then(query => {
        query.onSnapshot(
          snapshot => {
            // console.log(snapshot.size);
            if (snapshot.size <= 0) {
              window.localStorage.removeItem("currentQuiz");
              this.currentQuiz = null;
            }
            // Actually the data should be only 1
            snapshot.forEach(data => {
              this.currentQuiz = data.data();
              if(this.currentQuiz.start_delayed){
                this.currentQuiz.start_display = this.currentQuiz.start;
                this.currentQuiz.start = this.currentQuiz.start_delayed;
              }
              window.localStorage.setItem(
                "currentQuiz",
                JSON.stringify(this.currentQuiz)
              );
            });

            this.checkQuizAndQuestion();
            this.checkTimeDisplay();
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });

      // for current survey
      props.firebase.currentSurveyQuery().then(query => {
        query.onSnapshot(
          snapshot => {
            if (snapshot.size <= 0) {
              window.localStorage.removeItem("currentSurvey");
              this.currentSurvey = null;
            }
            // Actually the data should be only 1
            snapshot.forEach(data => {
              this.currentSurvey = data.data();
              this.currentSurvey.start_display = this.currentSurvey.start;
              window.localStorage.setItem(
                "currentSurvey",
                JSON.stringify(this.currentSurvey)
              );
              
            });
            this.checkSurveyAndQuestion();
            this.checkTimeDisplay();
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });

      // Get the current Match
      props.firebase.currentMatchQuery().then(query => {
        query.onSnapshot(
          snapshot => {
            if (snapshot.size <= 0) {
              console.log("No Match Active!");
              window.localStorage.removeItem("match_forecast");
              this.currentMatch = null;
            }
            // Actually the data should be only 1
            var currentMatch = null;
            snapshot.forEach(data => {
              currentMatch = data.data();
            });

            // if(this.currentMatch !== null){
            //   // check if same match and

            // }

            this.currentMatch = currentMatch;
            window.localStorage.setItem(
              "currentMatch",
              JSON.stringify(this.currentMatch)
            );
            if (this._isMounted) {
              this.setState({ currentMatch: this.currentMatch });
            }

            this.checkTimeDisplay();
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });
    }
    this.callbackTimer = this.callbackTimer.bind(this);
    this.callbackTimerForEnd = this.callbackTimerForEnd.bind(this);
    this.callbackMatch = this.callbackMatch.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    var intervalId = setInterval(() => {
      this.checkEndTime();
    }, 1000);
    this.setState({ intervalId: intervalId });
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this._isMounted = false;
  }

  checkEndTime() {
    // Check for QUIZ
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
    );
    // console.log(this.state.screen);
    if (this.state.screen === "ranking") {
      if (this.currentQuiz !== null) {
        // console.log(now - this.currentQuiz.end.seconds);
        if (now > this.currentQuiz.end.seconds) {
          console.log("looking for next quiz");
          window.location.reload(true);
        }
      }
    }
    if (this.state.screen === "thankyou") {
      if (this.currentSurvey !== null) {
        // console.log(now - this.currentSurvey.end.seconds);
        if (now > this.currentSurvey.end.seconds) {
          console.log("looking for next survey");
          window.location.reload(true);
        }
      }
    }
  }

  checkTimeDisplay() {
    // console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
    );

    var currentActive = null;
    const currentScreen =  this.state.screen;
    var shouldDisplayScreen = currentScreen; 
    if (this.currentQuiz === null && this.currentSurvey !== null) {
      shouldDisplayScreen = "surveyHome";
      currentActive = this.currentSurvey;
    } else if (this.currentSurvey === null && this.currentQuiz !== null) {
      shouldDisplayScreen = "home";
      currentActive = this.currentQuiz;
    } else if (this.currentQuiz !== null && this.currentSurvey !== null) {
      if (
        this.currentSurvey.start.seconds < this.currentQuiz.start_display.seconds &&
        now < this.currentSurvey.end.seconds
      ) {
        if (now >= this.currentSurvey.start.seconds) {
          if (this.currentQuestion == null) {
            shouldDisplayScreen = "thankyou" ;
          } else {
            shouldDisplayScreen = "surveys" ;
          }
        } else {
          shouldDisplayScreen = "surveyHome" ;
        }
        currentActive = this.currentSurvey;
      } else if (
        this.currentQuiz.start_display.seconds < this.currentSurvey.start.seconds &&
        now < this.currentQuiz.end.seconds
      ) {
        if (now >= this.currentQuiz.start.seconds) {
          if (this.currentQuestion == null) {
            shouldDisplayScreen = "ranking" ;
          } else {
            shouldDisplayScreen = "quizzes" ;
          }
        } else {
          shouldDisplayScreen = "home" ;
        }
        currentActive = this.currentQuiz;
      }
    }

    if (this.currentMatch !== null) {
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
      );
      if (currentActive === null) {
        shouldDisplayScreen =  "matchHome";
      } else {
        if (!(now >= currentActive.start_display.seconds && now <= currentActive.end.seconds)) {
          shouldDisplayScreen = "matchHome";
        }
      }
    }

    if(shouldDisplayScreen !== currentScreen){
      if(shouldDisplayScreen.includes("match")){
        this.setState({screen: shouldDisplayScreen, timeLeft: this.currentMatch.start.seconds - now});
      }else{
        this.setState({screen: shouldDisplayScreen});
      }
    }
    
  }

  callbackMatch(dataPronos, dataLive, fullList) {
    this.setState({
      screen: "matchWinner",
      matchWinners: dataPronos,
      liveData: dataLive,
      pronosticsSorted: fullList,
      updatedNum: this.state.updatedNum + 1
    });
  }

  callbackTimerForEnd(val) {
    if (val <= 0) {
      this.setState({ screen: "thankyou" });
    }
  }

  callbackTimer(val) {
    //Quizzes started
    // console.log(val);
    if (val <= 0) {
      if (this.state.screen === "surveyHome") {
        if (
          this.currentSurvey.display_type ===
            OPTIONS_QUESTIONS_TV_AND_RESULTS ||
          this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV
        ) {
          this.checkSurveyAndQuestion(true);
          // this.setState({ screen: "surveys" });
        }
      } else if (this.state.screen === "home") {
        if (this.currentQuiz !== null) {
          this.checkQuizAndQuestion(true);
          // this.setState({ screen: "quizzes" });
        }
      } else if (this.state.screen === "matchHome") {
        console.log("should display match");
      }
    } else {
      this.checkTimer(val);
    }
  }

  checkTimer = val => {
    // console.log(val);
    if (
      this.currentQuiz !== null ||
      this.currentSurvey !== null ||
      this.currentMatch !== null
    ) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
      );
      var timeLeft = 0;
      if (this.state.screen === "surveyHome") {
        if (this.currentSurvey === null) {
          this.checkTimeDisplay();
          return;
        } else {
          timeLeft = this.currentSurvey.start.seconds - now;
        }
      } else if (this.state.screen === "home") {
        if (this.currentQuiz === null) {
          this.checkTimeDisplay();
          return;
        } else {
          timeLeft =
            this.currentQuiz !== null
              ? this.currentQuiz.start.seconds - now
              : 10;
        }
      } else if (this.state.screen === "matchHome") {
        timeLeft =
          this.currentMatch !== null
            ? this.currentMatch.start.seconds - now
            : 0;
            this.checkTimeDisplay();
      }
      // console.log(Math.abs(val - timeLeft));
      if (Math.abs(val - timeLeft) > 1)
        // console.log("sycned timer");
        // console.log(timeLeft);
        this.setState({
          timeLeft: timeLeft,
          updatedNum: this.state.updatedNum + 1
        });
    }
  };

  checkSurveyAndQuestion = needRedirect => {
    if (this.currentSurvey !== null) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
      );
      const timeLeft = this.currentSurvey.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentSurvey;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentSurvey.start.seconds;
        this.currentQuestion = null;
        var time_pause = 0;
        if (
          this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS
        ) {
          time_pause = TIME_PAUSE;
        }
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter(e => {
            return e.order === i + 1;
          })[0];
          const qTime = question ? question.time : 0;
          if (i === 0) {
            timePast = timePast + qTime + TIME_EXTRA_ANIM;
          } else {
            timePast = timePast + time_pause + qTime + TIME_EXTRA_ANIM;
          }
          const now = Math.floor(
            (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) /
              1000
          );
          if (now < timePast) {
            this.currentQuestion = question;
            this.currentQuestion.timeLeft = timePast - now;
            this.currentQuestion.timeEnd = timePast;
            break;
          }
        }
        if (this.currentQuestion == null) {
          // Finish Quiz, no more question, should go to thankyou
          // console.log("go thankyou");
          if (
            this.state.screen === "surveys" ||
            this.state.screen === "surveyHome"
          ) {
            this.setState({
              screen: "thankyou",
              currentSurvey: this.currentSurvey
            });
            // console.log("go thankyou setted");
          }
        }
      }

      //Only need to check the respones if the result is display on TV
      if (
        this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS
      ) {
        this.checkResponses();
      }
      // console.log("checkSurveyQuestion");
      if (needRedirect) {
        this.setState({
          currentSurvey: this.currentSurvey,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft,
          screen: "surveys"
        });
      } else {
        this.setState({
          currentSurvey: this.currentSurvey,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft
        });
      }

      // }
    } else {
      // navigate to screen no QUIZ
      // this.props.navigation.navigate("NoQuiz");
    }
  };

  checkQuizAndQuestion = needRedirect => {
    if (this.currentQuiz !== null) {
      // Process the time line for quiz
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delayTele"), 10)) / 1000
      );
      const timeLeft = this.currentQuiz.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentQuiz;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentQuiz.start.seconds;
        this.currentQuestion = null;
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter(e => {
            return e.order === i + 1;
          })[0];
          const qTime = question ? question.time : 0;
          if (question) {
            if (i === 0) {
              timePast = timePast + qTime + TIME_EXTRA_ANIM;
            } else {
              timePast = timePast + TIME_PAUSE + qTime + TIME_EXTRA_ANIM;
            }
            const now = Math.floor(
              (Date.now() +
                parseInt(window.localStorage.getItem("delayTele"), 10)) /
                1000
            );
            if (now < timePast) {
              this.currentQuestion = question;
              this.currentQuestion.timeLeft = timePast - now;
              this.currentQuestion.timeEnd = timePast;
              break;
            }
          }
        }
        if (this.currentQuestion == null) {
          // Finish Quiz, no more question, should go to ranking
          if (this.state.screen === "home" || this.state.screen === "quizzes")
            this.setState({ screen: "ranking", currentQuiz: this.currentQuiz });
        }
      }
      this.checkAnswers();
      // console.log("checkQuizAndQuestion");
      if (needRedirect) {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft,
          screen: "quizzes"
        });
      } else {
        this.setState({
          currentQuiz: this.currentQuiz,
          currentQuestion: this.currentQuestion,
          timeLeft: timeLeft
        });
      }

      // }
    } else {
      // navigate to screen no QUIZ
      // this.props.navigation.navigate("NoQuiz");
    }
  };

  recalculateRanking = () => {
    const currentAnswers = window.localStorage.getItem("currentAnwsers");
    if (currentAnswers !== null && this.currentQuiz !== null) {
      const arrAnswers = JSON.parse(currentAnswers);
      const results = this.currentQuiz.questions.map(q => {
        // should save for ranking of time
        const answersForQ = arrAnswers
          .map(e => {
            return { 
              ...e, 
              isRight: q.rightAnswer === e.answerId ? 1 : 0,
              rightTimeMs : q.rightAnswer === e.answerId ? e.timeMs : 0
             };
          })
          .filter(ans => {
            return (
              ans.quizId === this.currentQuiz.id && ans.questionId === q.id
            );
          })
          .sort((a, b) => {
            return (
              b.isRight - a.isRight || a.rightTimeMs - b.rightTimeMs || a.timeMs - b.timeMs || a.time - b.time
            );
          });

        q.answersByTimes = answersForQ;
        q.totalAnswers = answersForQ.length;
        q.answers = q.answers.map(a => {
          const answersForA = answersForQ.filter(ans => {
            return ans.answerId === a.id;
          });
          a.totalAnswers = answersForA.length;
          a.percent = a.totalAnswers / q.totalAnswers;
          return a;
        });
        return q;
      });
      this.currentResults = results;
      window.localStorage.setItem("currentResults", JSON.stringify(results));
      if (this._isMounted) {
        this.setState({ updatedNum: this.state.updatedNum + 1 });
      }
    }
  };

  checkAnswers = () => {
    if (this.currentQuiz !== null) {
      this.props.firebase.currentAnswersQuery(this.currentQuiz.id).onSnapshot(
        snapshot => {
          if (snapshot.size <= 0) {
            window.localStorage.removeItem("currentAnwsers");
            window.localStorage.removeItem("currentResults");
          }
          // Actually the data should be only 1
          var arrData = [];
          snapshot.forEach(data => {
            arrData.push(data.data());
          });
          window.localStorage.setItem(
            "currentAnwsers",
            JSON.stringify(arrData)
          );
          this.recalculateRanking();
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  };

  checkResponses = () => {
    if (this.currentSurvey !== null) {
      this.props.firebase
        .currentResponeseQuery(this.currentSurvey.id)
        .onSnapshot(
          snapshot => {
            if (snapshot.size <= 0) {
              window.localStorage.removeItem("currentSurveyAnwsers");
              window.localStorage.removeItem("currentSurveyResults");
            }
            // Actually the data should be only 1
            var arrData = [];
            snapshot.forEach(data => {
              arrData.push(data.data());
            });
            window.localStorage.setItem(
              "currentSurveyAnwsers",
              JSON.stringify(arrData)
            );
            this.recalculateResultForSurvey();
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
    }
  };

  recalculateResultForSurvey = () => {
    const currentAnswers = window.localStorage.getItem("currentSurveyAnwsers");
    if (currentAnswers !== null && this.currentSurvey !== null) {
      const arrAnswers = JSON.parse(currentAnswers);
      const results = this.currentSurvey.questions.map(q => {
        // should save for ranking of time
        const answersForQ = arrAnswers
          .map(e => {
            return { ...e, isRight: q.rightAnswer === e.answerId ? 1 : 0 };
          })
          .filter(ans => {
            return (
              ans.surveyId === this.currentSurvey.id && ans.questionId === q.id
            );
          })
          .sort((a, b) => {
            return (
              b.isRight - a.isRight || a.timeMs - b.timeMs || a.time - b.time
            );
          });

        q.answersByTimes = answersForQ;
        q.totalAnswers = answersForQ.length;
        q.answers = q.answers.map(a => {
          const answersForA = answersForQ.filter(ans => {
            return ans.answerId === a.id;
          });
          a.totalAnswers = answersForA.length;
          a.percent = a.totalAnswers / q.totalAnswers;
          return a;
        });
        return q;
      });
      this.currentResults = results;
      window.localStorage.setItem(
        "currentSurveyResults",
        JSON.stringify(results)
      );
      if (this._isMounted) {
        this.setState({ updatedNum: this.state.updatedNum + 1 });
      }
    }
  };

  componentWillMount() {
    this.checkQuizAndQuestion();
    this.checkAnswers();
  }

  render() {
    console.log(this.state);
    return (
      <View style={{ flex: 1 }}>
        {this.state.screen === "matchHome" && (
          <TeleMatchHome
            key={this.state.updatedNum}
            {...this.props}
            currentMatch={this.state.currentMatch}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackTimer}
            callbackMatch={this.callbackMatch}
          />
        )}
        {this.state.screen === "matchWinner" && (
          <MatchWinner
            key={this.state.updatedNum}
            {...this.props}
            currentMatch={this.state.currentMatch}
            matchWinners={this.state.matchWinners}
            liveData={this.state.liveData}
            pronosticsSorted={this.state.pronosticsSorted}
          />
        )}
        {this.state.screen === "surveyHome" && (
          <TeleSurveyHome
            key={this.state.updatedNum}
            {...this.props}
            currentSurvey={this.state.currentSurvey}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackTimer}
            callbackTimerForEnd={this.callbackTimerForEnd}
          />
        )}
        {this.state.screen === "surveys" && (
          <TeleSurveys
            key={this.state.timeLeft}
            currentSurvey={this.state.currentSurvey}
            currentQuestion={this.state.currentQuestion}
            checkSurveyAndQuestion={this.checkSurveyAndQuestion}
          />
        )}
        {this.state.screen === "thankyou" && (
          <TeleSurveyResults
            key={this.state.updatedNum}
            currentSurvey={this.state.currentSurvey}
          />
        )}
        {this.state.screen === "home" && (
          <TeleHome
            key={this.state.timeLeft}
            {...this.props}
            currentQuiz={this.state.currentQuiz}
            timeLeft={this.state.timeLeft}
            callbackTimer={this.callbackTimer}
          />
        )}
        {this.state.screen === "quizzes" && (
          <TeleQuizzes
            key={this.state.timeLeft}
            currentQuiz={this.state.currentQuiz}
            currentQuestion={this.state.currentQuestion}
            checkQuizAndQuestion={this.checkQuizAndQuestion}
          />
        )}
        {this.state.screen === "ranking" && (
          <TeleRanking
            key={this.state.updatedNum}
            currentQuiz={this.state.currentQuiz}
            {...this.props}
          />
        )}
        <Text
          style={{
            fontSize: 10,
            fontStyle: "italic",
            color: "white",
            position: "fixed",
            bottom: 0,
            right: 0
          }}
        >
          WisslMedia {this.metaData.version || "1.1.0"} - b
          {this.metaData.build || "01"}
        </Text>
      </View>
    );
  }
}

export default withFirebase(HomeScreen);

import React from "react";
import { View, Text, Image, TouchableHighlight } from "react-native";
import Header from "../../components/Header";
import RF from "react-native-responsive-fontsize";
import { THEME, MAIN_COLOR, MAIN_URL } from "../../constants/const";
import { getHeightPercent } from "../../libs/list_picker/ratio";

const withMainLayout = Component => {
  class WithMainLayout extends React.Component {
    _isMounted = false;

    constructor(props) {
      super(props);
      this.currentSurvey = null;
      this.currentQuiz = null;
      this.currentMatch = null;
      this.authUser = null;
      this.state = {
        updateNum: 0,
        authUser: null,
        currentQuiz: { name: "", subtitle: "" },
        currentSurvey: { name: "", subtitle: "" }
      };
      this.metaData = require("../../metadata.json");
      if (this.props.firebase) {
        console.log("MainLayout");
        // Get the current Quiz
        props.firebase.currentQuizQuery().then(query => {
          query.onSnapshot(
            snapshot => {
              if (snapshot.size <= 0) {
                console.log("No Quiz Active!");
                window.localStorage.removeItem("currentQuiz");
                this.currentQuiz = null;
              }
              // Actually the data should be only 1
              snapshot.forEach(data => {
                this.currentQuiz = data.data();
                if(this.currentQuiz.start_delayed){
                  this.currentQuiz.start_display = this.currentQuiz.start;
                  this.currentQuiz.start = this.currentQuiz.start_delayed;
                }
                window.localStorage.setItem(
                  "currentQuiz",
                  JSON.stringify(this.currentQuiz)
                );
                this.registerQuizIn();
                if (this._isMounted) {
                  this.setState({ currentQuiz: this.currentQuiz, updateNum: this.state.updateNum + 1 });
                  this.checkTimeDisplay();
                }
              });
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
        });

        // Get the current Survey
        props.firebase.currentSurveyQuery().then(query => {
          query.onSnapshot(
            snapshot => {
              if (snapshot.size <= 0) {
                console.log("No Survey Active!");
                window.localStorage.removeItem("currentSurvey");
                this.currentSurvey = null;
              }
              // Actually the data should be only 1
              snapshot.forEach(data => {
                window.localStorage.setItem(
                  "currentSurvey",
                  JSON.stringify(data.data())
                );
                this.currentSurvey = data.data();
                if (this._isMounted) {
                  this.setState({ currentSurvey: data.data(), updateNum: this.state.updateNum + 1 });
                  this.checkTimeDisplay();
                }
              });
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
        });

        // Get the current Match
        props.firebase.currentMatchQuery().then(query => {
          query.onSnapshot(
            snapshot => {
              if (snapshot.size <= 0) {
                console.log("No Match Active!");
                window.localStorage.removeItem("match_forecast");
                this.currentMatch = null;
              }
              // Actually the data should be only 1
              snapshot.forEach(data => {
                window.localStorage.setItem(
                  "currentMatch",
                  JSON.stringify(data.data())
                );
                this.currentMatch = data.data();
                this.registerMatchIn();
                this.getCurrentMatchPronostic();
                if (this._isMounted) {
                  this.setState({ currentMatch: data.data(), updateNum: this.state.updateNum + 1 });
                }
              });
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
        });

        // Update the logged in user
        this.props.firebase.auth.onAuthStateChanged(authUser => {
          if (authUser) {
            if (this._isMounted) {
              this.authUser = authUser;
              this.setState({ authUser, updateNum: this.state.updateNum + 1 });
              this.registerQuizIn();
              this.registerMatchIn();
              this.getCurrentMatchPronostic();
            }
          }
        });
      }
    }

    registerQuizIn = () => {
      if (this.authUser !== null && this.currentQuiz !== null) {
        this.props.firebase
          .quiz_participants(
            this.currentQuiz.id + "_" + this.currentQuiz.start.seconds,
            this.authUser.uid
          )
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email
          });
      }
    };

    registerMatchIn = () => {
      if (this.authUser !== null && this.currentMatch !== null) {
        this.props.firebase
          .match_participants(
            this.currentMatch.id + "_" + this.currentMatch.start.seconds,
            this.authUser.uid
          )
          .set({
            displayName: this.authUser.displayName,
            email: this.authUser.email
          });
      }
    };

    getCurrentMatchPronostic = () => {
      if (this.authUser !== null && this.currentMatch !== null) {
        this.props.firebase
          .pronostics_responses(`${this.authUser.uid}_${this.currentMatch.id}`)
          .get()
          .then(doc => {
            if (doc.exists) {
              window.localStorage.setItem(
                "match_forecast",
                JSON.stringify(doc.data())
              );
            }
          })
          .catch(err => {
            console.log("Error getting getCurrentMatchPronostic", err);
          });
      }
    };

    checkTimeDisplay() {
      // console.log("new data received");
      // console.log(this.currentSurvey);
      // console.log(this.currentQuiz);
      // const now = Math.floor(
      //   (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      // );
      // if (this.currentQuiz === null && this.currentSurvey !== null) {
      //   console.log("navigate to Surveys");
      //   this.props.navigation.navigate("Surveys");
      // } else if (this.currentSurvey === null && this.currentQuiz !== null) {
      //   console.log("navigate to Quizzes");
      //   this.props.navigation.navigate("Quizzes");
      // } else if (this.currentQuiz !== null && this.currentSurvey !== null) {
      //   if (
      //     this.currentSurvey.start.seconds < this.currentQuiz.start.seconds &&
      //     now < this.currentSurvey.end.seconds
      //   ) {
      //     console.log("navigate to Surveys");
      //     this.props.navigation.navigate("Surveys");
      //   } else if (
      //     this.currentQuiz.start.seconds < this.currentSurvey.start.seconds &&
      //     now < this.currentQuiz.end.seconds
      //   ) {
      //     console.log("navigate to Quizzes");
      //     this.props.navigation.navigate("Quizzes");
      //   }
      // }
    }

    componentDidMount() {
      this._isMounted = true;
      var intervalId = setInterval(() => {
        this.checkEndTime();
      }, 1000);
      this.setState({ intervalId: intervalId });
    }

    componentWillUnmount() {
      clearInterval(this.state.intervalId);
      this._isMounted = false;
    }

    checkEndTime() {
      // Check for QUIZ
      // NOTE: Need to check for survey also
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      if (this.props.navigation.state.routeName === "Thankyou") {
        if (this.currentSurvey !== null) {
          // console.log(timeLeft);
          if (now > this.currentSurvey.end.seconds) {
            // console.log("looking for next Survey");
            window.location.replace("https://" + MAIN_URL + "/SelectGame");
          }
        }
      }

      if (this.props.navigation.state.routeName === "Ranking") {
        if (this.currentQuiz !== null) {
          // console.log(timeLeft);
          if (now > this.currentQuiz.end.seconds) {
            // console.log("looking for next quiz");
            window.location.replace("https://" + MAIN_URL + "/SelectGame");
          }
        }
      }
    }

    render() {
      // console.log(this.state);
      return (
        <View
          style={{
            flex: 1
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              position: "fixed"
            }}
            source={require("../../assets/themes/" + THEME + "/bg.png")}
            resizeMode={"cover"}
          />
          <View
            style={{
              flex: 1,
              top: getHeightPercent(8) + 40,
              position: "fixed",
              height: "100%",
              width: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              paddingBottom: 100
            }}
          >
            <View
              style={{
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Component
                {...this.props}
                key={this.state.updateNum}
                authUser={this.state.authUser}
                currentQuiz={this.state.currentQuiz}
                currentSurvey={this.state.currentSurvey}
                currentMatch={this.state.currentMatch}
              />
            </View>
          </View>
          {this.state && this.state.authUser && (
            <View
              style={{
                height: 30,
                width: "100%",
                backgroundColor: MAIN_COLOR,
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                position: "fixed",
                top: 0,
                borderBottomColor: "white",
                borderBottomWidth: 1
              }}
            >
              <TouchableHighlight
                style={{
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  paddingLeft: 15,
                  borderRightColor: "white",
                  borderRightWidth: 1
                }}
                onPress={() => {
                  this.props.navigation.navigate("Home");
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                    marginRight: 10
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/home.svg")}
                />
              </TouchableHighlight>

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <TouchableHighlight
                  onPress={() => {
                    this.props.navigation.navigate("Profile");
                  }}
                >
                  <Image
                    style={{
                      width: 20,
                      height: 20,
                      margin: 10
                    }}
                    resizeMode={"contain"}
                    source={require("../../assets/images/avatar.png")}
                  />
                </TouchableHighlight>
                <TouchableHighlight
                  onPress={() => {
                    this.props.navigation.navigate("Profile");
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: RF(2),
                      fontWeight: "bold"
                    }}
                  >
                    {this.state.authUser.displayName}
                  </Text>
                </TouchableHighlight>
              </View>

              <TouchableHighlight
                style={{
                  alignItems: "flex-end",
                  alignContent: "flex-end",
                  paddingRight: 15,
                  borderLeftColor: "white",
                  borderLeftWidth: 1
                }}
                onPress={() => {
                  if (window.confirm("Déconnexion ?")) {
                    //delete all data
                    window.localStorage.clear();
                    this.props.firebase.doSignOut();
                  }
                }}
              >
                <Image
                  style={{
                    width: 20,
                    height: 20,
                    marginLeft: 10
                  }}
                  resizeMode={"contain"}
                  source={require("../../assets/images/logout.svg")}
                />
              </TouchableHighlight>
            </View>
          )}
          <Header
            {...this.props}
            style={{
              position: "fixed",
              top: this.state && this.state.authUser ? 30 : 0
            }}
            currentQuiz={this.state.currentQuiz}
          />
          <Text
            style={{
              fontSize: 10,
              fontStyle: "italic",
              color: "white",
              position: "fixed",
              bottom: 0,
              right: 0
            }}
          >
            WisslMedia {this.metaData.version || "1.1.0"} - b
            {this.metaData.build || "01"}
          </Text>
        </View>
      );
    }
  }

  return WithMainLayout;
};

export default withMainLayout;

import React, { Component } from "react";
import {
  Text,
  View,
  StyleSheet,
  Image,
  TouchableHighlight
} from "react-native";
import { BUTTON_COLOR, THEME } from "../../constants/const";
import {
  getHeightPercent,
  getWidthPercentMaxLimited
} from "../../libs/list_picker/ratio";
import RF from "react-native-responsive-fontsize";
import moment from "moment";

export class MenuQuiz extends Component {
  render() {
    const { navigate } = this.props.navigation;
    return (
      <View>
        <View style={styles.moduleContainer}>
          <View style={styles.moduleTimmer}>
            <Image
              style={{ width: 20, height: 20, margin: 5 }}
              source={require("../../assets/images/clock.svg")}
            />
            <Text style={{ fontSize: RF(2), color: "white" }}>
              {moment(this.props.currentQuiz.start.seconds * 1000).format("HH")}
              h
              {moment(this.props.currentQuiz.start.seconds * 1000).format("mm")}
            </Text>
          </View>
          <TouchableHighlight
            style={styles.moduleButton}
            onPress={() => navigate("Quizzes")}
          >
            <Text style={styles.moduleButtonText}>
              {THEME === "omgame" ? "Quiz Puma" : "Quiz"}
            </Text>
          </TouchableHighlight>
        </View>
        <View style={styles.separator} />
      </View>
    );
  }
}

const maxWidth = 500;
const styles = StyleSheet.create({
  moduleContainer: {
    flex: 1,
    flexDirection: "row",
    minHeight: getHeightPercent(10),
    marginTop: getHeightPercent(3),
    marginBottom: getHeightPercent(3),
    justifyContent: "flex-start",
    alignItems: "center"
  },
  moduleTimmer: {
    flexDirection: "row",
    width: getWidthPercentMaxLimited(20, maxWidth),
    justifyContent: "center",
    alignItems: "center"
  },
  moduleButton: {
    alignSelf: "center",
    width: getWidthPercentMaxLimited(80, maxWidth) - 10
  },
  moduleButtonText: {
    color: "white",
    fontSize: getWidthPercentMaxLimited(4, maxWidth),
    fontWeight: "bold",
    backgroundColor: BUTTON_COLOR,
    textAlign: "center",
    textTransform: "uppercase",
    marginLeft: 10,
    padding: 8,
    borderRadius: 3,
    width: getWidthPercentMaxLimited(60, maxWidth)
  },
  separator: {
    width: getWidthPercentMaxLimited(30, maxWidth),
    borderBottomColor: "#ccc",
    borderBottomWidth: 1,
    height: 1,
    alignSelf: "center"
  }
});

export default MenuQuiz;

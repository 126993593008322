export const THEME = 'quizchallenge-benchr';
export const MAIN_URL = "quizchallenge-benchr.web.app";
export const MAIN_COLOR = "#000000";
export const BUTTON_COLOR = "#89b5d2";
export const TEXT_HOME_1 = "Bienvenue!!!";
export const TEXT_HOME_2 = "Avant le concert, participez au Grand Quiz sur le thème Muze et gagnez un album dédicacé !";
export const TEXT_MENU   = "Merci de vous être inscrit. Vous pouvez désormais participer à nos différentes animations";

export const URL_RULES          = "https://firebasestorage.googleapis.com/v0/b/quizchallenge-benchr.appspot.com/o/rules%2Fstade_de_france.pdf?alt=media&token=b1cfbdfd-bb4d-4b73-aef3-3fb56ffb4f86";
export const PROFILE_COMPANY    = true;
export const PROFILE_PHONE      = true;
export const TEXT_OPTION_1 = true;
export const TEXT_OPTION_2 = `J’accepte de recevoir des offres commerciales.`;
export const TEXT_OPTION_3 = null;
export const EMAILS_RESULTS = 'developer@wissl.co';

export const TIME_PAUSE = 5;
export const TIME_DELAY = 0;
export const TIME_EXTRA_ANIM = 1;
export const FIREBASE_APP_API_KEY = "AIzaSyA4sCUgY34-DoqQ5jcNlIgbMSZdP848Xzo";
export const FIREBASE_APP_AUTH_DOMAIN = "quizchallenge-benchr.firebaseapp.com";
export const FIREBASE_APP_DATABASE_URL = "https://quizchallenge-benchr.firebaseio.com";
export const FIREBASE_APP_PROJECT_ID = "quizchallenge-benchr";
export const FIREBASE_APP_STORAGE_BUCKET = "quizchallenge-benchr.appspot.com";
export const FIREBASE_APP_MESSAGING_SENDER_ID = "14343510578";
export const FIREBASE_APP_ID = "1:14343510578:web:4d0c0be313231677";

import React, { Component } from "react";
import { Text, View, Image, ImageBackground, Dimensions } from "react-native";
import TeleHeader from "./Elements/header";
import FadeSlideView from "../Anim/FadeSlideView";
import ClassementPhone from "./Elements/classementonyourphone";
import Ranking from "../Ranking";
import Confetti from "react-confetti";
import RF from "react-native-responsive-fontsize";
import { MAIN_COLOR, THEME, EMAILS_RESULTS } from "../../constants/const";

export class TeleRanking extends Component {
  _isMounted = false;
  _isSaving = false;
  state = { data: null, screen: "winner" };

  componentDidMount() {
    this._isMounted = true;
    const currentResults = window.localStorage.getItem("currentResults");
    var playersRanking = [];
    if (currentResults != null) {
      const arrResults = JSON.parse(currentResults);
      // console.log(arrResults);
      arrResults.forEach(q => {
        // console.log(q);
        const answerList = q.answersByTimes;
        const qId = q.id;
        // console.log(answerList);
        answerList.forEach(ans => {
          // console.log(ans);
          const exists = playersRanking.filter(pl => {
            return pl.userId === ans.userId;
          });
          if (exists.length > 0) {
            //Exist and need update
            // We count only the time of right answers
            exists[0].isRight += ans.isRight;
            const rightTimeMs = ans.isRight === 1 ? ans.timeMs : 0;
            exists[0].time += ans.time;
            exists[0].timeMs += ans.timeMs;
            exists[0].rightTimeMs += rightTimeMs;
            exists[0].answeredList.push({
              order: qId,
              value: ans.isRight === 1
            });
          } else {
            //New needs to add
            ans.answeredList = [{ order: qId, value: ans.isRight === 1 }];
            playersRanking.push(ans);
          }
        });
      });

      playersRanking.sort((a, b) => {
        return (
          b.isRight - a.isRight ||
          a.rightTimeMs - b.rightTimeMs ||
          a.timeMs - b.timeMs ||
          a.time - b.time
        );
      });

      var answeredList = [];
      var winner = playersRanking[0] || null;
      if (winner !== null) {
        // console.log(winner);
        const listBoolean = [];
        arrResults.forEach(q => {
          const qId = q.id;
          const exist = winner.answeredList.filter(l => {
            return l.order === qId;
          });
          if (exist.length > 0) {
            listBoolean.push(exist[0].value);
          } else {
            listBoolean.push(false);
          }
        });
        winner.answeredList = listBoolean;
        answeredList = this.renderAnswerList(winner.answeredList);

        if (this._isSaving === false) {
          this._isSaving = true;
          const toSaveRanking = playersRanking.map((el, index) => {
            return {
              rank: index + 1,
              quiz_id: el.quizId,
              user_id: el.userId,
              display_name: el.displayName,
              email: el.email || "",
              answers_corrected: el.isRight,
              answers_total: arrResults.length,
              time: el.time,
              time_ms: el.timeMs,
              right_time_ms: el.rightTimeMs,
              answered_list: el.answeredList
            };
          });
          // Update winner to server
          if (this.props.firebase) {
            if (!this.props.currentQuiz.sent_result) {
              const id_result =
                winner.quizId + "_" + this.props.currentQuiz.start.seconds;

              var winSave = this.props.firebase.quiz_winner(id_result);
              const winnerData = {
                quiz_id: winner.quizId,
                user_id: winner.userId,
                display_name: winner.displayName,
                email: winner.email || "",
                answers_corrected: winner.isRight,
                answers_total: arrResults.length,
                time: winner.time,
                time_ms: winner.timeMs,
                right_time_ms: winner.rightTimeMs,
                answered_list: winner.answeredList,
                loge: THEME,
                quiz_name: this.props.currentQuiz.name,
                quiz_start: this.props.currentQuiz.start,
                quiz_end: this.props.currentQuiz.end,
                quiz_source_id: this.props.currentQuiz.quiz_id
              };

              const toSaveLength = toSaveRanking.length;
              var lastIndex = 0;
              var theRestLength = toSaveLength;
              var max = 450;
              var loopDone = 0;
              while (theRestLength > 0) {
                var batch = this.props.firebase.db.batch();
                batch.set(winSave, winnerData);
                for (let i = lastIndex; i < lastIndex + max; i++) {
                  if (i < toSaveLength) {
                    const el = toSaveRanking[i];
                    var doc = this.props.firebase.quiz_winner_ranking(
                      id_result,
                      el.rank
                    );
                    batch.set(doc, el);
                  }
                }

                loopDone += 1;
                theRestLength = theRestLength - max;
                lastIndex = loopDone * max;

                // Commit the batch
                const that = this;
                batch.commit().then(function() {
                  console.log("updated winner");
                  if (theRestLength <= 0) {
                    that._callSendEmailRanking();
                  }
                });
              }
            }
          }
        }
      } else {
        const listBoolean = [];
        arrResults.forEach(q => {
          listBoolean.push(false);
        });
        answeredList = this.renderAnswerList(listBoolean);
      }

      if (playersRanking.length > 0) {
        //Only get last 10
        playersRanking = playersRanking.splice(0, 10);
      }

      this.setState({
        winner: winner,
        data: playersRanking,
        totalQuestions: arrResults.length,
        answeredList: answeredList
      });
    }

    setTimeout(
      function() {
        if (this._isMounted) {
          this.setState({ screen: "list" });
        }
      }.bind(this),
      10000
    );
  }

  _callSendEmailRanking = id => {
    if (this.props.firebase) {
      const sending = window.localStorage.getItem("sendingEmail");
      if (sending !== null) {
        return true;
      }
      window.localStorage.setItem("sendingEmail", "true");
      const sendMailQuizRanking = this.props.firebase.functions.httpsCallable(
        "sendMailQuizRanking"
      );
      //send Email of results
      const dataMail = {
        currentQuizResultId: `${this.props.currentQuiz.id}_${this.props.currentQuiz.start.seconds}`,
        currentQuizId: this.props.currentQuiz.id,
        currentQuizName: this.props.currentQuiz.name,
        sent_result: this.props.currentQuiz.sent_result || false,
        to: EMAILS_RESULTS
      };
      // console.log(dataMail);
      sendMailQuizRanking(dataMail)
        .then(re => {
          setTimeout(
            function() {
              window.localStorage.removeItem("sendingEmail");
            },
            5000
          );
        })
        .catch(er => {
          console.log(er);
          setTimeout(
            function() {
              window.localStorage.removeItem("sendingEmail");
            },
            5000
          );
        });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderAnsweredItem = (anw, i) => {
    return (
      <FadeSlideView direction="left" key={i}>
        <Image
          style={{
            width: 30,
            height: 30,
            margin: 10
          }}
          resizeMode={"contain"}
          source={
            anw
              ? require("../../assets/images/right_answered.png")
              : require("../../assets/images/wrong_answered.png")
          }
        />
      </FadeSlideView>
    );
  };

  renderAnswerList = arrList => {
    const out = [];
    for (let i = 0; i < arrList.length; i++) {
      const anw = arrList[i];
      out.push(this.renderAnsweredItem(anw, i));
    }
    return out;
  };

  render() {
    const screenHeight = Math.round(Dimensions.get("window").height);
    const screenWidth = Math.round(Dimensions.get("window").width);

    return (
      <ImageBackground
        style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        source={require("../../assets/themes/" + THEME + "/bg.png")}
        resizeMode={"cover"}
      >
        <TeleHeader
          style={{
            flex: 1,
            flexDirection: "row",
            width: screenWidth,
            maxHeight: "20%",
            padding: 10,
            marginTop: 10,
            top: 0,
            position: "absolute"
          }}
        />
        <Confetti width={screenWidth} height={screenHeight} />

        {this.state.screen === "winner" && (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: MAIN_COLOR,
              borderRadius: 10,
              padding: 50,
              width: "80%"
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: RF(6),
                fontWeight: "bold",
                textTransform: "uppercase",
                textAlign: "center"
              }}
            >
              {this.props.currentQuiz.name}
            </Text>
            <Text
              style={{
                color: "darkgrey",
                textTransform: "uppercase",
                fontSize: RF(3),
                fontWeight: "100",
                marginBottom: 50
              }}
            >
              {this.props.currentQuiz.subtitle}
            </Text>

            <View
              style={{
                height: 40,
                borderRadius: 20,
                borderColor: "dimgrey",
                borderWidth: 1,
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 30
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(2.5),
                  fontWeight: "300",
                  textAlign: "center",
                  textAlignVertical: "center"
                }}
              >
                Vainqueur
              </Text>
            </View>

            <FadeSlideView direction="down" delay={1000}>
              <Text
                style={{
                  color: "white",
                  fontSize: RF(9),
                  fontWeight: "bold",
                  margin: 20
                }}
              >
                {this.state.winner != null
                  ? this.state.winner.displayName
                  : "AUCUN DES PARTICIPANTS"}
              </Text>
            </FadeSlideView>

            <View
              style={{
                flexDirection: "row",
                marginTop: 50,
                marginBottom: 20
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: RF(3)
                }}
              >
                Score:{" "}
                {this.state.winner != null ? this.state.winner.isRight : 0}
              </Text>
              <Text
                style={{
                  color: "white",
                  fontSize: RF(3),
                  marginLeft: 100
                }}
              >
                Temps:{" "}
                {this.state.winner != null
                  ? Math.floor(this.state.winner.timeMs / 1000).toFixed(0) +
                    "'" +
                    (Math.floor(
                      (this.state.winner.timeMs -
                        Math.floor(this.state.winner.timeMs / 1000).toFixed(0) *
                          1000) /
                        10
                    ) < 10
                      ? "0" +
                        Math.floor(
                          (this.state.winner.timeMs -
                            Math.floor(this.state.winner.timeMs / 1000).toFixed(
                              0
                            ) *
                              1000) /
                            10
                        )
                      : Math.floor(
                          (this.state.winner.timeMs -
                            Math.floor(this.state.winner.timeMs / 1000).toFixed(
                              0
                            ) *
                              1000) /
                            10
                        )) +
                    "s"
                  : 0}
              </Text>
            </View>

            {this.state.answeredList && (
              <View
                style={{
                  flexDirection: "row",
                  borderColor: "dimgrey",
                  borderBottomWidth: 1,
                  borderTopWidth: 1,
                  width: "90%",
                  justifyContent: "center",
                  padding: 10
                }}
              >
                {this.state.answeredList}
              </View>
            )}
          </View>
        )}
        {this.state.screen === "list" && (
          <View
            style={{ flex: 1, flexDirection: "row", justifyContent: "center" }}
          >
            <View
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                height: screenHeight,
                maxHeight: screenHeight
              }}
            >
              <FadeSlideView
                style={{
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(8),
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    marginLeft: Math.round(screenWidth / 30),
                    marginRight: Math.round(screenWidth / 30),
                    textAlign: "center",
                    width: Math.round((2 * screenWidth) / 3)
                  }}
                >
                  {this.props.currentQuiz.name}
                </Text>
                <Text
                  style={{
                    color: "darkgrey",
                    textTransform: "uppercase",
                    fontSize: RF(5),
                    fontWeight: "100",
                    marginBottom: 30
                  }}
                >
                  {this.props.currentQuiz.subtitle}
                </Text>
              </FadeSlideView>
              <FadeSlideView
                delay={500}
                direction="up"
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Ranking {...this.state} modeTele={true} />
                <ClassementPhone style={{ marginTop: 50 }} />
              </FadeSlideView>
            </View>
          </View>
        )}
      </ImageBackground>
    );
  }
}

export default TeleRanking;

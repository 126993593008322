import React, { Component } from "react";
import { Text, View, TouchableHighlight } from "react-native";
import RF from "react-native-responsive-fontsize";
import {
  MAIN_COLOR,
  BUTTON_COLOR,
  TEXT_HOME_1,
  TEXT_HOME_2
} from "../../constants/const";
import { withFirebase } from "../Firebase";

export class Intro extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuiz = null;
    this.currentMatch = null;
    this.authUser = null;
    this.state = { skipRegister: false };

    if (props.firebase) {
      props.firebase.auth.onAuthStateChanged(authUser => {
        if (authUser !== null) {
          this.props.navigation.navigate("SelectGame");
        }
      });

      props.firebase.currentQuizQuery().then(query => {
        query.onSnapshot(
          snapshot => {
            if (snapshot.size <= 0) {
              console.log("No Quiz Active!");
              window.localStorage.removeItem("currentQuiz");
              this.currentQuiz = null;
            }
            // Actually the data should be only 1
            snapshot.forEach(data => {
              window.localStorage.setItem(
                "currentQuiz",
                JSON.stringify(data.data())
              );
              this.currentQuiz = data.data();
              this.checkTheTimeDisplay();
            });
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });

      // Get the current Match
      props.firebase.currentMatchQuery().then(query => {
        query.onSnapshot(
          snapshot => {
            if (snapshot.size <= 0) {
              console.log("No Match Active!");
              window.localStorage.removeItem("match_forecast");
              this.currentMatch = null;
            }
            // Actually the data should be only 1
            snapshot.forEach(data => {
              window.localStorage.setItem(
                "currentMatch",
                JSON.stringify(data.data())
              );
              this.currentMatch = data.data();
              this.checkTheTimeDisplay();
            });
          },
          err => {
            console.log(`Encountered error: ${err}`);
          }
        );
      });
    }
  }

  checkTheTimeDisplay = () => {
    if (this.currentMatch !== null && this.currentQuiz === null) {
      if (this._isMounted) {
        this.setState({ skipRegister: true });
      }
    } else {
      if (this._isMounted) {
        this.setState({ skipRegister: false });
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { navigate } = this.props.navigation;

    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <View
          style={{
            padding: 10,
            maxWidth: 330,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: MAIN_COLOR,
            borderColor: "white",
            borderWidth: 1,
            borderRadius: 6
          }}
        >
          <Text
            style={{
              color: "white",
              fontSize: RF(2.5),
              marginTop: 30,
              marginBottom: 30,
              textAlign: "center"
            }}
          >
            {TEXT_HOME_1}
          </Text>
          <Text
            style={{
              color: "white",
              fontSize: RF(2.8),
              marginTop: 30,
              marginBottom: 30,
              textAlign: "center"
            }}
          >
            {TEXT_HOME_2}
          </Text>

          {this.state.skipRegister === false && (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "stretch",
                alignContent: "space-between"
              }}
            >
              <TouchableHighlight onPress={() => navigate("SignUp")}>
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.5),
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    padding: 8,
                    borderRadius: 3
                  }}
                >
                  CRÉER UN COMPTE
                </Text>
              </TouchableHighlight>
              <TouchableHighlight onPress={() => navigate("SignIn")}>
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.5),
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    marginLeft: 10,
                    padding: 8,
                    borderRadius: 3,
                    marginBottom: 30
                  }}
                >
                  SE CONNECTER
                </Text>
              </TouchableHighlight>
            </View>
          )}
          {this.state.skipRegister === true && (
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "stretch",
                alignContent: "space-between"
              }}
            >
              <TouchableHighlight onPress={() => navigate("Menu")}>
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.5),
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    padding: 8,
                    borderRadius: 3
                  }}
                >
                  PARTICIPER
                </Text>
              </TouchableHighlight>
              <TouchableHighlight onPress={() => navigate("SignIn")}>
                <Text
                  style={{
                    color: "white",
                    fontSize: RF(1.5),
                    backgroundColor: BUTTON_COLOR,
                    textAlign: "center",
                    marginLeft: 10,
                    padding: 8,
                    borderRadius: 3,
                    marginBottom: 30
                  }}
                >
                  SE CONNECTER
                </Text>
              </TouchableHighlight>
            </View>
          )}
          {/* <Image
            style={{
              height: 45,
              width: 280,
              marginTop: 30
            }}
            source={banner}
            resizeMode="cover"
          /> */}
        </View>

        <Text
          style={{
            color: "white",
            fontSize: RF(1),
            fontStyle: "italic",
            fontWeight: "100",
            textAlign: "center",
            alignSelf: "flex-end",
            marginRight: 20
          }}
        >
          Powered by Wissl Media
        </Text>
      </View>
    );
  }
}

export default withFirebase(Intro);

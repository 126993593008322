import React from "react";
import { TIME_PAUSE, TIME_EXTRA_ANIM } from "../../constants/const";
import {
  OPTIONS_NO_TV_RESULT_MOBILE,
  OPTIONS_QUESTIONS_TV_AND_RESULTS
} from "../../constants/routes";

export class BaseSondage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentSurvey = null;
    this.currentQuestion = null;
    this.currentResults = null;
    this.timePast = 0;
    this.state = {
      currentSurvey: null,
      currentQuestion: null,
      currentResults: null,
      displayResult: false,
      timeLeft: 10
    };
  }

  checkSurveyAndQuestion = (forceNext) => {
    const currentSurvey = window.localStorage.getItem("currentSurvey");
    if (currentSurvey !== null) {
      this.currentSurvey = JSON.parse(currentSurvey);
      // if (this._isMounted) {
      this.setState({
        currentSurvey: this.currentSurvey
      });
      // }

      // Process the time line for survey
      const now = Math.floor(
        (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
      );
      const timeLeft = this.currentSurvey.start.seconds - now;
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var { questions } = this.currentSurvey;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });

        const currentOrder = this.currentQuestion !== null ? this.currentQuestion.order : 0;
        this.currentQuestion = null;

        if (forceNext === true) {
          if (currentOrder >= questions.length) {
            
          } else {
            console.log("should go to " + (currentOrder + 1));
            const question = questions.filter(e => {
              return e.order === currentOrder + 1;
            })[0];
            if(question !== null){
              this.processDataWithQuestion(question, currentOrder);
            }
          }
        } else {
          this.timePast = this.currentSurvey.start.seconds;
          for (let i = 0; i < questions.length; i++) {
            const question = questions.filter(e => {
              return e.order === i + 1;
            })[0];

            if(question !== null){
              
                const out = this.processDataWithQuestion(question, i);
                if ( out === true) {
                  break;
                }
              
            }
          }
        }

        if (this.currentQuestion === null) {
          if (this.props.navigation.state.routeName !== "Thankyou") {
            this.props.navigation.navigate("Thankyou");
          }
        }
      } else {
        this.props.navigation.navigate("CountDown", {
          params: timeLeft,
          act: "surveys"
        });
      }
    } else {
      // navigate to screen no SURVEY
      this.props.navigation.navigate("NoQuiz");
    }
  };

  processDataWithQuestion = (question, index) => {
    if(question === null){
      return false;
    }
    var time_pause = 0;
    if (
      this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
      this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
    ) {
      time_pause = TIME_PAUSE;
    }
    const qTime = question ? question.time : 0;
    if (index === 0) {
      this.timePast = this.timePast + qTime + TIME_EXTRA_ANIM;
    } else {
      this.timePast = this.timePast + time_pause + qTime + TIME_EXTRA_ANIM;
    }
    const timePastWithResult = this.timePast + time_pause + TIME_EXTRA_ANIM;
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );

    if (now <= timePastWithResult) {
      this.currentQuestion = question;
      this.currentQuestion.timeLeft = this.timePast - now; //>  this.currentQuestion.time ? this.currentQuestion.time : (timePast - now);
      this.currentQuestion.displayResult = now > this.timePast && time_pause > 0;
      this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
      this.currentQuestion.timeEnd = this.timePast;
      if (this.currentQuestion.displayResult === true) {
        this.recalculateRanking();
      }
      if(this.currentQuestion !== null){
        if (this.state.currentQuestion === null) {
          this.bindData();
        } else if (this.state.currentQuestion !== null) {
          if (
            this.currentQuestion !== null && 
            this.currentQuestion.id !== this.state.currentQuestion.id
          ) {
            this.bindData();
          } else if (
            this.currentQuestion.id === this.state.currentQuestion.id &&
            this.currentQuestion.displayResult !==
              this.state.currentQuestion.displayResult
          ) {
            this.bindData();
          }
        }
      }
      return true;
    }

    return false
  };

  bindData = () => {
    // if (this._isMounted) {
    this.setState({
      currentQuestion: this.currentQuestion,
      displayResult: this.currentQuestion.displayResult,
      timeLeft: this.currentQuestion.displayResult
        ? this.currentQuestion.timeLeft
        : this.currentQuestion.displayResultTimeLeft
    });
    // }
  };

  recalculateRanking = () => {
    const currentAnswers = window.localStorage.getItem("currentSurveyAnwsers");
    if (currentAnswers !== null && this.currentSurvey !== null) {
      const arrAnswers = JSON.parse(currentAnswers);
      const results = this.currentSurvey.questions.map(q => {
        // should save for ranking of time
        const answersForQ = arrAnswers
          .map(e => {
            return {
              ...e,
              isRight: q.rightAnswer === e.answerId ? 1 : 0
            };
          })
          .filter(ans => {
            return (
              ans.surveyId === this.currentSurvey.id && ans.questionId === q.id
            );
          })
          .sort((a, b) => {
            return b.isRight - a.isRight || a.time - b.time;
          });

        q.answersByTimes = answersForQ;
        q.totalAnswers = answersForQ.length;
        q.answers = q.answers.map(a => {
          const answersForA = answersForQ.filter(ans => {
            return ans.answerId === a.id;
          });
          a.totalAnswers = answersForA.length;
          a.percent = a.totalAnswers / q.totalAnswers || 0;
          return a;
        });
        return q;
      });
      this.currentResults = results;
      window.localStorage.setItem(
        "currentSurveyResults",
        JSON.stringify(results)
      );
      if (this._isMounted) {
        this.setState({
          currentResults: this.currentResults
        });
      }

      if (this.currentQuestion !== null) {
        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          const res = this.currentQuestion.answers[i];
          var result = null;

          result = this.currentResults
            .filter(q => {
              return q.id === this.currentQuestion.id;
            })[0]
            .answers.filter(r => {
              return r.id === res.id;
            })[0];

          this.currentQuestion.answers[i].withResult = true;
          this.currentQuestion.answers[i].percent = result.percent;
          this.currentQuestion.answers[i].totalAnswers = result.totalAnswers;
          this.currentQuestion.answers[i].isRight =
            res.id === this.currentQuestion.rightAnswer;
        }
      }
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillMount() {
    this.checkSurveyAndQuestion();

    if (this.currentSurvey !== null) {
      if (
        this.currentSurvey.display_type === OPTIONS_QUESTIONS_TV_AND_RESULTS ||
        this.currentSurvey.display_type === OPTIONS_NO_TV_RESULT_MOBILE
      ) {
        this.props.firebase
          .currentResponeseQuery(this.currentSurvey.id)
          .onSnapshot(
            snapshot => {
              if (snapshot.size <= 0) {
                console.log("No Survey Response!");
                window.localStorage.removeItem("currentSurveyAnwsers");
                window.localStorage.removeItem("currentSurveyResults");
              }
              // Actually the data should be only 1
              var arrData = [];
              snapshot.forEach(data => {
                arrData.push(data.data());
              });
              window.localStorage.setItem(
                "currentSurveyAnwsers",
                JSON.stringify(arrData)
              );
              this.recalculateRanking();
            },
            err => {
              console.log(`Encountered error: ${err}`);
            }
          );
      }
    }
  }
}

export default BaseSondage;

import React, { Component } from "react";
import withMainLayout from "../../components/MainLayout";
import {
  Text,
  View
} from "react-native";
import RF from "react-native-responsive-fontsize";
import FadeView from "../../components/Anim/FadeView";
import { withAuthorization } from "../../components/Session";
import { MAIN_COLOR, TEXT_MENU } from "../../constants/const";
import {
  getWidthPercentMaxLimited
} from "../../libs/list_picker/ratio";
import MenuMatch from "../../components/Menu/menu_match";
import MenuQuiz from "../../components/Menu/menu_quiz";
import MenuNote from "../../components/Menu/menu_note";
import MenuSurvey from "../../components/Menu/menu_survey";

export class SelectGameScreen extends Component {
  state = {forecasted : false};
  constructor(props) {
    super(props);
    this.currentQuiz = null;
    this.currentSurvey = null;
    this.currentMatch = null;

    //get prono
    // Update the logged in user
    
    props.firebase.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        if (this._isMounted) {
          this.authUser = authUser;
          this.setState({ authUser });
          this.registerQuizIn();
          this.registerMatchIn();
        }
      }
    });

    setTimeout(
      function() {
        const currentQuiz = window.localStorage.getItem("currentQuiz");
        if (currentQuiz !== null) {
          this.currentQuiz = JSON.parse(currentQuiz);
        }
        const currentSurvey = window.localStorage.getItem("currentSurvey");
        if (currentSurvey !== null) {
          this.currentSurvey = JSON.parse(currentSurvey);
        }
        const currentMatch = window.localStorage.getItem("currentMatch");
        if (currentMatch !== null) {
          this.currentMatch = JSON.parse(currentMatch);
          const match_forecast = window.localStorage.getItem("match_forecast");
          if (match_forecast !== null) {
            this.currentForecast = JSON.parse(match_forecast);
            if (this.currentForecast.matchId === this.currentMatch.match_id) {
              // Same match and already forecasted
              this.setState({forecasted: true});
            }else{
              // not the same match, delete the old one
              this.setState({forecasted: false});
            }
          }
        }
        this.checkTimeDisplay();
      }.bind(this),
      1500
    );
  }

  checkTimeDisplay() {
    // console.log("checkTimeDisplay");
    const now = Math.floor(
      (Date.now() + parseInt(window.localStorage.getItem("delay"), 10)) / 1000
    );
    if (this.currentQuiz === null && this.currentSurvey !== null) {
      // this.setState({ screen: "surveyHome" });
      this.props.navigation.navigate("Surveys");
    } else if (this.currentSurvey === null && this.currentQuiz !== null) {
      // this.setState({ screen: "home" });
      this.props.navigation.navigate("Quizzez");
    } else if (this.currentQuiz !== null && this.currentSurvey !== null) {
      if (
        this.currentSurvey.start.seconds < this.currentQuiz.start.seconds &&
        now < this.currentSurvey.end.seconds
      ) {
        if (now >= this.currentSurvey.start.seconds) {
          if (this.currentQuestion == null) {
            // this.setState({ screen: "thankyou" });
          } else {
            // this.setState({ screen: "surveys" });
            this.props.navigation.navigate("Surveys");
          }
        } else {
          // this.setState({ screen: "surveyHome" });
        }
      } else if (
        this.currentQuiz.start.seconds < this.currentSurvey.start.seconds &&
        now < this.currentQuiz.end.seconds
      ) {
        if (now >= this.currentQuiz.start.seconds) {
          if (this.currentQuestion == null) {
            // this.setState({ screen: "ranking" });
          } else {
            // this.setState({ screen: "quizzes" });
            this.props.navigation.navigate("Quizzez");
          }
        } else {
          // this.setState({ screen: "home" });
        }
      }
    }
  }


  render() {
    return (
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
        key={this.props.toString()}
      >
        <Text
          style={{
            color: "white",
            fontSize: RF(1.8),
            fontWeight: "normal",
            maxWidth: getWidthPercentMaxLimited(80, maxWidth),
            textAlign: "center",
            marginBottom: 20
          }}
        >
          {this.state.forecasted ? "Votre pronostic :" : TEXT_MENU}
        </Text>

        <FadeView
          style={{
            margin: 5,
            padding: 20,
            width: getWidthPercentMaxLimited(100, maxWidth) - 10,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: MAIN_COLOR,
            borderColor: "white",
            borderWidth: 1,
            borderRadius: 6
          }}
          
        >
          {this.props.currentMatch && <MenuMatch key={this.state.forecasted} {...this.props} />}

          {/* <View style={styles.separator} /> */}

          {this.props.currentQuiz && this.props.currentQuiz.name !== "" && (
            <MenuQuiz {...this.props} />
          )}

          {/* <View style={styles.separator} /> */}

          {this.props.currentNote && <MenuNote {...this.props} />}

          {/* <View style={styles.separator} /> */}

          {this.props.currentSurvey && this.props.currentSurvey.name !== "" && (
            <MenuSurvey {...this.props} />
          )}
          {!this.props.currentMatch &&
            !(this.props.currentQuiz && this.props.currentQuiz.name !== "") &&
            !this.props.currentNote &&
            !(
              this.props.currentSurvey && this.props.currentSurvey.name !== ""
            ) && (
              <Text style={{ fontSize: RF(2.5), color: "white" }}>
                Aucune activité prévue ! Revenez bientôt !
              </Text>
            )}
        </FadeView>

        <FadeView
          delay={600}
          style={{ alignSelf: "flex-end", marginRight: 20 }}
        >
          <Text
            style={{
              color: "white",
              fontSize: RF(1),
              fontStyle: "italic",
              fontWeight: "100",
              textAlign: "center"
            }}
          >
            Powered by Wissl Media
          </Text>
        </FadeView>
      </View>
    );
  }
}

const maxWidth = 500;
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(SelectGameScreen));

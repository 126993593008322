import React, { Component } from "react";
import ResponseTextItem from "./reponseTextItem";

export class Response extends Component {
  constructor(props) {
    super(props);
    this.state = { data: this.props.data, answered: false };
  }
  onPressButton = (key, id) => {
    if (!this.state || !this.state.answered) {
      
      this.props.firebase.answers().add({
        quizId: this.state.data.currentQuizId,
        questionId: this.state.data.currentQuestionId,
        answerId: id,
        time: this.state.data.currentQuestionTimeLeft - (this.state.data.currentQuestionTimeEnd - Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)) / 1000)),
        timeMs: this.state.data.currentQuestionTimeLeftMs - ((this.state.data.currentQuestionTimeEnd * 1000) - Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)))),
        userId: this.props.authUser.uid,
        displayName: this.props.authUser.displayName,
        email: this.props.authUser.email
      });
      const anw = this.props.data;
      anw.answers[key].answered = true;

      this.setState({ data: anw });

      this.setState({ answered: true });
      this.setState({ answerId: key });
    }
  };

  render() {
    return (
      <ResponseTextItem
        data={this.props.data.answers}
        question={this.props.data}
        onPressButton={this.onPressButton}
        answered={this.state.answered}
        modeTele={this.props.modeTele || false}
        withResult={this.props.data.withResult}
      />
    );
  }
}

export default Response;

import React from "react";
import {
  TIME_PAUSE,
  TIME_EXTRA_ANIM
} from "../../constants/const";

export class BaseQuiz extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.currentQuiz = null;
    this.currentQuestion = null;
    this.currentResults = null;
    this.state = {
      currentQuiz: null,
      currentQuestion: null,
      currentResults: null,
      displayResult: false,
      timeLeft: 10
    };
  }

  checkQuizAndQuestion = () => {
    const currentQuiz = window.localStorage.getItem("currentQuiz");
    if (currentQuiz !== null) {
      this.currentQuiz = JSON.parse(currentQuiz);
      //if (this._isMounted) {
        this.setState({
          currentQuiz: this.currentQuiz
        });
      //}

      // Process the time line for quiz
      const now = Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)) / 1000);
      const nowMs = Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)));
      const timeLeft = this.currentQuiz.start.seconds - now;
      
      const isStarted = timeLeft <= 0;
      if (isStarted) {
        // Process questions base on time
        var {
          questions
        } = this.currentQuiz;
        questions = questions.sort((a, b) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        });
        var timePast = this.currentQuiz.start.seconds;
        this.currentQuestion = null;
        for (let i = 0; i < questions.length; i++) {
          const question = questions.filter(e => {
            return e.order === i + 1;
          })[0];
          if(question){
            const qTime = question ? question.time : 0;
            if (i === 0) {
              timePast = timePast + qTime + TIME_EXTRA_ANIM;
            } else {
              timePast = timePast + TIME_PAUSE + qTime + TIME_EXTRA_ANIM;
            }
            const timePastWithResult = timePast + TIME_PAUSE +  TIME_EXTRA_ANIM;
            const now = Math.floor((Date.now() + parseInt(window.localStorage.getItem('delay'), 10)) / 1000);
  
            if (now <= timePastWithResult) {
              this.currentQuestion = question;
              this.currentQuestion.timeLeft = (timePast - now) //>  this.currentQuestion.time ? this.currentQuestion.time : (timePast - now);
              this.currentQuestion.timeLeftMs = ((timePast * 1000) - nowMs);
              this.currentQuestion.displayResult = now > timePast;
              this.currentQuestion.displayResultTimeLeft = timePastWithResult - now;
              this.currentQuestion.timeEnd = timePast;
              // this.currentQuestion.order = i + 1;
              if (this.currentQuestion.displayResult === true) {
                this.recalculateRanking();
              }
              if (this.state.currentQuestion === null) {
                this.bindData();
              } else if (this.state.currentQuestion !== null && this.currentQuestion !== null) {
                if (this.currentQuestion.id !== this.state.currentQuestion.id) {
                  this.bindData();
                } else if (this.currentQuestion.id === this.state.currentQuestion.id &&
                  this.currentQuestion.displayResult !==
                  this.state.currentQuestion.displayResult) {
                  this.bindData();
                }
              }
              break;
            }
          }          
        }
        // console.log(this.currentQuestion);
        if (this.currentQuestion === null) {
          // this.currentQuestion = this.currentQuiz.questions[0];
          // console.log(this.props.navigation);
          if (this.props.navigation.state.routeName !== "Ranking") {
            this.props.navigation.navigate("Ranking");
          }
        }
      } else {
        this.props.navigation.navigate("CountDown", {
          params: timeLeft, act : 'quizzes'
        });
      }
    } else {
      // navigate to screen no QUIZ
      this.props.navigation.navigate("NoQuiz");
    }
  };

  bindData = () => {
    // if (this._isMounted) {
      this.setState({
        currentQuestion: this.currentQuestion,
        displayResult: this.currentQuestion.displayResult,
        timeLeft: this.currentQuestion.displayResult ?
          this.currentQuestion.timeLeft : this.currentQuestion.displayResultTimeLeft
      });
    // }
  }

  recalculateRanking = () => {
    const currentAnswers = window.localStorage.getItem("currentAnwsers");
    if (currentAnswers !== null && this.currentQuiz !== null) {
      const arrAnswers = JSON.parse(currentAnswers);
      const results = this.currentQuiz.questions.map(q => {
        // should save for ranking of time
        const answersForQ = arrAnswers
          .map(e => {
            return {
              ...e,
              isRight: q.rightAnswer === e.answerId ? 1 : 0,
              rightTimeMs : q.rightAnswer === e.answerId ? e.timeMs : 0
            };
          })
          .filter(ans => {
            return (
              ans.quizId === this.currentQuiz.id && ans.questionId === q.id
            );
          })
          .sort((a, b) => {
            return b.isRight - a.isRight || a.rightTimeMs - b.rightTimeMs || a.timeMs - b.timeMs || a.time - b.time;
          });

        q.answersByTimes = answersForQ;
        q.totalAnswers = answersForQ.length;
        q.answers = q.answers.map(a => {
          const answersForA = answersForQ.filter(ans => {
            return ans.answerId === a.id;
          });
          a.totalAnswers = answersForA.length;
          a.percent = (a.totalAnswers / q.totalAnswers) || 0;
          return a;
        });
        return q;
      });
      this.currentResults = results;
      window.localStorage.setItem("currentResults", JSON.stringify(results));

      if (this.currentQuestion !== null) {
        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          const res = this.currentQuestion.answers[i];
          var result = null;

          result = this.currentResults
            .filter(q => {
              return q.id === this.currentQuestion.id;
            })[0]
            .answers.filter(r => {
              return r.id === res.id;
            })[0];

          this.currentQuestion.answers[i].withResult = true;
          this.currentQuestion.answers[i].percent = result.percent;
          this.currentQuestion.answers[i].totalAnswers = result.totalAnswers;
          this.currentQuestion.answers[i].isRight =
            res.id === this.currentQuestion.rightAnswer;
        }
      }

      const _filted = this.currentResults.filter(q => {
        return q.id === (this.currentQuestion !== null ? this.currentQuestion.id : 0);
      });
      var answersByTimes = _filted.length > 0 ? _filted[0].answersByTimes : [];

      answersByTimes = answersByTimes.map(el => {
        el.rightTimeMs = el.isRight === 1 ? el.timeMs : 0;
        return el;
      });

      if(answersByTimes.length > 0){
        // Only take first 10
        answersByTimes = answersByTimes.sort((a, b) => {
          return b.isRight - a.isRight || a.rightTimeMs - b.rightTimeMs || a.timeMs - b.timeMs || a.time - b.time;
        });
      }

      this.setState({
        currentResults: this.currentResults,
        answersByTimes: answersByTimes.slice(0,100)
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillMount() {
    this.checkQuizAndQuestion();

    if (this.currentQuiz !== null) {
      this.props.firebase.currentAnswersQuery(this.currentQuiz.id).onSnapshot(
        snapshot => {
          if (snapshot.size <= 0) {
            console.log("No Quiz Answers!");
            window.localStorage.removeItem("currentAnwsers");
            window.localStorage.removeItem("currentResults");
          }
          // Actually the data should be only 1
          var arrData = [];
          snapshot.forEach(data => {
            arrData.push(data.data());
          });
          window.localStorage.setItem(
            "currentAnwsers",
            JSON.stringify(arrData)
          );
          this.recalculateRanking();
        },
        err => {
          console.log(`Encountered error: ${err}`);
        }
      );
    }
  }
}

export default BaseQuiz;
import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import Forecast from '../../components/Forecast';
export class PreForecastScreen extends Component {
  state = {
    currentMatch: null
  }
  componentDidMount(){
    const currentMatch = window.localStorage.getItem("currentMatch");
    if (currentMatch !== null) {
      this.currentMatch = JSON.parse(currentMatch);
      const match_forecast = window.localStorage.getItem("match_forecast");
      if (match_forecast !== null) {
        this.currentForecast = JSON.parse(match_forecast);
        if (this.currentForecast.matchId === this.currentMatch.match_id) {
          // Same match and already forecasted
          this.setState({ forecasted: true });
        } else {
          // not the same match, delete the old one
          this.setState({ forecasted: false });
        }
      }
    }

    this.setState({
      currentMatch: this.currentMatch
    });

  }
  render() {
    return (
        <Forecast mode='preforecast' {...this.props} {...this.state} />
    )
  }
}

export default withMainLayout(PreForecastScreen);

import React, { Component } from 'react'
import withMainLayout from '../../components/MainLayout';
import Forecast from '../../components/Forecast';
import { withAuthorization } from "../../components/Session";
export class ForecastScreen extends Component {
  render() {
    return (
        <Forecast {...this.props} />
    )
  }
}
const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(ForecastScreen))

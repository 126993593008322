import React, { Component } from "react";
import Question from "../../components/Quizzes/question";
import withMainLayout from "../../components/MainLayout";
import { withAuthorization } from "../../components/Session";

export class QuizzesScreen extends Component {
  render() {
    return (
      <Question {...this.props}  />
    );
  }
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withMainLayout(QuizzesScreen));

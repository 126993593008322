// In production, we register a service worker to serve assets from local cache.

// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on the "N+1" visit to a page, since previously
// cached resources are updated in the background.

// To learn more about the benefits of this model, read https://goo.gl/KwvDNy.
// This link also includes instructions on opting out of this behavior.

export default function register() {
  //process.env.NODE_ENV === 'production' &&
  if ("serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location);
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebookincubator/create-react-app/issues/2374
      return;
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
      // Is not local host. Just register service worker
      registerValidSW(swUrl);
      navigator.serviceWorker.ready.then(() => {
        console.log("WisslMedia service loaded");
      });
    });
  }
}

let newWorker;

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      registration.addEventListener('updatefound', () => {
        // A wild service worker has appeared in reg.installing!
        newWorker = registration.installing;
        // newWorker.state;
        // "installing" - the install event has fired, but not yet complete
        // "installed"  - install complete
        // "activating" - the activate event has fired, but not yet complete
        // "activated"  - fully active
        // "redundant"  - discarded. Either failed install, or it's been
        //                replaced by a newer version    
        newWorker.addEventListener('statechange', () => {
          // newWorker.state has changed
          if (newWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the old content will have been purged and
              // the fresh content will have been added to the cache.
              // It's the perfect time to display a "New content is
              // available; please refresh." message in your web app.
              console.log("New content is available; please refresh.");
              // navigator.serviceWorker.skipWaiting();
              // window.location.reload(true);
              let notification = document.getElementById('snackbar');
              notification.addEventListener('click', function(){
                newWorker.postMessage({ action: 'skipWaiting' });
              });
              // The click event on the notification
              notification.className = 'show';
              newWorker.postMessage({ action: 'skipWaiting' });
              
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");
            }
          }
        });

      });
      registration.update();
    })
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // This fires when the service worker controlling this page
      // changes, eg a new worker has skipped waiting and become
      // the new active worker.
      window.location.reload(true);
      console.log("controllerchange service worker")
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
